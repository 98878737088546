import moment, { Moment } from 'moment';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_ISO, DATE_FORMAT_VALUE } from '../../constants/index';

export type DateObjectType = Moment;

export const getCurrentDateObject = (): DateObjectType => {
  return moment();
};

export const getDateFormatted = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? DATE_FORMAT_DEFAULT) ?? '';
};

export const getValueFromDate = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? DATE_FORMAT_VALUE) ?? '';
};

export const getDateFormattedFromURL = (date?: string): string => {
  return moment(date, DATE_FORMAT_VALUE)?.format(DATE_FORMAT_ISO);
};

export const getNextDateObject = (date: DateObjectType): DateObjectType => {
  return moment(date).add(1, 'days');
};

export const getPrevDateObject = (date: DateObjectType): DateObjectType => {
  return moment(date).add(-1, 'days');
};

export const getDateObject = (date: string, format = moment.defaultFormat): DateObjectType => {
  return moment(date, format).utc();
};

export const getDateObjectCurrent = (date: string, format = moment.defaultFormat): DateObjectType => {
  return moment(date, format).utc(true);
};

// to get the dates based on the localization
export const getLocalizedDate = (date: DateObjectType | undefined, dateFormat: string): string => {
  moment.locale('locale');

  if (date) {
    //initially date will be in the english format we need to change that to the localized value
    const formattedDate = moment(date).format();
    return moment(formattedDate).format(dateFormat ?? DATE_FORMAT_DEFAULT);
  }
  return '';
};

export const getNumberOfDays = (startDate: DateObjectType | undefined, endData: DateObjectType | undefined): number => {
  startDate = startDate?.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  endData = endData?.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  // If startDate and endDate are present then calculate the diffrence and round it off
  return endData && startDate ? Math.round(endData.diff(startDate, 'days', true)) : 0;
};
