// Styles for LanguageSelector go here.
import styled from 'styled-components';
import { baseVariables, toRem, Modal } from '@marriott/mi-ui-library';

export const StyledLanguageSelector = styled.div`
  .modal-popup-container {
    @media ${baseVariables.mediaQuery.lg} {
      position: absolute;
    }
    @media ${baseVariables.mediaQuery.sm} {
      position: fixed;
      height: 100vh;
    }
  }

  .modal-main-container {
    position: relative;
    @media ${baseVariables.mediaQuery.sm} {
      overflow-y: auto;
      max-height: 80vh;
    }
    @media ${baseVariables.mediaQuery.lg} {
      overflow-y: unset;
      max-height: none;
      max-width: ${toRem(1140)};
    }
  }

  .modal-header {
    margin-bottom: ${toRem(32)};

    height: ${toRem(32)};
    h4 {
      color: ${baseVariables.color['base10']};
    }
    .popup-close {
      max-height: ${toRem(32)};
      max-width: ${toRem(32)};
    }
  }

  .custom-header-font h2 {
    color: ${baseVariables.color['base10']};
  }

  .custom-link a {
    color: ${baseVariables.color['base10']};
  }

  .mobile-border-2 {
    display: none;
  }
`;

export const StyledModal = styled(Modal)`
  overflow-y: scroll;
`;

// Overriding styles from linkscollection until those styles have been updated
export const StyledColumn = styled.div`
  padding-bottom: ${toRem(24)};
  .header-international {
    margin-top: ${toRem(8)};
  }
  .custom-header-font {
    @media ${baseVariables.mediaQuery.smallMobile} {
      padding-left: ${toRem(32)};
      padding-right: ${toRem(32)};

      li {
        padding: 0;
      }
      .region-header {
        padding-left: 0 !important;
      }
    }
    @media ${baseVariables.mediaQuery.sm} {
      .region-header {
        padding-top: 0 !important;
      }
    }
  }
  .custom-header-font:not(:last-child) {
    @media ${baseVariables.mediaQuery.sm} {
      padding-top: 0;
      padding-bottom: 0;
      border-right: thin ${baseVariables.color['neutral30']} solid;
      padding-left: ${toRem(48)};
      padding-right: ${toRem(24)};
    }
  }

  .custom-header-font:last-child {
    @media ${baseVariables.mediaQuery.sm} {
      padding-left: ${toRem(48)};
      padding-right: ${toRem(24)};
    }
  }

  .custom-header-font:first-child {
    @media ${baseVariables.mediaQuery.sm} {
      padding-left: ${toRem(32)} !important;
    }
  }

  .custom-header-font {
    padding-top: 0 !important;
  }
  ul {
    padding-left: 0 !important;
    margin-left: ${toRem(-8)} !important;
    margin-bottom: 0 !important;
    border-top: none !important;
  }
  li {
    border-radius: ${toRem(8)};
    padding-left: ${toRem(8)} !important;
  }
  li:hover {
    background: ${baseVariables.color['neutral10']};

    a:hover {
      text-decoration: none;
    }
  }
  li a {
    min-width: -webkit-fill-available;
  }
  .t-subtitle-xl {
    font-size: 22px;
    font-style: normal;
    line-height: 125%;
    color: ${baseVariables.color['base10']};
    font-weight: 700;
    padding-top: 0 !important;
  }
`;

export const StyledModalHeader = styled(Modal.Header)`
  margin-top: ${toRem(40)};
`;

export const StyledBorder = styled.div`
  border-bottom: thin ${baseVariables.color['neutral30']} solid;
  margin-top: ${toRem(24)};
  margin-bottom: ${toRem(24)};
`;
