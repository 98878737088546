import styled from 'styled-components';

import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { font, mediaQuery } from '../../utils/styles';

export const StyledOfferCarouselContainer = styled.div`
  .cardWrapper {
    .container {
      padding: 0 0.5rem;
    }
    .icon-arrow-right-external {
      position: initial;
    }
    .chevronContainer[class*='icon-arrow-']::before {
      color: ${baseVariables.color['base20']};
    }
    .glide__slides_authoring {
      width: 101% !important;
    }
  }

  .m-badge-overlay-merch {
    background-color: rgb(255, 153, 98);
  }
  .t-overline-overlay {
    text-transform: none;
    color: ${baseVariables.color['base20']};
  }
  .feature-header {
    line-height: 1;
  }

  .cardTitle {
    font-size: ${toRem(22)};
    font-weight: 700;
    text-transform: none;
    color: ${baseVariables.color['base20']};
    font-family: ${font.Swiss721BTRegular};
    @media ${mediaQuery.tabletOnly} {
      line-height: ${toRem(20)};
      font-size: ${toRem(18)};
    }
    @media ${mediaQuery.mobileOnly} {
      line-height: ${toRem(20)};
      font-size: ${toRem(18)};
    }
  }

  .hideCarousel {
    display: none;
  }
`;

export const StyledOfferCardsDivPreviewMode = styled.div`
  pointer-events: none;
  .glide__arrow--right {
    display: none;
  }
  .center-align {
    display: none !important;
  }
`;
