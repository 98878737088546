/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, KeyboardEvent } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { isServer } from '@marriott/mi-apollo-client-utils';
import { DropdownOptionList, getLocalizedDate } from '@marriott/mi-ui-library';

import { useSearchFormStore } from '../../../modules/store/searchFormStore';
import { useStaticDataContext } from '../../../modules/context';

import { RecentViewAndSearchProps } from './RecentViewAndSearch.types';
import { StyledRecentViewAndSearch } from './RecentViewAndSearch.styles';
import { DESTINATION_HIDDEN_FIELDS } from '../../../modules/store/store.constants';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { useCheckBreakpoint } from '../../../hooks/lib/useCheckBreakpoint';
import {
  CityPickListDesktopCountForRecentSearches,
  CityPickListMobileCountForRecentSearches,
  CountForRecentSearches,
} from '../../../constants/lib/constants';

//list config type for each list, example: recentSearch, recentView.
interface configType {
  heading: string;
  listArray: Array<Record<string, string>>;
  nameKey: string;
  valueKey: string;
  icon: string;
  descriptionKey?: string;
}

/*
  Information!
  Molecule  : Recent View and search will provide thee list of recently searched and recently viewed properties.
              Along with it, It will also provide the current location option.
              In addition to it, In case recently searched and recently viewed properties are not available,
              This component will render the list of popoler destinations.
  Props     : updateInputValue - function to update the input value
              closePopup - function to close popup.
              updateSearchView - flag to update search view
              showListView - flag to show list view
*/

const RecentViewAndSearch: FC<RecentViewAndSearchProps> = ({
  updateInputValue,
  closePopup,
  updateSearchView,
  showListView,
  isMeetingDestField,
  lastElementRef,
  isCityPicklistFlow,
  setShowClearRecentsOnPicklist,
}) => {
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);
  const clickTrackingLoc = 'Destination';
  const {
    //values and lists from AEM
    topDestinationslistItems,
    domesticDestinationsListItems,
    featuredDestinationsListItems,
    orderingRecentlySearchedAndViewed,
    //flags from AEM
    disableRecentlyViewed,
    disableRecentlySearched,
    disableTopDestination,
    //labels from AEM
    domesticLabel,
    featuredLabel,
    clearRecentLabel,
    night,
    nights,
    nightLabel,
    guest,
    guests,
    flexible,
    nameOfRecentSearchesSection,
    nameOfRecentlyViewedSection,
    nameOfPopularDestinationSection,
    enableFlexibleSearch,
    recentViewSpecificDateFormat,
    recentViewFlexibleDateFormat,
  } = useStaticDataContext(); //This content is received from AEM.
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { ACCEPT_LANGUAGE: currentLocale } = clientEnvVars;
  const recentListConfig: Array<configType | undefined> = [];
  // const disableTopDestination = false;
  let showRecentSearches = false;
  let showRecentlyViewed = false;
  let recentSearchesData;
  let recentlyViewedData;
  const nightLabeltext = night || nightLabel;
  const hideFlexibleDateFlow = enableFlexibleSearch === true ? false : true;
  const isTabletView = useCheckBreakpoint('viewportM');

  //This block will fetch list of recent searches from localhost, If recent search flag is enabled from AEM
  if (disableRecentlySearched !== true) {
    const getRecentSearchList: string | null = !isServer ? localStorage.getItem('miRecentSearch') : null;
    const recentSearchesAll: Array<Record<string, string>> = getRecentSearchList && JSON.parse(getRecentSearchList);
    //this function will fetch the last 3 recently searched destinations from localstorage
    if (recentSearchesAll && recentSearchesAll.length > 0) {
      const nonPastDateSearches = recentSearchesAll.filter(item => {
        //converting date to hours(0,0,0,0) to avoid time comparision , only dates are being considered
        return (
          new Date(item?.['fromDate'] || item?.['startDate']).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
        );
      });
      if (nonPastDateSearches && nonPastDateSearches.length) {
        let nonFlexibleSearches;
        if (hideFlexibleDateFlow) {
          nonFlexibleSearches = nonPastDateSearches?.filter(item => {
            return item['flexible']?.toString() === 'false' || item['flexibleDates'] === 'false';
          });
        } else {
          nonFlexibleSearches = nonPastDateSearches;
        }
        const nonFlexibleDateFormat = recentViewSpecificDateFormat
          ? recentViewSpecificDateFormat
          : `MMM DD${isCNLocale(currentLocale) ? '日' : ''}`;

        nonFlexibleSearches.forEach(item => {
          const guestCount = item['guests'] || item['attendees'];
          const guestsLabel = Number(guestCount) > 1 ? guests : guest;
          const guestsDisplayValue = guestsLabel?.includes('{x}')
            ? guestsLabel.replace('{x}', guestCount.toString())
            : guestCount + ' ' + guestsLabel;
          const fromDate = moment(item?.['fromDate'] || item?.['startDate']);
          const toDate = moment(item['toDate'] || item?.['endDate']);
          const lengthOfStay = item['lengthOfStay'] || toDate.diff(fromDate, 'days');
          item['description'] =
            item['flexible'] && item['flexible'].toString() === 'true'
              ? `${fromDate
                  .subtract(fromDate.utcOffset() / 60, 'hours')
                  .format(
                    recentViewFlexibleDateFormat ? recentViewFlexibleDateFormat : 'MMM'
                  )} (${flexible?.toLowerCase()})${
                  !isCityPicklistFlow
                    ? `, ${lengthOfStay} ${Number(lengthOfStay) > 1 ? nights : nightLabeltext}, ${guestsDisplayValue}`
                    : ''
                } 
                 `
              : `${getLocalizedDate(
                  fromDate.subtract(fromDate.utcOffset() / 60, 'hours'),
                  nonFlexibleDateFormat,
                  currentLocale
                )} - ${getLocalizedDate(
                  toDate.subtract(toDate.utcOffset() / 60, 'hours'),
                  nonFlexibleDateFormat,
                  currentLocale
                )}${
                  !isCityPicklistFlow
                    ? `, ${lengthOfStay} ${Number(lengthOfStay) > 1 ? nights : nightLabeltext}, ${guestsDisplayValue}`
                    : ''
                }`;
        });
        //removing duplicate entries
        const removedDuplicates = nonFlexibleSearches?.filter(
          (value, index, self) =>
            index === self.findIndex(t => t['label'] === value['label'] && t['description'] === value['description'])
        );
        //slice by 5 for citypicklist desktop/tablet, slice by 4 citypicklist mobile, slice by 3 for other locales
        const recentSearchesSliceCount = isCityPicklistFlow
          ? isTabletView
            ? -CityPickListDesktopCountForRecentSearches
            : -CityPickListMobileCountForRecentSearches
          : -CountForRecentSearches;
        //slicing from end of the array in case of recent searches , hence negative slice count
        const recentSearches = removedDuplicates?.slice(recentSearchesSliceCount);
        if (recentSearches?.length) {
          showRecentSearches = true;
        }
        recentSearchesData = {
          heading: nameOfRecentSearchesSection,
          listArray: recentSearches,
          nameKey: 'label',
          valueKey: 'label',
          descriptionKey: 'description',
          icon: 'search',
        };
      }
    }
  }

  //This block will fetch list of recently viewed properties from localhost, If recentlyViewed flag is enabled from AEM
  if (disableRecentlyViewed !== true) {
    const getRecentViewList: string | null = !isServer ? localStorage.getItem('miRecentlyViewedProperties') : null;
    const recentlyViewedAll: Array<Record<string, string>> =
      getRecentViewList && JSON.parse(getRecentViewList)?.recentlyViewedProperties;
    //this function will fetch the last 3 recently viewed properties from localstorage
    //slice by 5 for citypicklist desktop/tablet, slice by 4 citypicklist mobile, slice by 3 for other locales
    const recentViewSliceCount = isCityPicklistFlow
      ? isTabletView
        ? CityPickListDesktopCountForRecentSearches
        : CityPickListMobileCountForRecentSearches
      : CountForRecentSearches;
    //slicing from start of the array in case of recently viewed , hence positive slice count
    const recentlyViewed: Array<Record<string, string>> = recentlyViewedAll?.slice(0, recentViewSliceCount);
    if (recentlyViewed && recentlyViewed.length > 0 && !isMeetingDestField) {
      showRecentlyViewed = true;
      recentlyViewedData = {
        heading: nameOfRecentlyViewedSection,
        listArray: recentlyViewed.reverse(),
        nameKey: 'name',
        valueKey: 'name',
        icon: 'clock',
      };
    }
  }

  //This block will clear the list of recent searches from localhost.
  const clearRecent = (): void => {
    localStorage.removeItem('miRecentSearch');
    localStorage.removeItem('miRecentlyViewedProperties');
    closePopup();
  };

  const handleClearRecentKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      clearRecent();
    }
  };

  //This block will generate the list to display on destination popup based on the sort order received from AEM.
  if (orderingRecentlySearchedAndViewed !== 'recentSearch') {
    if (recentlyViewedData) {
      recentListConfig.push(recentlyViewedData);
    }
    if (recentSearchesData) {
      recentListConfig.push(recentSearchesData);
    }
  } else {
    if (recentSearchesData) {
      recentListConfig.push(recentSearchesData);
    }
    if (recentlyViewedData) {
      recentListConfig.push(recentlyViewedData);
    }
  }

  // This function will update the hidden fields, based on the user interactions.
  const hiddenFieldsHandler = (data: Record<string, string | number>): void => {
    setSearchFormState(
      [DESTINATION_HIDDEN_FIELDS],
      {
        [DESTINATION_HIDDEN_FIELDS]: data,
      },
      true
    );
  };

  const showSearchHistory = showRecentlyViewed || showRecentSearches;
  setShowClearRecentsOnPicklist?.(showSearchHistory);

  useEffect(() => {
    if (!showSearchHistory) {
      updateSearchView(true);
    }
  }, []);

  return (
    <StyledRecentViewAndSearch data-testid="recent-view-and-search">
      <>
        {/** If search history is not present and top destination flag is enabled from AEM, we will load top destinations using recent list in destination popup **/}
        {!showSearchHistory &&
          !isCityPicklistFlow &&
          disableTopDestination !== true &&
          topDestinationslistItems &&
          topDestinationslistItems.length > 0 && (
            <DropdownOptionList
              heading={nameOfPopularDestinationSection}
              listArray={topDestinationslistItems}
              listIcon={'location'}
              nameKey={'topDestinationField'}
              valueKey={'topDestinationField'}
              clickHandler={updateInputValue}
              descriptionKey={''}
              lastElementRef={lastElementRef}
              isGridLayout={isCityPicklistFlow}
            />
          )}
        {/** If search history is not present and search view type is default, we will load domestic destinations and Featured destinations using recent list in destination popup **/}
        {(showListView || !showSearchHistory) && (
          <>
            {domesticDestinationsListItems?.length > 0 && (
              <DropdownOptionList
                heading={domesticLabel}
                listArray={domesticDestinationsListItems}
                listIcon={'location'}
                nameKey={'displayLabel'}
                valueKey={'actualLabel'}
                clickHandler={updateInputValue}
                inlineDisplay={true}
                elementClass={'domestic-dest-list'}
                descriptionKey={''}
                lastElementRef={lastElementRef}
                isGridLayout={isCityPicklistFlow}
              />
            )}
            {featuredDestinationsListItems?.length > 0 && (
              <DropdownOptionList
                heading={featuredLabel}
                listArray={featuredDestinationsListItems}
                listIcon={'location'}
                nameKey={'displayLabel'}
                valueKey={'actualLabel'}
                clickHandler={updateInputValue}
                descriptionKey={''}
                inlineDisplay={true}
                lastElementRef={lastElementRef}
                isGridLayout={isCityPicklistFlow}
              />
            )}
          </>
        )}
        {/** If list view is not enabled and recent search or recent viewed list exist, we will load last 3 recent search and last 3 recently viewed properties using recent list in destination popup **/}
        {!showListView && recentListConfig.length
          ? recentListConfig.map(
              item =>
                item && (
                  <DropdownOptionList
                    key={item.heading}
                    heading={item.heading}
                    listArray={item.listArray}
                    listIcon={item.icon}
                    nameKey={item.nameKey}
                    valueKey={item.valueKey}
                    descriptionKey={item.descriptionKey ?? ''}
                    clickHandler={(...args) =>
                      updateInputValue(args[0], args[1], args[2], args[3], item.heading, args[5], args[6], args[7])
                    }
                    hiddenFieldsHandler={hiddenFieldsHandler}
                    lastElementRef={lastElementRef}
                    isGridLayout={isCityPicklistFlow}
                  />
                )
            )
          : null}
        {/** If search history exist, we will show clear recent button in destination popup **/}
        {showSearchHistory && !isCityPicklistFlow && (
          <div className={clsx('search-history-btm-cta-wrapper', 't-font-s', !showListView ? '' : 'd-none')}>
            <a
              role="button"
              tabIndex={0}
              onClick={clearRecent}
              onKeyDown={handleClearRecentKeyDown}
              className="t-font-xs m-link-action custom_click_track"
              {...{ custom_click_track_value: `${clickTrackingLoc}| ${clearRecentLabel} |internal` }}
            >
              {clearRecentLabel}
            </a>
          </div>
        )}
      </>
    </StyledRecentViewAndSearch>
  );
};

export default RecentViewAndSearch;
