// import { logger } from '@marriott/mi-headless-utils';
// const { log } = logger({})('CookieUtils.ts');
class CookieUtils {
  static cookies: {
    [key: string]: string;
  };
  static getCookie = (cookieName: string): string => {
    if (!CookieUtils.cookies) {
      CookieUtils.setCookies();
    }
    return CookieUtils.cookies[cookieName];
  };

  static setCookies = (): void => {
    const cookieString = document?.cookie;
    CookieUtils.cookies = {};

    cookieString.split(';').forEach(cookie => {
      const [key, value] = cookie.trim().split('=');
      if (key && value) {
        try {
          CookieUtils.cookies[key] = decodeURIComponent(value);
        } catch (error) {
          CookieUtils.cookies[key] = value;
          // log.error(`Cookie ${key} with value ${value} could not be decoded - Error ${error}`);
        }
      }
    });
  };
}

export default CookieUtils;
