import React, { useEffect, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { StyledTab } from './Tabs.styles';
import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { Heading } from '../../atoms/Heading';
import { getEventKeyTypes } from '../../utils/helper';
import { headingType, tags, size } from '../../utils/enums/enums';
import { TabProps } from './Tabs.types';
import { clsx } from 'clsx';

export const TabConfig = {
  emptyLabel: 'Tab',
  isEmpty: () => true,
  resourceType: `/components/content/Tab`,
};

export const TabComponent: React.FC<TabProps> = ({ isIconTabs = false, ...props }) => {
  const tabProps = props;
  const { tabList } = tabProps;

  const [activeTab, setActiveTab] = useState(tabList[0]?.tabValue);

  useEffect(() => {
    if (props.selectedTab) {
      setActiveTab(props.selectedTab);
    }
  }, [props.selectedTab]);

  return (
    <StyledTab
      data-component-name="m-ui-library-Tab"
      data-testid="ui-library-Tab"
      className={clsx(
        'tab-wrapper',
        ` ${!props.disabledContainerClass && 'container'}`,
        ` ${props.hidetabsAndContent && 'd-none'}`,
        `${props.classTabsEnabled ?? ''}`
      )}
    >
      <div className={props?.tabContainerClass}>
        <ul
          className={clsx(
            !isIconTabs && `${props?.isSwitcherTabs ? 'm-switcher-tab-list' : `m-standard-tab-list`}`,
            `${props.customClass}`,
            `${props.hideTabs && 'd-none'}`
          )}
          role="tablist"
        >
          {tabList.map((tab, key) => {
            const { tabTitle, tabSubtitle, tabValue } = tab;
            const iconClassName = isIconTabs && (props?.tabIcon[tabValue] || '');
            return (
              <li
                key={key}
                className={clsx(
                  'custom_click_track',
                  !isIconTabs && `${props?.isSwitcherTabs ? 'm-switcher-tab-list-item' : 'm-standard-tab-list-item'}`,
                  `${props?.iconClass}`,
                  activeTab === tabValue ? 'active' : 'inactive-tab'
                )}
                {...{
                  custom_click_track_value: `${props.clickTrackingLoc}|${tabTitle} tab |internal`,
                }}
                role="tab"
                onClick={() => {
                  setActiveTab(tabValue);
                  props.setSelectedTab(tabValue);
                }}
                onKeyDown={event => {
                  if (getEventKeyTypes(event)?.isEnterKey) {
                    setActiveTab(tabValue);
                    props.setSelectedTab(tabValue);
                  }
                }}
                tabIndex={0}
                aria-label={tabTitle}
                aria-expanded={activeTab === tabValue ? 'true' : 'false'}
              >
                <Button
                  isLink={props.isTablink !== undefined ? props.isTablink : true}
                  custom_click_track_value={`${props.clickTrackingLoc} | ${tabTitle} tab | internal`}
                  ariaRole={props.ariaRole}
                >
                  {!props?.isSwitcherTabs ? (
                    <>
                      {isIconTabs && <span className={clsx('icon', iconClassName)}></span>}
                      <div className={props?.subTitleClass}>
                        <Heading
                          element={tags.span}
                          variation={headingType.subtitle}
                          titleText={tabTitle}
                          disableCustomClickTrack={props?.disableCustomClickTrack}
                        />
                      </div>
                    </>
                  ) : (
                    <Heading
                      element={tags.span}
                      variation={headingType.body}
                      fontSize={size.extraSmall}
                      titleText={tabTitle}
                      clickTrackingLoc={props.clickTrackingLoc}
                      disableCustomClickTrack={props?.disableCustomClickTrack}
                    />
                  )}
                  <Text
                    element={tags.span}
                    fontSize={size.extraSmall}
                    copyText={tabSubtitle}
                    customClass={'item-description tab-desc custom_click_track'}
                    {...{
                      custom_click_track_value: `${props.clickTrackingLoc}|${tabTitle} tab |internal`,
                    }}
                  />
                </Button>
              </li>
            );
          })}
        </ul>
        {props.children ?? ''}
      </div>
    </StyledTab>
  );
};

//Export for Tab component with config for AEM
export const TabsEditable = (props: TabProps) => {
  return (
    <EditableComponent config={TabConfig} {...props}>
      <TabComponent {...props} />
    </EditableComponent>
  );
};
