// Imports for external libraries go here.
import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';

// separated by a blank line from external imports.
// The closer the import is to the file, the lower it should be in this list.
import { StyledNavigationContainer } from './NavigationContainer.styles';
import { NavigationContainerProps } from './NavigationContainer.types';
import { NavigationContext } from './NavigationContextProvider';
import { HeaderLogo, AEMModel } from '@marriott/mi-ui-library';

import { NavigationWrapper, NavigationUtilityLinkWrapper, UtilityNavigationType } from '../../molecules/Navigation';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useScrollStore, useStickyState } from '@marriott/mi-store-utils';

export const NavigationContainer: FC<NavigationContainerProps> = props => {
  const [iswhiteHeader, setIsWhiteHeader] = useState<boolean>(false);
  const [isMegaMenuActive, setIsMegaMenuActive] = useState<boolean>(false);
  const { model, cqPath, isAuthorMode } = props;
  const appliedCssClassNames = model?.appliedCssClassNames;
  const { logoUrl, logoImageAltText, logoType } = model?.resourceProperties ?? {};
  const primaryNavigationProps: AEMModel = model?.cqItems ? model.cqItems['primaryNavigation'] : {};
  const isTransparentHeader = model?.resourceProperties?.transparency === 'true';
  const utilityNavigation = (model?.cqItems ? model.cqItems['utilityNavigation'] : {}) as UtilityNavigationType;

  const setRegisteredComponent = useScrollStore(state => state.setRegisteredComponent);
  const scrollPosition = useScrollStore(state => state.scrollPosition);
  const { isSticky, offset } = useStickyState(model.componentId);

  const navRef = useRef(null);

  const headerContainerRef = useCallback((ref: HTMLDivElement) => {
    if (!ref) {
      return;
    }
    setRegisteredComponent({
      componentId: model.componentId,
      isStickyOnScrollUp: !!model?.scrollSetting?.isStickyOnScrollUp,
      isStickyOnScrollDown: !!model?.scrollSetting?.isStickyOnScrollDown,
      height: ref.clientHeight,
    });
  }, []);

  useEffect(() => {
    setIsWhiteHeader(isMegaMenuActive && scrollPosition > 0);
  }, [scrollPosition, isMegaMenuActive]);
  return (
    <NavigationContext.Provider value={{ offset, navRef }}>
      <StyledNavigationContainer
        id="navcontainer"
        data-component-name="o-common-static-navigationcontainer"
        ref={headerContainerRef}
        onMouseOver={() => setIsWhiteHeader(true)}
        onFocus={() => {
          setIsWhiteHeader(true);
        }}
        onMouseOut={() => {
          if (!isMegaMenuActive) {
            setIsWhiteHeader(false);
          }
        }}
        className={clsx(appliedCssClassNames, {
          'default-background inverse': !isSticky && !iswhiteHeader && isTransparentHeader,
          'header-sticky t-background-color': isSticky,
          'standard t-background-color': (iswhiteHeader && !isSticky) || !isTransparentHeader,
        })}
      >
        <header role="banner" ref={navRef}>
          <div className="row align-items-center mx-0">
            <div
              className={clsx('navigation-container', {
                'container-xl p-0': !appliedCssClassNames?.includes('standard'),
              })}
            >
              <div className="navigation-logo-container">
                <HeaderLogo logoUrl={logoUrl} logoImageAltText={logoImageAltText} logoType={logoType} />
              </div>
              <NavigationWrapper
                isAuthorMode={isAuthorMode}
                cqPath={cqPath}
                model={primaryNavigationProps}
                utilityNavigation={utilityNavigation}
                setIsMegaMenuActive={setIsMegaMenuActive}
              />
              {utilityNavigation?.[':items'] && (
                <NavigationUtilityLinkWrapper
                  utilityNavigation={utilityNavigation}
                  isRenderedInsideOverlay={false}
                  isAuthorMode={isAuthorMode}
                  cqPath={cqPath}
                  logoUrl={logoUrl}
                  logoImageAltText={logoImageAltText}
                  logoType={logoType}
                />
              )}
            </div>
          </div>
        </header>
      </StyledNavigationContainer>
    </NavigationContext.Provider>
  );
};
