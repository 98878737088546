import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Hero as HeroComp } from '@marriott/mi-ui-library';

export const HeroConfig = {
  emptyLabel: 'Hero',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/hero`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Hero = (props: any) => {
  return (
    <div data-testid="hero" data-component-name="o-common-static-hero">
      <EditableComponent config={HeroConfig} {...props}>
        <HeroComp {...props} />
      </EditableComponent>
    </div>
  );
};
