import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledCobrandCardHorizontalFlexible = styled.div`
  /* clamp (minVal : fallback if scaling value falls below minValue, scaling value, maxValue : fallback if scaling value falls below minValue) */
  /* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))) */
  padding-top: clamp(${toRem(62)}, calc(${toRem(62)} + 24 * ((100vw - ${toRem(365)}) / 211)), ${toRem(86)});
  @media ${baseVariables.mediaQuery.md} {
    padding-top: 0;
  }
  margin: 0 auto;
  .card-container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    border: ${baseVariables.border['borderWidth02']} solid ${baseVariables.color['neutral20']};
    border-radius: ${baseVariables.border['borderRadiusDefault']};
    padding: 0 ${toRem(24)};
    box-shadow: ${baseVariables.shadows['shadowSubtle01']};
    @media ${baseVariables.mediaQuery.md} {
      flex-direction: row;
      padding: ${toRem(12)} ${toRem(24)};
    }
    p {
      margin: 0;
    }
    .image-container {
      margin-top: clamp(${toRem(-72)}, calc(-1 * (${toRem(48)} + 24 * ((100vw - ${toRem(365)}) / 211))), ${toRem(-48)});
      @media ${baseVariables.mediaQuery.sm} {
        margin-top: -${toRem(68)};
      }
      @media ${baseVariables.mediaQuery.md} {
        margin-top: 0;
        padding-right: ${toRem(24)};
        display: flex;
        justify-content: center;
      }
      img {
        width: clamp(${toRem(170)}, calc(${toRem(170)} + 130 * ((100vw - ${toRem(365)}) / 211)), ${toRem(300)});
        @media ${baseVariables.mediaQuery.md} {
          width: ${toRem(122)};
        }
      }
    }
    .math-img-container {
      img {
        width: ${toRem(180)};
      }
    }
    .flexible-card {
      display: flex;
      padding-top: ${toRem(16)};
      text-align: center;
      flex-direction: column;
      @media ${baseVariables.mediaQuery.sm} {
        padding-top: ${toRem(24)};
      }
      @media ${baseVariables.mediaQuery.md} {
        flex: 1;
        padding: 0;
        text-align: left;
      }
    }
    .flexible-math-card {
      margin: 0;
      @media ${baseVariables.mediaQuery.lg} {
        margin: ${toRem(0)} ${toRem(80)};
      }
      @media ${baseVariables.mediaQuery.md} {
        margin-left: ${toRem(80)};
      }
    }
    .cobrand-math-content-section {
      margin-top: ${toRem(14)};
      max-width: 100%;
      margin-left: ${toRem(0)};
      @media ${baseVariables.mediaQuery.md} {
        margin-left: ${toRem(80)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(232)};
        margin-top: ${toRem(0)};
        margin-left: ${toRem(0)};
      }
      .total-amount {
        color: ${baseVariables.color.merch20};
      }
    }
    .content-section {
      flex-direction: column;
      @media ${baseVariables.mediaQuery.lg} {
        flex-direction: row;
      }
    }
    .disclaimer-and-cta-section {
      flex-direction: column-reverse;
      @media ${baseVariables.mediaQuery.md} {
        flex-direction: row;
      }
    }
    .evergreen-content-section {
      flex-direction: column;
      @media ${baseVariables.mediaQuery.md} {
        flex-direction: row;
      }
    }
    .flexible-card-content-container {
      display: contents;
    }
    .disclaimer-text {
      @media ${baseVariables.mediaQuery.md} {
        margin-left: ${toRem(80)};
      }
    }
    .flexible-click {
      margin-top: ${toRem(24)};
      margin-bottom: ${toRem(32)};
    }
    .disclaimer-and-cta-section .flexible-click {
      margin-bottom: ${toRem(24)};
    }
  }
  .card-math-container {
    padding: ${toRem(24)};
  }
`;
