import { APPLICATION_BREAKPOINTS } from '@marriott/mi-ui-library-shop';
import { useEffect, useState } from 'react';
import { logger } from '../../utils/src/logger';
import { useWindowSize } from './useWindowSize';

const allowedKeys = Object.keys(APPLICATION_BREAKPOINTS);

type keys = keyof typeof APPLICATION_BREAKPOINTS;

const { log } = logger({})('useCheckBreakpoint');
log.debug('APPLICATION_BREAKPOINTS from mi-ui-library', APPLICATION_BREAKPOINTS);

/**
 * Hook to return whethre current window size is greater than viewport size provided. Current sizes from
 * global styles are:
 * {
 *   "viewportXS": "0px",
 *   "viewportS": "576px",
 *   "viewportM": "768px",
 *   "viewportL": "992px",
 *   "viewportXl": "1200px"
 * }
 * @param viewportSize
 * @returns boolean
 */
export function useCheckBreakpoint(viewportSize: keys) {
  const [isGreater, setIsGreater] = useState(false);
  const { width } = useWindowSize();

  if (allowedKeys.indexOf(viewportSize.toString()) === -1) {
    log.error(
      `Incorrect viewport provided, should be one of ${JSON.stringify(allowedKeys)} with mappping as ${JSON.stringify(
        APPLICATION_BREAKPOINTS
      )}`
    );
  }

  const targetViewportSize = APPLICATION_BREAKPOINTS[viewportSize] || 0;

  useEffect(() => {
    if (width && width >= targetViewportSize) {
      setIsGreater(true);
    } else {
      setIsGreater(false);
    }
  }, [width, targetViewportSize]);

  return isGreater;
}
