import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '../../../styles';

const { color, mediaQuery } = baseVariables;

export const StyledPopupFooterGeneric = styled.div`
  ${rtl`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    width: 100%;
    max-width: 100%;
    background-color: ${color.neutral10};
    border-top: ${toRem(1)} solid ${color.neutral20};
    height: auto;
    padding: ${toRem(12)} ${toRem(16)};
    border-radius: 0 0 ${toRem(14)} ${toRem(14)};

    @media only ${mediaQuery.md} {
      padding: ${toRem(21)} ${toRem(32)};
    }

    .m-modal-scrim {
      height: ${toRem(44)};
      top: ${toRem(-44.5)};
    }
 `}
`;

export const StyledButtonGroup = styled.div`
  ${rtl`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: ${toRem(24)};
  `}
`;
