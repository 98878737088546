import React, { FC, Children } from 'react';
import { PopupFooterGenericProps } from '../Modal.types';
import { StyledPopupFooterGeneric, StyledButtonGroup } from './index.styles';

const PopupFooter: FC<PopupFooterGenericProps> = ({
  actions,
  className,
  customClass,
  fadeEffect = false,
  children,
}) => {
  return (
    <StyledPopupFooterGeneric className={className}>
      <>
        {fadeEffect && <div className="m-modal-scrim"></div>}
        {children}
        <StyledButtonGroup className={customClass}>
          {Children.map(actions, (action, index) => {
            return React.isValidElement(action)
              ? React.cloneElement(action as React.ReactElement, { key: index })
              : null;
          })}
        </StyledButtonGroup>
      </>
    </StyledPopupFooterGeneric>
  );
};

export default PopupFooter;
