// Styles for UtilityLinks go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledNavigationUtilityLink = styled.ul<{ isRenderedInsideOverLay: boolean }>`
  display: flex;
  padding: 0;
  margin: 0;

  ${({ isRenderedInsideOverLay }) =>
    isRenderedInsideOverLay
      ? `
        flex-direction: column;
        gap: ${toRem(24)};
        `
      : `
        align-items: center;
        gap: ${toRem(16)};
      `}

  .utility {
    &-item {
      list-style-type: none;
      white-space: nowrap;
      color: inherit;
      padding: 0;
      > a {
        color: inherit;
      }
      > [class*='utility-'] {
        ::before {
          height: ${toRem(24)};
          width: ${toRem(24)};
          padding: ${toRem(4)};
        }
      }
    }

    &-language-selector {
      :focus {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
      @media ${baseVariables.mediaQuery.lg} {
        order: 0;
      }
    }

    &-trips {
      :focus {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    &-link-text {
      color: inherit;
      font-weight: ${baseVariables.font.fontWeightRegular};
      line-height: ${toRem(15)};
      margin-left: ${toRem(4)};
      font-size: ${baseVariables.font.fontXs};
      @media ${baseVariables.mediaQuery.xl} {
        line-height: ${baseVariables.font.lineHeightS};
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      font-size: ${({ isRenderedInsideOverLay }) =>
        isRenderedInsideOverLay ? baseVariables.font.fontM : baseVariables.font.fontXxxl};
      font-weight: 400;
      color: inherit;

      ::before {
        padding: ${toRem(4)};
        height: ${toRem(32)};
        width: ${toRem(32)};
      }

      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${baseVariables.font.fontM};
        ::before {
          height: ${toRem(24)};
          width: ${toRem(24)};
          padding: ${toRem(4)};
        }
      }
    }

    &-mytrips-button,
    &-language-selector-button {
      display: flex;
      align-items: center;
      color: inherit;
      background: transparent;
      padding: 0;
      :focus {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }
    &-sigin {
      @media ${baseVariables.mediaQuery.lg} {
        .utility-link-text {
          font-size: 13px;
          line-height: 13px;
        }
        .utility-icon::before {
          height: ${toRem(16)};
          width: ${toRem(16)};
          padding: ${toRem(0)};
        }
      }
    }
  }
`;
