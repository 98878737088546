import { EditableComponent } from '@adobe/aem-react-editable-components';
import { BannerLayered as BannerLayeredComp } from '@marriott/mi-ui-library';

export const BannerLayeredConfig = {
  emptyLabel: 'BannerLayered',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/bannerlayered`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BannerLayered = (props: any) => {
  return (
    <div data-testid="banner-layered" data-component-name="o-common-static-bannerlayered">
      <EditableComponent config={BannerLayeredConfig} {...props}>
        <BannerLayeredComp {...props} />
      </EditableComponent>
    </div>
  );
};
