let LoggerClass;

const config = {
  MI_LOG_LEVEL: 'DEBUG', // will be override by ENV variable
  PERF_LOGGING_ENABLED: 'true',
};

// process.browser is deprecated, checking if clientside using window object
if (typeof window !== 'undefined') {
  LoggerClass = class Logger {
    constructor(identifiers) {
      this.identifiers = identifiers;
    }

    getLogger(loggerName) {
      const enableLogger = () => {
        const LOGS_ENABLED = localStorage.getItem('ENABLE_LOGS') === 'true';
        return LOGS_ENABLED;
      };
      return {
        log: (...args) => {
          if (enableLogger()) {
            console.log('%s | %s | LOG |', loggerName, this.getTimeStampFormat(), ...args);
          }
        },
        warn: (...args) => {
          if (enableLogger()) {
            console.warn('%s | %s | WARN |', loggerName, this.getTimeStampFormat(), ...args);
          }
        },
        info: (...args) => {
          if (enableLogger()) {
            console.info('%s | %s | INFO |', loggerName, this.getTimeStampFormat(), ...args);
          }
        },
        error: (...args) => {
          if (enableLogger()) {
            console.error('%s | %s | ERROR |', loggerName, this.getTimeStampFormat(), ...args);
          }
        },
        debug: (...args) => {
          if (enableLogger()) {
            console.debug('%s | %s | DEBUG |', loggerName, this.getTimeStampFormat(), ...args);
          }
        },
      };
      // }
    }

    /**
     * Achieve the following format for timestamp:
     * 2017-05-23 11:35:43.673
     *
     * @returns {String} Formatted Date string
     */
    getTimeStampFormat() {
      return new Date().toISOString().replace('Z', '').split('T').join(' ');
    }
  };
} else {
  // server side logic
  /* eslint-disable global-require */
  const os = require('os');
  const Winston = require('winston');
  const { createLogger, format, transports } = Winston;
  const { combine, timestamp, label, errors, printf } = format;
  /* eslint-enable global-require */

  /**

   * Logger class to fetch instances for logging with custom formatting enabled

   */

  LoggerClass = class Logger {
    constructor(requestIdentifiers) {
      this.logPrefix = this.getLogPrefix();
      this.requestIdString = this.getRequestIdentifierString(requestIdentifiers || {});
    }

    /**
     * Get the prefix for the log statement containing the log prefix from environment
     * (if available) and the hostname
     *
     * @returns {String} Prefix for the logging statement
     */
    getLogPrefix() {
      const hostName = os.hostname().toUpperCase();
      const logPrefix = process.env.LOG_PREFIX;

      return logPrefix ? `${logPrefix}|${hostName}` : hostName;
    }

    /**
     * Get the request identifier string for the log statement
     * consisting of the session id and the request id
     *
     * @param {Object} requestIdentifiers Request identifiers as received in the constructor
     * @returns {String} String consisting of session id and request id as available
     */
    getRequestIdentifierString(requestIdentifiers) {
      let requestIdString = '';
      const { sessionID, requestID } = requestIdentifiers;

      if (sessionID && requestID) {
        requestIdString += sessionID ? ` [SESSION ID: ${sessionID}]` : '';
        requestIdString += requestID ? ` [REQUEST ID: ${requestID}]` : '';
      }

      return requestIdString;
    }

    /**
     * Achieve the following format for timestamp:
     * 2017-05-23 11:35:43.673
     *
     * @returns {String} Formatted Date string
     */
    getTimeStampFormat() {
      return new Date().toISOString().replace('Z', '').split('T').join(' ');
    }

    /**
     * Achieve the following format for the log statement:
     * 2017-05-23 12:25:54.292 [<LOG_PREFIX>] [SESSION ID: <SESSION_ID>] [REQUEST ID: <REQUEST_ID>] [<LOG_LEVEL>] [<LOGGER_NAME>] - <MESSAGE>
     *
     * @param {Object} options Winston options for custom formatting
     * @returns {String} Custom logging format
     */
    // getFormatterString(options) {
    //   return `|LOG_LINE| ${options.timestamp()} [${options.level.toUpperCase()}] [${this.logPrefix}]${this.requestIdString} [${this.loggerName}] - ${options.message ? options.message : ''}`;
    // }

    getFormatterString = printf(
      ({
        // eslint-disable-next-line no-shadow
        level,
        message,
        label,
        timestamp,
        stack,
      }) =>
        `|LOG_LINE| ${timestamp} [${level.toUpperCase()}] [${this.logPrefix}]${this.requestIdString} [${label}] - ${
          message || ''
        } ${stack || ''}`
    );

    /**
     * Get the logger instance with request identifiers as part of formatter
     *
     * @param {String} loggerName Name to identify the log initiator
     */
    getLogger(loggerName) {
      return createLogger({
        level: (process.env.MI_LOG_LEVEL ?? config.MI_LOG_LEVEL).toLowerCase(),
        format: combine(
          label({ label: loggerName }),
          timestamp({
            format: this.getTimeStampFormat,
          }),
          errors({ stack: true }),
          this.getFormatterString
        ),
        transports: [new transports.Console()],
      });
    }
  };
}

/**
 * Constructor for PerfLogger
 *
 * @param {Object} logger Logger instance to be used
 * @constructor
 */
class PerfLogger {
  constructor(log) {
    this.logger = log;
    // turn on/off perf logging
    this.isPerfLoggingEnabled = (process.env.PERF_LOGGING_ENABLED || config.PERF_LOGGING_ENABLED) === 'true';
  }

  /**
   * End and return the ops time
   *
   * @param {String} start Perf logging start time
   * @param {Number} end Perf logging end time
   * @returns {Number} Time difference for ops involved
   * @private
   */
  getOpsTime(start, end) {
    return ((end - start) / 1000).toFixed(3);
  }

  /**
   * End timer and log response time
   *
   * @param {String} message Primary message for logger
   * @param {Number} start Perf logging start time
   * @param {Number} end Perf logging end time
   */
  log(message, start, end) {
    if (!this.isPerfLoggingEnabled) return;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    process.nextTick(() => {
      self.logger.error(`[PERFLOG] ${message} ${self.getOpsTime(start, end)} secs.`);
    });
  }
}

export const logger = ids => loggerName => {
  const log = new LoggerClass(ids).getLogger(loggerName);
  return {
    log,
    pLog: new PerfLogger(log),
  };
};
