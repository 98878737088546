import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';
// import { toRem } from '../../index';

export const StyledHero = styled.div<{ scrimGradient: string }>`
  display: flex;
  .hero_item {
    position: relative;
    overflow: hidden;
    .feature-img {
      width: 100vw;
    }
    .gradient {
      &:after {
        @media ${baseVariables.mediaQuery.sm} {
          height: 100%;
          background: ${props =>
            props?.scrimGradient?.includes('standard')
              ? `${baseVariables.gradient['baseGradient09']}`
              : `${baseVariables.gradient['baseGradient11']}`};
        }
        display: block;
        width: 60%;
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
  .hero {
    &__cnt-sec {
      position: absolute;
      display: flex;
      align-items: end;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      &:before {
        display: none;
      }
      .hero_eyebrow_content {
        .isIconOverlay,
        .isImageOverlay {
          margin-bottom: ${toRem(16)};
          @media ${baseVariables.mediaQuery.md} {
            margin-bottom: ${toRem(12)};
          }
          @media ${baseVariables.mediaQuery.lg} {
            margin-bottom: ${toRem(16)};
          }
          .logooverlay-img {
            max-width: ${toRem(150)};
            @media ${baseVariables.mediaQuery.lg} {
              max-width: ${toRem(200)};
            }
          }
        }
        .isIconOverlay {
          .icon_size {
            &:hover {
              background: transparent;
            }
            &::before {
              line-height: 1.25;
              font-size: ${toRem(56)};
              @media ${baseVariables.mediaQuery.lg} {
                font-size: ${toRem(64)};
              }
            }
            &.brand {
              &-logo-RZ::before {
                font-size: ${toRem(57.28)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(71.68)};
                }
              }
              &-logo-EB::before {
                font-size: ${toRem(22.08)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(27.52)};
                }
              }
              &-logo-JW::before {
                font-size: ${toRem(47.36)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(59.2)};
                }
              }
              &-logo-AK::before {
                font-size: ${toRem(49.92)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(62.72)};
                }
              }
              &-logo-BR::before {
                font-size: ${toRem(63.04)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(78.56)};
                }
              }
              &-logo-GE::before {
                font-size: ${toRem(57.6)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(72.8)};
                }
              }
              &-logo-CY::before {
                font-size: ${toRem(18.56)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(23.2)};
                }
              }
              &-logo-RI::before {
                font-size: ${toRem(21.6)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(27.04)};
                }
              }
              &-logo-SH::before {
                font-size: ${toRem(44.8)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(56.32)};
                }
              }
              &-logo-FI::before {
                font-size: ${toRem(33.76)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(42.24)};
                }
              }
              &-logo-TS::before {
                font-size: ${toRem(40.32)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(50.24)};
                }
              }
              &-logo-PR::before {
                font-size: ${toRem(52.48)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(65.44)};
                }
              }
              &-logo-OX::before {
                font-size: ${toRem(40.64)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(50.88)};
                }
              }
              &-logo-LC::before {
                font-size: ${toRem(65.6)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(81.92)};
                }
              }
              &-logo-WI::before {
                font-size: ${toRem(26.88)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(33.44)};
                }
              }
              &-logo-SI::before {
                font-size: ${toRem(56.32)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(70.4)};
                }
              }
              &-logo-MD::before {
                @include brand-icons-resize(17.6, 22.08);
                font-size: ${toRem(17.6)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(22.08)};
                }
              }
              &-logo-TX::before {
                font-size: ${toRem(24.8)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(31.04)};
                }
              }
              &-logo-DS::before {
                font-size: ${toRem(12.4)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(15.5)};
                }
              }
              &-logo-BG::before {
                font-size: ${toRem(27.76)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(34.72)};
                }
              }
              &-logo-AL::before {
                font-size: ${toRem(56)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(70.08)};
                }
              }
              &-logo-HV::before {
                font-size: ${toRem(52)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(64.96)};
                }
              }
              &-logo-WV::before {
                font-size: ${toRem(40)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(48)};
                }
              }
              &-logo-MG::before {
                font-size: ${toRem(40)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(48)};
                }
              }
              &-logo-WH::before {
                font-size: ${toRem(12.48)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(18.56)};
                }
              }
              &-logo-RS::before {
                font-size: ${toRem(32)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(48)};
                }
              }
              &-logo-SN::before {
                font-size: ${toRem(64)};
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: ${toRem(96)};
                }
              }
              &-logo-Escape {
                &::before {
                  font-size: unset;
                  line-height: unset;
                }
                font-size: 4.22rem;
                margin-left: -1.4rem;
                @media ${baseVariables.mediaQuery.lg} {
                  font-size: 5.275rem;
                  margin-left: -1.5rem;
                }
              }
            }
          }
        }
      }
      .hero_content {
        .hero_head {
          margin-bottom: ${toRem(0)};
        }
        @media ${baseVariables.mediaQuery.xl} {
          .hero_head {
            margin-bottom: ${toRem(4)};
          }
        }
      }
      .hero_cta-wrapper {
        display: flex;
        flex-direction: row;
        margin-top: ${toRem(4)};
        text-align: center;
        @media ${baseVariables.mediaQuery.sm} {
          margin-top: -${toRem(8)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          margin-top: ${toRem(0)};
        }
        .hero_sec-cta {
          margin-left: ${toRem(16)};
        }
      }
      .&_cta-center {
        justify-content: center;
      }
      @media ${baseVariables.mediaQuery.sm} {
        align-items: center;
      }
    }
  }
  .hero__cnt-ctr {
    position: relative;
    z-index: 1;
    padding: ${toRem(40)} ${toRem(16)} ${toRem(70)};
    margin: 0 auto;
    background: ${props =>
            props?.scrimGradient?.includes('standard')
              ? `${baseVariables.gradient['baseGradient08']}`
              : `${baseVariables.gradient['baseGradient10']}`};
    @media ${baseVariables.mediaQuery.sm} {
        padding-top: ${toRem(0)};
        background: none !important;
      }
  }
  .hero_iconText-wrapper {
    position: absolute;
    padding: ${toRem(3)} ${toRem(8)};
    background-color: rgba(28, 28, 28, ${baseVariables.opacity['opacity5']});
    border-radius: ${toRem(5)};
  }
  .hero_16x9 {
    .hero__cnt-ctr {
      padding: ${toRem(40)} ${toRem(16)} ${toRem(40)};
      @media ${baseVariables.mediaQuery.sm} {
        padding-top: ${toRem(0)};
        padding-bottom: ${toRem(0)};
      }
    }
    .hero_iconText-wrapper {
      right: ${toRem(16)};
      bottom: ${toRem(8)};
      @media ${baseVariables.mediaQuery.sm} {
        right: ${toRem(15)};
        bottom: ${toRem(13)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        right: ${toRem(30)};
        bottom: ${toRem(20)};
      }
      @media ${baseVariables.mediaQuery.xl} {
        right: ${toRem(32)};
        bottom: ${toRem(32)};
      }
    }
  }
  .hero_5x2 {
    .hero_iconText-wrapper {
      right: ${toRem(16)};
      top: ${toRem(16)};
      @media ${baseVariables.mediaQuery.sm} {
        right: ${toRem(15)};
        top: ${toRem(13)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        right: ${toRem(30)};
        top: ${toRem(20)};
      }
      @media ${baseVariables.mediaQuery.xl} {
        right: ${toRem(16)};
        top: ${toRem(16)};
      }
    }
  }
  .hero_3x1 {
    .hero__cnt-ctr {
      padding: ${toRem(40)} ${toRem(16)} ${toRem(16)};
      @media ${baseVariables.mediaQuery.sm} {
        padding-top: ${toRem(0)};
        padding-bottom: ${toRem(0)};
      }
    }
    .hero_iconText-wrapper {
      right: ${toRem(16)};
      top: ${toRem(16)};
      @media ${baseVariables.mediaQuery.sm} {
        right: ${toRem(15)};
        top: ${toRem(13)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        right: ${toRem(30)};
        top: ${toRem(20)};
      }
      @media ${baseVariables.mediaQuery.xl} {
        right: ${toRem(16)};
        top: ${toRem(16)};
      }
    }
  }
`;
