import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '../../styles';
import { StyledPopupProps } from './Modal.types';
import { StyledPopupMain } from './ModalBody/index.styles';

export const StyledPopupDiv = styled.div<StyledPopupProps>`
  ${rtl`
    ${(props: StyledPopupProps): string =>
      !props.show
        ? `display:none;`
        : `
    overflow: visible;
    width: 100%;
    position: fixed;
    z-index:1011;
    top: 0;
    height: auto;
    margin:0 auto;
    left:0;
    right:0;
    bottom:0;
    &:before{
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${baseVariables.color.base10};
      opacity:0.5;
    }
    @media only ${baseVariables.mediaQuery.md} {
      display: flex;
      align-items: center;
    }
    @media only ${baseVariables.mediaQuery.lg} {
      display: block;
    }`}
    `}
`;

export const StyledPopUpContentContainerDiv = styled.div`
  ${rtl`
  z-index: 1;
  background: ${baseVariables.color.alert10};
  box-shadow: 0 ${toRem(1)} ${toRem(15)} 0 rgba(0, 0, 0, 0.12);
  border-radius: ${toRem(14)};

  @media only ${baseVariables.mediaQuery.md} {
    width: 100%;
  }
  @media only ${baseVariables.mediaQuery.lg} {
    width: auto;
  }

  ${StyledPopupMain} {
    overflow: hidden;
    overflow-y: auto;
    height: fit-content;
  }

  .hide-class {
    display: none;
  }
  
  .custom-scrollbar {
    ::-webkit-scrollbar-thumb {
      border: ${toRem(4)} solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: ${toRem(9999)};
      background-color: rgb(187 182 182);
    }

    ::-webkit-scrollbar {
      width: ${toRem(14)};
    }
    ::-webkit-scrollbar-track {
      background-color:transparent;
      @media only ${baseVariables.mediaQuery.sm}{
        background-color:${baseVariables.color.alert10};
      }
    }
  }
  position: fixed;
  margin: 0;
  bottom: 0;
  max-width: 100%;
  width: 100%;
  ${StyledPopupMain} {
    padding: ${toRem(15)} ${toRem(24)};
  }
  @media only ${baseVariables.mediaQuery.md}{
    position: relative;
    margin: ${toRem(56)} auto;
    max-width: ${toRem(744)};
    ${StyledPopupMain} {
      padding: ${toRem(15)} ${toRem(32)};
      padding-right:${toRem(16)};
    }
  }
  `}
`;
