import { toRem } from '@marriott/mi-ui-library';

//Adding some styles here as they are removed from shared/mi-ui-library
//Moving forward we should not use these mediaqueries. Use mediaqueries only from shared/mi-ui-library and follow mobile first approach
//TODO: Code needs to be updated where we are using these. These mediaqueries should be removed and what is imported from shared/mi-ui-library should be used.
//TODO: we can move these mediaqueries and fonts and alphaColor to common
export const mediaQuery = {
  desktop: ` screen and (min-width:  ${toRem(992)})`,
  mobileOnly: ` screen and (max-width: ${toRem(767)})`,
  minDesktop: ` screen and (min-width: ${toRem(1200)})`,
  tabletOnly: ` (min-width:${toRem(768)}) and (max-width:${toRem(991)})`,
  smallMobileOnly: ` screen and (max-width: ${toRem(350)})`,
  tablet: ` screen and (min-width:${toRem(768)})`,
  tabletMax: ` screen and (max-width: ${toRem(1199)})`,
  allTablets: ` screen and (min-width: ${toRem(768)}) and (max-width: ${toRem(1025)}) `,
  mobileTablet: ` screen and (max-width: ${toRem(991)})`,
  mobile: ` screen and (max-width: ${toRem(576)})`,
};

export const font = {
  Swiss721BTRegular: 'Swiss721-BT-Regular, Helvetica, Arial, sans-serif',
  Swiss721BTMedium: 'Swiss721-BT-Medium, Helvetica, Arial, sans-serif',
};
