// Styles for Stepper go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledStepper = styled.div`
  button:focus-visible {
    outline: ${toRem(1)} solid ${baseVariables.color.black};
  }

  .disable-hover {
    cursor: not-allowed;
  }

  .btn-disable {
    pointer-events: none;
  }
`;
