import { Model } from '@adobe/aem-spa-page-model-manager';
import { produce } from 'immer';
import throttle from 'lodash/throttle';
import { StateCreator } from 'zustand';
import { createAppStore } from '../zustand';
import { EXPERIENCE_FRAGMENT_TYPE } from './constants';

export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
  INDETERMINATE = '',
}

export interface ScrollSettings {
  isNavComponent?: boolean;
  isStickyOnScrollDown?: boolean;
  isStickyOnScrollUp?: boolean;
}
export interface ScrollStoreModel extends Model {
  componentId: string;
  scrollSetting?: ScrollSettings;
  ':children'?: {
    [key: string]: ScrollStoreModel;
  };
  ':items'?: {
    [key: string]: ScrollStoreModel;
  };
}
export interface RegisteredComponent {
  componentId: string;
  isStickyOnScrollUp: boolean;
  isStickyOnScrollDown: boolean;
  height: number;
}
export interface ScrollState {
  scrollPosition: number;
  prevScrollPosition: number;
  scrollDirection: ScrollDirection;
  prevScrollDirection: ScrollDirection;
  navComponentOrder: string[];
  registeredComponents: {
    [key: RegisteredComponent['componentId']]: RegisteredComponent & { itemsOrderIndex: number };
  };
  initialize: (data: Model) => void;
  destroy: () => void;
  handleScroll: () => void;
  setNavComponentOrder: (data: Model) => void;
  setRegisteredComponent: (component: RegisteredComponent) => void;
}

const initialState = {
  scrollPosition: 0,
  prevScrollPosition: 0,
  scrollDirection: ScrollDirection.INDETERMINATE,
  prevScrollDirection: ScrollDirection.INDETERMINATE,
  navComponentOrder: [],
  registeredComponents: {},
};

/**
 * returns an array of component IDs in their respective order,
 * including any components nested within experience fragments */
const getNavComponentOrder = (data: ScrollStoreModel): string[] => {
  if (!data) return [];
  return (
    data?.[':itemsOrder']?.reduce((order: string[], key: string) => {
      const currentComponent = data?.[':items']?.[key] as ScrollStoreModel;
      const isExperienceFragment = currentComponent?.[':type'] === EXPERIENCE_FRAGMENT_TYPE;
      // don't do anything if this is not a nav component and not an experience fragment
      if (!isExperienceFragment && !currentComponent?.scrollSetting?.isNavComponent) {
        return order;
      }
      order.push(
        // if we have an experience fragment, spread its respective component IDs here inline
        ...(isExperienceFragment
          ? getNavComponentOrder(
              currentComponent?.[':items']?.['root']?.[':items']?.['responsivegrid'] as ScrollStoreModel
            )
          : [currentComponent['componentId' as keyof Model] as string])
      );
      return order;
    }, []) ?? []
  );
};

export const clientScrollStore: StateCreator<ScrollState> = (set, get) => {
  return {
    ...initialState,
    initialize: (data: Model) => {
      if (!data) {
        return;
      }
      const { setNavComponentOrder, handleScroll } = get();
      setNavComponentOrder(data);

      if (typeof document === 'undefined') {
        return;
      }

      document.addEventListener('scroll', handleScroll);
      handleScroll();
    },
    destroy: () => {
      document.removeEventListener('scroll', get().handleScroll);
    },
    handleScroll: throttle(() => {
      set(state => ({
        prevScrollPosition: state.scrollPosition,
        scrollPosition: window.scrollY,
        prevScrollDirection: state.scrollDirection,
        scrollDirection:
          window.scrollY > state.scrollPosition
            ? ScrollDirection.DOWN
            : window.scrollY < state.scrollPosition
            ? ScrollDirection.UP
            : ScrollDirection.INDETERMINATE,
      }));
    }, 100),
    setNavComponentOrder: (data: Model) => {
      if (!data) {
        return;
      }
      set({ navComponentOrder: getNavComponentOrder(data as ScrollStoreModel) });
    },
    setRegisteredComponent: (component: RegisteredComponent) => {
      const { navComponentOrder } = get();

      // if the store hasn't been instantiated do nothing.
      if (!navComponentOrder.length) {
        return;
      }

      set(
        produce(state => {
          state.registeredComponents[component.componentId] = {
            ...component,
            itemsOrderIndex: navComponentOrder.findIndex(id => id === component.componentId),
          };
        })
      );
    },
  };
};

export const useScrollStore = createAppStore(clientScrollStore, { usePersistentStore: false });

export const useStickyState = (componentId: string) => {
  const registeredComponents = useScrollStore(state => state.registeredComponents);
  const scrollPosition = useScrollStore(state => state.scrollPosition);
  const scrollDirection = useScrollStore(state => state.scrollDirection);
  const prevScrollDirection = useScrollStore(state => state.prevScrollDirection);
  const component = registeredComponents[componentId];
  const isScrolled = scrollPosition > 0;
  const { UP, DOWN, INDETERMINATE } = ScrollDirection;
  const stickyState = { isSticky: false, offset: 0, top: 0 };

  if (!component || typeof window === 'undefined') {
    return stickyState;
  }

  const { itemsOrderIndex, isStickyOnScrollDown, isStickyOnScrollUp, height } = component;

  // if we can't determine a scroll direction (rerender without scrolling),
  // use the previous direction
  const isScrolling = (dir: ScrollDirection) =>
    scrollDirection === INDETERMINATE ? isScrolled && prevScrollDirection === dir : scrollDirection === dir;
  const isScrollingDown = isScrolling(DOWN) && isStickyOnScrollDown;
  const isScrollingUp = isScrolling(UP) && isStickyOnScrollUp;

  // for all components "previous" to the given component in the order,
  // add the offset and top position based on the scroll direction
  Object.values(registeredComponents)
    .filter(c => c && c.itemsOrderIndex < itemsOrderIndex)
    .forEach(c => {
      stickyState.offset += c.height;
      if ((isScrollingDown && c.isStickyOnScrollDown) || (isScrollingUp && c.isStickyOnScrollUp)) {
        stickyState.top += c.height;
      }
    });

  stickyState.isSticky =
    (isScrollingDown && scrollPosition > stickyState.offset) ||
    (isScrollingUp && scrollPosition > height / 2 + stickyState.offset);

  return stickyState;
};
