import { useEffect, useRef } from 'react';
import { PopupProps } from './Modal.types';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalFooterGeneric from './ModalFooterGeneric';
import { StyledPopUpContentContainerDiv, StyledPopupDiv } from './Modal.styles';
import clsx from 'clsx';

const Modal: React.FC<PopupProps> = ({
  className,
  show,
  children,
  handleBlur,
  popupOpenState,
  setPopupOpenState,
  role,
  labelledBy,
  onClose,
  secondaryClassName,
  modalId,
  disableScrollOnBody = false,
  ...props
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const popupElem = popupRef.current;
    if (popupElem) {
      const setupFocusTrap = () => {
        const handleKeyDown = (event: KeyboardEvent) => {
          // Find all focusable elements within the popup
          const focusableElements = popupElem?.querySelectorAll(
            '[tabindex]:not([disabled]), a[href]:not([disabled]), button:not([disabled]), input:not([disabled]), textarea:not([disabled]), select:not([disabled])'
          );
          // Find the first and last focusable elements
          const firstFocusableElement = focusableElements?.[0] as HTMLInputElement;
          const lastFocusableElement = focusableElements?.[focusableElements.length - 1] as HTMLInputElement;
          // Handle keyboard events
          if (event.key === 'Tab') {
            if (!event.shiftKey) {
              // Tab pressed (forward)
              if (document.activeElement === lastFocusableElement) {
                event.preventDefault(); // Prevent default tab behavior
                firstFocusableElement?.focus(); // Focus on the first element
              }
            } else if (event.shiftKey) {
              // Shift+Tab pressed (backward)
              if (document.activeElement === firstFocusableElement) {
                event.preventDefault(); // Prevent default tab behavior
                lastFocusableElement?.focus(); // Focus on the last element
              }
            }
          } else if (event.key === 'Escape') {
            setPopupOpenState?.(false);
            onClose?.();
          }
        };
        // Add event listener for keyboard navigation
        popupElem.addEventListener('keydown', handleKeyDown);
        // Cleanup function: remove the event listener when the component unmounts
        return () => {
          popupElem.removeEventListener('keydown', handleKeyDown);
        };
      };

      // Set up focus trap immediately for modals with static content
      const cleanupFocusTrap = setupFocusTrap();

      // Set up focus trap when modal's content changes (for modals with dynamic content)
      const observer = new MutationObserver(setupFocusTrap);
      observer.observe(popupElem as Node, { childList: true, subtree: true });

      return () => {
        cleanupFocusTrap();
        observer.disconnect();
      };
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // close popup on desktop if tab focus is out of popup or user clicks outside the popup
    if (handleBlur && popupOpenState && setPopupOpenState) {
      const popupElem = popupRef.current;
      if (popupElem && popupOpenState) {
        // Below, we are checking if the clicking is on any of the elements that are not contained
        // within 'popupElem' but are used to either open the popup or to return to the popup and closing popup accordingly
        const clickListener = (event: Event): void => {
          if (
            !popupElem.contains(event.target as Element) &&
            !popupElem.contains(document.activeElement) &&
            !(
              document.activeElement?.classList.contains('sm-search-result-all-filter-component-button') ||
              document.activeElement?.classList.contains('hqv-modal-opener') ||
              // The 'gm-ui-hover-effect' is the class contained in the google maps 'keyboard controls' close button.
              // Adding the condition prevents unexpected modal closings by clicking on the close btn.
              document.activeElement?.classList.contains('gm-ui-hover-effect') ||
              (event?.target as HTMLElement)?.parentElement?.classList.contains('return-to-categories') ||
              (event?.target as HTMLElement)?.classList.contains('return-to-categories') ||
              (event?.target as HTMLElement)?.classList.contains('gm-ui-hover-effect')
            )
          ) {
            if (onClose) {
              setPopupOpenState(false);
              onClose();
            }
          }
        };

        // focus out handler
        const focusoutListener = (event: FocusEvent): void => {
          if (
            event.relatedTarget &&
            !popupElem.contains(event.relatedTarget as Element) &&
            !popupElem.contains(document.activeElement) &&
            !popupElem.contains(event.target as Element)
          ) {
            if (onClose) {
              setPopupOpenState(false);
              onClose();
            }
          }
        };
        const clickListenerForToolTip = (event: Event): void => {
          if (
            !popupElem.contains(event.target as Element) &&
            !popupElem.contains(document.activeElement) &&
            !(event.target as Element)?.classList.contains('m-link-icon-button-info')
          ) {
            if (onClose) {
              onClose();
            }
            setPopupOpenState(false);
          }
        };
        //Only if its a tooltip popup
        if (secondaryClassName) {
          document.addEventListener('click', clickListenerForToolTip);
        } //other popups
        else {
          document.addEventListener('click', clickListener);
          popupElem.addEventListener('focusout', focusoutListener);
        }

        document.addEventListener('click', clickListener);
        popupElem.addEventListener('focusout', focusoutListener);

        return (): void => {
          document.removeEventListener('click', clickListener);
          document.removeEventListener('click', clickListenerForToolTip);
          popupElem.removeEventListener('focusout', focusoutListener);
        };
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (): void => {};
  }, [handleBlur, popupOpenState, setPopupOpenState]);

  useEffect(() => {
    if (popupOpenState) {
      popupRef?.current?.focus();
    }
  }, [popupOpenState]);

  useEffect(() => {
    if (disableScrollOnBody) {
      document.body.style.overflow = show ? 'hidden' : 'auto';
      return () => {
        document.body.style.overflow = 'auto';
      };
    } else return;
  }, [show, disableScrollOnBody]);

  return (
    <StyledPopupDiv
      data-component-name="m-ui-library-Modal"
      data-testid="ui-library-Modal"
      className={className}
      show={show}
    >
      <StyledPopUpContentContainerDiv
        className={clsx('custom-scrollbar', secondaryClassName)}
        ref={popupRef}
        role={role}
        aria-labelledby={labelledBy}
        tabIndex={0}
        {...props}
        {...(modalId ? { id: modalId } : {})} // add id props
      >
        {children}
      </StyledPopUpContentContainerDiv>
    </StyledPopupDiv>
  );
};

export default Object.assign(Modal, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
  FooterGeneric: ModalFooterGeneric,
});
