import { baseVariables, toRem } from '../styles';
import { FONT_ICON_1_1_SIZE } from './enums/enums';

const generateLogoStyle = (fontIcon: string, logoRatio: typeof FONT_ICON_1_1_SIZE, mediaQuery: string) => {
  const ratio = logoRatio[fontIcon] ?? logoRatio['DEFAULT'];
  return ratio?.mobileSize
    ? `font-size: ${toRem(ratio.mobileSize)};
       line-height: normal;
       @media ${mediaQuery} {
         font-size: ${toRem(ratio.deskTopSize)};
       }`
    : '';
};

export const logoResize = (fontIcon: string, logoRatio: typeof FONT_ICON_1_1_SIZE, sameMobileTabletView?: boolean) => {
  const mediaQuery = sameMobileTabletView ? baseVariables.mediaQuery.xl : baseVariables.mediaQuery.md;
  return generateLogoStyle(fontIcon, logoRatio, mediaQuery);
};
