// Imports for external libraries go here.
import React, { FC } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { StepperTextBlockProps } from './StepperTextBlock.types';
import { Stepper } from '../Stepper/Stepper';
import { StyledStepperTextBlock } from './StepperTextBlock.styles';

// Use named rather than default exports.
export const StepperTextBlock: FC<StepperTextBlockProps> = ({
  copyText,
  stepperValue,
  handleIncrement,
  handleDecrement,
  subHeadingText,
  disableIncrement,
  disableDecrement,
  helperText,
  increamentLabel,
  decreamentLabel,
  clickTrackloc,
}) => {
  return (
    <StyledStepperTextBlock
      data-component-name="m-ui-library-StepperTextBlock"
      data-testid="ui-library-StepperTextBlock"
    >
      <div className="m-stepper-wrapper">
        <div className="m-stepper-label">
          <span className="t-font-s">{copyText}</span>
          {subHeadingText && <span className="m-stepper-desc">{subHeadingText}</span>}
          {helperText && <span className="m-stepper-desc">{helperText}</span>}
        </div>

        <Stepper
          stepperText={stepperValue}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          disableIncrement={disableIncrement}
          disableDecrement={disableDecrement}
          analyticsVal={`${clickTrackloc}`}
          increamentLabel={increamentLabel}
          decreamentLabel={decreamentLabel}
        />
      </div>
    </StyledStepperTextBlock>
  );
};
