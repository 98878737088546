import { Container } from '@adobe/aem-react-editable-components';

import { Navigation as NavigationREACT } from './Navigation';
import { AemContainerConfig, NavigationWrapperProps } from './Navigation.types';
import { SortedModelArray, sortAEMModelItemsByOrder } from '@marriott/mi-ui-library';
import clsx from 'clsx';
export const NavigationWrapper = (props: NavigationWrapperProps) => {
  const cqPath = `${props.cqPath}/primaryNavigation`;
  const primaryNavigationItems: SortedModelArray = props?.model ? sortAEMModelItemsByOrder(props?.model) : [];

  const isPrimarynavigationAvailable = !!primaryNavigationItems?.length;
  const NavigationConfig: AemContainerConfig = {
    emptyLabel: 'Marriott Navigation',
    isEmpty: () => false,
    resourceType: 'mi-aem-homepage-spa/components/content/primaryNavigation',
    isInEditor: !!props?.isAuthorMode,
    cqPath: cqPath,
  };

  return isPrimarynavigationAvailable ? (
    <Container
      className={clsx('primary-navigation-wrapper ml-lg-5', { 'py-0': props?.isAuthorMode })}
      {...NavigationConfig}
      childPages={
        <NavigationREACT
          primaryNavigationItems={primaryNavigationItems}
          cqPath={cqPath}
          isAuthorMode={props?.isAuthorMode}
          utilityNavigation={props.utilityNavigation}
          setIsMegaMenuActive={props?.setIsMegaMenuActive}
          megaMenuCloseButtonLabel={props?.model?.resourceProperties?.megaMenuCloseButton}
        />
      }
    />
  ) : (
    <div />
  );
};
