/* eslint-disable @typescript-eslint/no-explicit-any */
import { TrackingPropsInterface } from '../../utils/enums/enums';

export enum LinksCollectionVariations {
  Standard = 'standardLinks',
  NavLink = 'Link',
}
export enum LinksCollectionAlignment {
  Vertical = 'vertical-align',
  Horizontal = 'horizontal-align',
}
export interface LinksCollectionProps {
  id?: string;
  heading?: string;
  variation?: LinksCollectionVariations;
  headingClassName?: string | string[];
  listCollectionClassname?: string | string[];
  linkListClassName?: string | string[];
  listItemClassName?: string | string[];
  listIconClassName?: string | string[];
  linkListData: any;
  alignment?: LinksCollectionAlignment;
  children?: React.ReactNode;
  trackingProperties?: TrackingPropsInterface;
  hasIconArrow?: boolean;
  cardLinkIconClass?: string;
  componentName?: string;
  componentId?: string;
}
