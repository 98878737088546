import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CardVertical as CardVerticalComp, CardVerticalProps } from '@marriott/mi-ui-library';
import { CardVerticalStyled } from './CardVertical.styles';

export const CardVerticalConfig = {
  emptyLabel: 'CardVertical',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/cardvertical`,
};

export const CardVertical = (props: CardVerticalProps) => (
  <CardVerticalStyled
    className="d-flex p-0 col-12 standard"
    data-testid="card-vertical"
    data-component-name="o-common-static-cardvertical"
  >
    <EditableComponent config={CardVerticalConfig} {...props}>
      <CardVerticalComp {...props} />
    </EditableComponent>
  </CardVerticalStyled>
);
