import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';

export const StyledDropdownOptionList = styled.div`
  .highlighted-item: focus {
    background: ${baseVariables.color.neutral20};
    border-radius: ${toRem(8)};
    outline: none;
    z-index: 100;
  }
  .recent-list-heading {
    margin-bottom: 0;
    padding: 0 0 ${toRem(8)} ${toRem(8)};
  }
  ul {
    border-top: ${toRem(1)} solid #eeeeee;
    list-style: none;
    padding-left: 0;
  }
  .grid-layout {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: ${toRem(16)};
    padding-bottom: ${toRem(16)};
    margin-bottom: 0;

    @media ${baseVariables.mediaQuery.lg} {
      padding-top: ${toRem(4)};
      margin-top: ${toRem(-1)};
      padding-bottom: ${toRem(4)};
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
  }
`;
