export interface UserProfileImageProps {
  userImage?: string;
  userImageAltText: string;
  userType?: UserType;
  lifeTimeLevel?: boolean;
}

export const enum UserType {
  MEMBER = 'R',
  SILVER = 'S',
  GOLD = 'G',
  PLATINUM = 'P',
  TITANIUM = 'PPE',
  AMBASADOR = 'PPAE',
}
