import styled from 'styled-components';

export const StyledAccordionContainer = styled.span`
  .acc:last-child {
    .accordion {
      .accordion__container {
        border-bottom: 0;
        border-right: 0;
      }
    }
  }
`;
