import { useState, useEffect } from 'react';

interface SizeState {
  width: undefined | number;
  height: undefined | number;
}
// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<SizeState>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    //to check if device type is android or not
    function androidOrIOS() {
      const userAgent = navigator.userAgent;
      const platform = navigator.platform;
      const isLinuxBased = /Linux/i.test(platform); // for android based tablets, we dont get appropriate info in navigator.userAgent , hence used navigator.platform as Android devices use Linux based platforms
      if (/android/i.test(userAgent) || isLinuxBased) {
        return 'android';
      }
      return null;
    }
    const deviceOS = androidOrIOS();
    if (deviceOS === 'android') {
      //event to catch screen orientation change and resize (basically when user switches from Potrait to Landscape or vice-versa)
      const orientationQuery = window.matchMedia('(orientation: portrait)');
      orientationQuery.addEventListener('change', handleResize);
      return () => window.removeEventListener('change', handleResize);
    }

    //resize on app load
    window.addEventListener('load', handleResize);

    //Only resize if it´s not Android
    if (deviceOS !== 'android') {
      // Add event listener
      window.addEventListener('resize', handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('load', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
