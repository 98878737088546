import { Container } from '@adobe/aem-react-editable-components';

import { SignInDrawer as SignInDrawerREACT } from './index';
import { AemContainerConfig } from '../Navigation';
import { SignInDrawerWrapperProps } from './SignInDrawer.types';
import clsx from 'clsx';
export const SignInDrawerWrapper = (props: SignInDrawerWrapperProps) => {
  const SignInDrawerConfig: AemContainerConfig = {
    emptyLabel: 'Marriott Sign In',
    isEmpty: /* istanbul ignore next */ () => false,
    resourceType: 'mi-aem-homepage-spa/components/content/signin',
    isInEditor: !!props?.isAuthorMode,
    cqPath: props?.cqPath || '',
  };
  return (
    <Container
      {...SignInDrawerConfig}
      className={clsx({ 'py-0': props?.isAuthorMode })}
      childPages={<SignInDrawerREACT {...props} />}
    />
  );
};
