/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useEffect } from 'react';
import clsx from 'clsx';
import { constants } from '../../constants/ApplicationConstants';
import { LinkProps } from './Link.types';
import { getClickTrackValue, trackImpression } from '../../utils/TrackingPropertyUtils';

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      id,
      text,
      linkHref,
      ariaLabel,
      rel,
      linkClassName,
      trackingProperties,
      callback,
      target = '_blank',
      custom_click_track_value,
      compName,
      linkType = 'external',
      children,
      // when anchor tags are not given an href they are not focusable, this prevents that from happening in the case of an authoring mistake
      tabIndex = 0,
      ...props
    },
    ref: any
  ) => {
    const trackingProps = {
      ...trackingProperties,
      isCoBrand: trackingProperties?.merchandisingCategory === constants.COBRAND_NON_POINTS_MATH,
    };
    useEffect(() => {
      if (!ref?.current?.classList?.contains('track-completed')) {
        if (!custom_click_track_value) {
          getClickTrackValue(linkType, trackingProps, ref);
        }
        trackImpression(trackingProps || {}, text || '', props?.preTrackVal);
        ref?.current?.classList?.add('track-completed');
      }
    }, []);

    return (
      <a
        data-component-name="a-ui-library-Link"
        data-testid="ui-library-Link"
        id={id}
        onClick={callback}
        target={target}
        href={linkHref}
        rel={rel}
        className={clsx(linkClassName, trackingProps?.clickTrack ? 'custom_click_track' : '')}
        aria-label={ariaLabel}
        ref={ref}
        data-tracking-content-position={trackingProps?.trackingContentPosition}
        data-tracking-offer-type={trackingProps?.trackingOfferType}
        data-at-c-ce-var48={trackingProps?.atCCeVar48}
        data-tracking-description={trackingProps?.trackingDescription}
        data-tracking-tag={trackingProps?.trackingTag}
        data-comp-name={compName}
        data-custom_click_track_value={custom_click_track_value}
        data-is-co-brand={trackingProps?.isCoBrand}
        data-is-click-track={trackingProps?.clickTrack}
        data-is-impression-track={trackingProps?.impressionTrack}
        data-is-impression-count={trackingProps?.impressionCount}
        tabIndex={tabIndex}
      >
        {text}
        {children}
      </a>
    );
  }
);
