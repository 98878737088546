import React, { useEffect, useState, FC } from 'react';
import ReactDOM from 'react-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';

import { VideoProps } from './Video.types';
import { Modal, Image } from '@marriott/mi-ui-library';
import { canUseDOM } from '../../utils/helper';
import { StyledVideoPlayer, StyledVideoModal } from './Video.styles';
import clsx from 'clsx';

interface PlayerInstance {
  paused: () => boolean;
  pause: () => void;
  currentTime: () => number;
  setCurrentTime: (time: number) => void;
}

export const Video: FC<VideoProps> = props => {
  const {
    id,
    playerId = 'default',
    accountId,
    videoId,
    poster,
    clickTrack,
    custom_click_track_value,
    ariaLabel,
    renditions,
    enableMute = true,
    enableVideoLoop = false,
    onError,
  } = props;

  const [error, setError] = useState(false);
  const [viewPort, setViewPort] = useState('Desktop');
  const [player, setPlayer] = useState<PlayerInstance | null>(null);
  const [videoProgress, setVideoProgress] = useState(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobileView = viewPort === 'MobileTablet';
  const modalRoot = canUseDOM && document.getElementsByTagName('body')[0];

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 1200) {
      setViewPort('MobileTablet');
    }
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showModal]);

  const handleResize = () => {
    const xs = window.matchMedia('(max-width: 1199px)');
    if (xs?.matches) {
      setViewPort('MobileTablet');
    } else {
      setViewPort('Desktop');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [player]);

  useEffect(() => {
    if (error && onError) {
      onError();
    }
  }, [error, onError]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document?.pictureInPictureElement && !document?.fullscreenElement) {
        closeModal();
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuccess = (success: any) => {
    const { ref } = success;
    if (!ref) return;

    ref.on('error', () => {
      setError(true);
    });

    if (clickTrack) {
      const playControlButton = ref.el_.querySelector('.vjs-play-control');
      if (playControlButton) {
        playControlButton.setAttribute('data-custom_click_track_value', custom_click_track_value);
        playControlButton.classList.add('custom_click_track');
      }
    }

    // Set the player instance when it's ready
    setPlayer(ref);

    // Set the video progress when the player is ready
    ref.on('ready', () => {
      ref.currentTime(videoProgress);
    });

    // Save the video progress when the video ends
    ref.on('ended', () => {
      setVideoProgress(ref.currentTime());
    });

    // Save the video progress when the video is paused
    ref.on('pause', () => {
      setVideoProgress(ref.currentTime());
    });

    // Update the video progress while the video is playing
    ref.on('timeupdate', () => {
      setVideoProgress(ref.currentTime());
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const renderPlayer = (autoplay: boolean) => (
    <ReactPlayerLoader
      id={id}
      accountId={accountId}
      videoId={videoId}
      playerId={playerId}
      poster={poster}
      onSuccess={handleSuccess}
      onFailure={() => setError(true)}
      options={{
        responsive: true,
        autoplay,
        preload: 'none',
        muted: enableMute,
        aspectRatio: '16:9',
        loop: enableVideoLoop,
        src: 'https://players.brightcove.net',
        userActions: {
          doubleClick: false,
        },
      }}
      aria-label={ariaLabel}
    />
  );

  return (
    <StyledVideoPlayer>
      <div className="video-image-container">
        <Image altText={''} renditions={renditions} dynamic={false} defaultImageURL={poster} />
        <div className="video-image-btn-container">
          <button
            className="video-image-play-btn"
            aria-label="Play"
            custom_click_track_value=""
            onClick={() => {
              setShowModal(true);
            }}
          >
            <svg fill="none" height="90" viewBox="0 0 90 90" width="90" xmlns="http://www.w3.org/2000/svg">
              <circle cx="45" cy="45" fill="#1C1C1C" fillOpacity="0.62" r="45"></circle>
              <path d="M63 45.5L36.75 60.6554L36.75 30.3446L63 45.5Z" fill="#EEEEEE"></path>
            </svg>
          </button>
        </div>
      </div>
      {showModal &&
        modalRoot &&
        ReactDOM.createPortal(
          <StyledVideoModal>
            <Modal
              show={showModal}
              className="video-modal-main-container"
              secondaryClassName={clsx(isMobileView ? 'video-modal-container-mobile' : 'video-modal-container')}
            >
              <Modal.Header
                labelText={''}
                popupHeaderOnCLoseFunc={closeModal}
                className="video-modal-header"
                popupCloseClassName="video-close-btn m-icon-button-primary"
              />
              <Modal.Body className="p-0 video-modal-body">{renderPlayer(true)}</Modal.Body>
            </Modal>
          </StyledVideoModal>,
          modalRoot
        )}
    </StyledVideoPlayer>
  );
};
