import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '../../styles';
interface StyledComponentProps {
  showUnderline: boolean;
}
export const StyledInputTextField = styled.div<StyledComponentProps>`
  ${rtl`
  
    .mobile-font-text-class {
      font-size: ${toRem(18)} !important;
    }
    &:after{
      content: "";
      position: absolute;
      left: 50%;
      top: ${toRem(52)};
      transform: translateX(-50%);
      width: 100%;
      border-bottom: ${(props: { showUnderline: boolean }) =>
        props.showUnderline ? toRem(2) + ' solid ' + baseVariables.color.neutral40 : 0} ;
    }   
  `}
`;
