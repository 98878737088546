import styled from 'styled-components';
import { toRem, baseVariables, Button } from '@marriott/mi-ui-library';

export const StyledSignInForm = styled.form`
  .welcomeText {
    white-space: normal;
    padding-top: ${toRem(16)};
    @media ${baseVariables.mediaQuery.lg} {
      padding-top: ${toRem(20)};
    }
  }

  .subHeading {
    max-width: fit-content;
    font-size: ${toRem(14)};
    color: ${baseVariables.color['neutral40']};
    margin-bottom: ${toRem(16)};
  }

  .checkBoxLabel label:first-of-type {
    margin-bottom: 0;
  }

  .signInButton {
    padding-left: ${toRem(36)};
    padding-right: ${toRem(36)};
    font-weight: 500;
    line-height: ${toRem(20)};
  }

  .linkClass {
    color: ${baseVariables.color['base10']};
    line-height: ${toRem(20)};
    border-bottom-color: ${baseVariables.color['base10']};
  }

  .errorMessage {
    color: ${baseVariables.color.alert50};
    font-size: ${baseVariables.font.fontXs};
  }
`;

export const FullWidthContainer = styled.div`
  width: 100%;
`;

export const WrapText = styled.div`
  text-wrap: wrap;
`;

export const StyledLinkButton = styled(Button)`
  background: none;
  padding: 0;
`;

export const JoinNowWrapper = styled.div`
  margin-top: ${toRem(40)};
  @media ${baseVariables.mediaQuery.lg} {
    margin-top: ${toRem(52)};
  }
  .join-now-label {
    @media ${baseVariables.mediaQuery.sm} {
      line-height: ${toRem(27.5)};
    }
    @media ${baseVariables.mediaQuery.md} {
      line-height: ${toRem(31.9)};
    }
  }
  .join-now-subheading {
    line-height: ${toRem(21)};
  }
  .join-now-button {
    line-height: ${toRem(21)};
  }
`;
