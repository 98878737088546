import { tags, TrackingPropsInterface, size } from '../../utils/enums/enums';
import { renditions } from '../../utils/enums/enums';

export enum CardLayeredVariations {
  'Square' = 'square',
  'Wide' = 'wide',
  'Tall' = 'Tall2:3',
}
export interface CardLayeredProps {
  cardLayeredVariations: CardLayeredVariations.Square | CardLayeredVariations.Wide | CardLayeredVariations.Tall;
  badgeText: string;
  openLinkInNewTab?: boolean;
  rel?: string;
  socialMediaIconName?: string;
  eyebrow?: string | undefined;
  ctaLink: string;
  titleText?: string;
  fileReferenceImage?: string;
  badgeIcon?: string;
  icon?: string;
  headerTag?: tags.h2 | tags.h3 | tags.h4 | tags.h5 | tags.h6;
  header?: string;
  fontSize: size.large | size.extraLarge | size.medium;
  openInNewTab: boolean;
  trackingProperties?: TrackingPropsInterface;
  dynamicMedia?: renditions;
  dynamicMediaTall?: renditions;
  dynamicMediaWide?: renditions;
  styleclass?: string;
  componentName?: string;
  componentId?: string;
  trunkEllipsesClass?: string;
  chevronIconSizeClass?: string;
  additionalWrapperClass?: string;
  logoIcon?: string;
  logoIconAltText?: string;
}
