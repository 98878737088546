import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '../../../styles';

export const StyledPopupFooterDiv = styled.div`
  ${rtl`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  background-color: ${baseVariables.color.neutral10};
  border-top: 1px solid ${baseVariables.color.neutral20};

  .clear {
    color: ${baseVariables.color.base10};
    font-size: ${toRem(13)};
    background: transparent;
    &:after {
      display: none !important;
    }
    &:focus {
      outline: none;
    }
  }
  .reset-btn {
    text-align: center;
    padding: 0;
    margin-top: ${toRem(11)};
    background: transparent;
    border: none;
  }
  .view-rates-button{
    width:${toRem(166)};
  }
  height: ${toRem(56)};
  padding: ${toRem(12)} ${toRem(16)};
  border-radius: 0;
  .view-rates-button{
    width:${toRem(128)};
    height:${toRem(32)};
    font-size:${toRem(13)};
}
  height: auto;
  padding: ${toRem(22)} ${toRem(24)};
  border-radius: 0 0 ${toRem(14)} ${toRem(14)};
  @media only ${baseVariables.mediaQuery.sm}{ 
  .view-rates-button{
    width:${toRem(166)};
    height: ${toRem(40)};
  }
  `}
`;

export const StyledApplyButtonDiv = styled.div`
  ${rtl`
  .apply-button-container {
    margin: ${toRem(28)} 0;
    display: flex;
    align-items: center;
    justify-items: center;
    color: ${baseVariables.color.alert10};
  }
  .apply-button {
    height: ${toRem(40)};
    width: ${toRem(128)};
    background-color: ${baseVariables.color.base10};
    border-radius: ${toRem(20)};
    justify-content: center;
    align-items: center;
    display: flex;
    color: ${baseVariables.color.alert10};
  }

  @media only ${baseVariables.mediaQuery.sm} {
    .apply-button {
      height: ${toRem(32)};
      width: ${toRem(82)};
      font-size: ${toRem(13)};
    }
  }
  `}
`;
