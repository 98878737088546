/**
 * Application constant's
 */

export const HOME_PAGE_HEADER_CONST_HEIGHT = 80;
export const HOME_PAGE_HEADER_HEIGHT = 112;
export const MEETINGS_AND_EVENTS = 'Meetings';
export const submitSearchURL = '/search/submitSearch.mi';
export const availabilitySearchUrl = '/reservation/availabilitySearch.mi';
export const meetingsSubmitURL = '/meetings/search-hotels.mi';
export const clickTrackTabbedSFTitle = 'Phoenix Advance Search ';
export const clickTrackMeetingEventsTitle = 'Meeting Search Form';
export const clickTrackMeetingEventsPurpose = 'Event purpose';
export const clickTrackMeetingEventsFindBtn = 'Meeting Search Form | Find Hotels | internal';
export const localeToRemoveFromURL = ['tr', 'pl', 'id', 'zh'];
export const tabIcons = {
  'Baseline Search Form': 'icon-city',
  Meetings: 'icon-meeting-services-app',
};
