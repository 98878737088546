import styled, { css } from 'styled-components';

import { baseVariables, toRem, Button, constants } from '@marriott/mi-ui-library';
import { MERCHANDISING_HEROBANNER_GRADIENTS } from './MerchandisingHeroBanner.types';
const { xl, lg, md, sm } = { ...baseVariables.mediaQuery };
const { EXTRATHIN, THIN, DEFAULT, WIDE } = MERCHANDISING_HEROBANNER_GRADIENTS;
export const StyledMerchandisingHeroBannerImage = styled.div`
  .hb__img {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    aspect-ratio: 2 / 3;
    @supports (object-fit: cover) {
      object-fit: cover;
      width: 100%;
    }
    @media screen and (min-width: 601px) {
      top: 0;
      transform: translate(-50%, 0);
      width: 100%;
    }
    @media ${sm} {
      object-fit: unset;
    }
    @media ${md} {
      aspect-ratio: 16/ 9;
    }
    &.hb-3x1-ratio {
      aspect-ratio: 1 / 1;
      @media ${md} {
        aspect-ratio: 3 / 1;
      }
    }
  }
`;

export const StyledMerchandisingHeroBannerContainer = styled.div<{ shouldClipPathFor2x1: boolean }>`
  aspect-ratio: 2 / 3;
  @media ${md} {
    aspect-ratio: 16/ 9;
  }
  &.hb-3x1-ratio {
    aspect-ratio: 1 / 1;
    @media ${md} {
      aspect-ratio: 3 / 1;
    }
  }
  .hb-item {
    position: relative;
    overflow: hidden;
    background-color: ${baseVariables.color['base10']};
    &.hero-2x1 {
      ${({ shouldClipPathFor2x1 }) =>
        shouldClipPathFor2x1 &&
        css`
          @media ${md} {
            clip-path: inset(5% 0 5% 0);
            margin-top: -2.8%;
            margin-bottom: calc(2rem - 5%);
          }
        `}

      .hb {
        @media ${md} {
          margin-bottom: ${toRem(16)};
        }
        @media ${lg} {
          margin-bottom: ${toRem(32)};
        }
        &__cnt-ctr {
          padding: 0 ${toRem(16)} ${toRem(61)} ${toRem(16)};
          color: ${baseVariables.color['base20']};
          @media ${md} {
            padding: 0 ${toRem(8)} 0 ${toRem(8)};
          }
        }
        &__cnt-sec {
          height: 150vw;
          @media ${md} {
            height: 56.25vw;
            align-items: center;
            padding-top: ${toRem(88)};
          }
          @media ${lg} {
            padding-top: ${toRem(137)};
          }
          &:before {
            display: none;
          }
        }
        &__heading {
          font-weight: ${baseVariables.font.fontWeightMedium};
          margin-bottom: ${toRem(8)};
          line-height: ${toRem(32)};
          @media ${md} {
            margin-bottom: 0;
            line-height: ${toRem(36)};
          }
          @media ${lg} {
            margin-bottom: ${toRem(25)};
            line-height: ${toRem(46)};
          }
          &.vertical__image__hero-2x1 {
            display: flex;
            font-weight: ${baseVariables.font.fontWeightMedium};
            line-height: ${toRem(32)};
            @media ${lg} {
              font-size: ${toRem(40)};
              line-height: ${toRem(44)};
              margin-bottom: ${toRem(8)};
            }
          }
        }
        &__heading__assetsVariation {
          @media ${lg} {
            margin-bottom: ${toRem(8)};
            font-size: ${toRem(40)};
            line-height: ${baseVariables.font.lineHeight1x};
          }
        }
        &__heading__cobrandMathHero {
          margin-bottom: ${toRem(10)};
          font-size: ${toRem(30)};
          line-height: ${toRem(32)};
          @media ${md} {
            margin-bottom: ${toRem(6)};
            line-height: ${toRem(31)};
          }
          @media ${lg} {
            margin-bottom: ${toRem(16)};
            font-size: ${toRem(40)};
            line-height: ${toRem(40)};
          }
        }
        &__subheading {
          font-weight: ${baseVariables.font.fontWeightRegular};
          margin-bottom: 0;
          font-size: ${toRem(16)};
          line-height: ${toRem(22)};
          @media ${md} {
            font-size: ${toRem(14)};
            line-height: ${toRem(20)};
          }
          @media ${lg} {
            font-size: ${toRem(16)};
            line-height: ${toRem(22)};
          }
          &.vertical__image {
            &__hero-2x1 {
              display: flex;
              font-weight: ${baseVariables.font.fontWeightRegular};
              line-height: ${toRem(20)};
              font-size: ${toRem(16)};
              @media ${md} {
                font-size: ${toRem(14)};
              }
              @media ${lg} {
                font-size: ${toRem(16)};
                line-height: ${toRem(24)};
              }
            }
          }
        }
        &__subheading__assetsVariation {
          font-size: ${baseVariables.font.fontM};
          line-height: ${baseVariables.font.lineHeightL};
          @media ${md} {
            font-size: ${baseVariables.font.fontS};
            line-height: ${baseVariables.font.lineHeightM};
          }
          @media ${lg} {
            font-size: ${baseVariables.font.fontM};
            line-height: ${baseVariables.font.lineHeightL};
          }
        }
        &__subheading__cobrandMathHero {
          font-size: ${baseVariables.font.fontM};
          line-height: ${baseVariables.font.lineHeightM};
          @media ${md} {
            font-size: ${baseVariables.font.fontS};
          }
          @media ${lg} {
            font-size: ${baseVariables.font.fontXxl};
            line-height: ${toRem(29)};
          }
        }
      }
    }
  }
  &.hb__masking {
    @media ${lg} {
      clip-path: inset(${toRem(112)} 0 0 0);
      margin-top: -${toRem(112)};
      .hb__cnt-sec {
        padding-top: ${toRem(249)};
      }
    }
    .hero-2x1 {
      .hb {
        &__cnt-sec {
          @media ${lg} {
            padding-top: calc(249px - 2.5%);
          }
        }
      }
    }
  }
  .flexLayout {
    display: flex;
  }

  .vertical__image__centeraligned {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    @media ${md} {
      display: block;
      text-align: left;
    }
  }
  .no-scrim {
    .hb {
      &__cnt-sec {
        &.gradient {
          &:after {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: 0;
            background: none;
          }
        }
      }
    }
  }
  .light-scrim {
    .hb {
      &__cnt-sec {
        .m-icon::before,
        .m-icon.brand-logo-Escape::after,
        .equal-icon,
        .icon-plus::before {
          color: ${baseVariables.color['base10']};
        }
        .hb__cobrand-total {
          border-top: ${toRem(2)} solid ${baseVariables.color['base10']};
          @media ${md} {
            border: none;
          }
        }
        &.gradient {
          .hb__cnt-ctr,
          .t-color-black,
          .hb__cobrand {
            color: ${baseVariables.color['base10']};
          }

          &:after {
            background: ${baseVariables.gradient['baseGradient01']};
            @media ${md} {
              background: ${baseVariables.gradient['baseGradient02']};
            }
            @media ${lg} {
              background: ${baseVariables.gradient['baseGradient03']};
            }
          }
        }
      }
    }
  }
  .t-color-black {
    color: ${baseVariables.color['base10']};
  }
  .hb {
    &__cnt-sec {
      position: relative;
      display: flex;
      align-items: flex-end;
      height: 150vw;
      min-height: 0;

      @media ${md} {
        align-items: center;
        height: 56.25vw;
        padding-top: ${toRem(88)};
      }

      @media ${lg} {
        padding-top: ${toRem(137)};
      }

      &:before {
        display: none;
      }
      &.gradient {
        &:after {
          display: block;
          width: 100%;
          height: 100%;
          background: ${baseVariables.gradient['baseGradient05']};
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
        }
        &.extraThin:after {
          @media ${md} {
            background: ${EXTRATHIN.md};
          }
          @media ${xl} {
            background: ${EXTRATHIN.xl};
          }
        }
        &.thin:after {
          @media ${md} {
            background: ${THIN.md};
          }
          @media ${xl} {
            background: ${THIN.xl};
          }
        }

        &.default:after {
          @media ${md} {
            background: ${DEFAULT.md};
          }
          @media ${xl} {
            background: ${DEFAULT.xl};
          }
        }

        &.wide::after {
          @media ${md} {
            background: ${WIDE.md};
          }
          @media ${xl} {
            background: ${WIDE.xl};
          }
        }
      }
      &.overlay-text-hero3x1 {
        height: 100vw;
        min-height: 0;
        @media ${md} {
          height: 33.33vw;
          padding-top: 0;
        }
      }
    }

    &__cnt-ctr {
      color: ${baseVariables.color['base20']};
      position: relative;
      z-index: 1;
      padding: 0 ${toRem(16)} ${toRem(48)} ${toRem(16)};
      @media ${md} {
        padding: 0 ${toRem(8)} 0 ${toRem(8)};
      }
      &.vertical__image__Container {
        display: flex;
        flex-direction: column;
        padding-left: ${toRem(12)};
        padding-right: ${toRem(12)};
        align-items: center;

        @media ${md} {
          flex-direction: row;
          align-items: stretch;
        }
      }
      &.hero-3x1 {
        padding-bottom: ${toRem(16)};
        @media ${md} {
          padding-bottom: 0.75rem;
        }
        .hb {
          &__heading {
            margin-bottom: ${toRem(-4)} !important;
            @media ${sm} {
              font-size: ${toRem(30)};
              line-height: ${toRem(36)};
              &.vertical__image__hero-3x1 {
                line-height: ${toRem(32)};
              }
            }
          }
          &__heading__assetsVariation {
            @media ${sm} {
              font-size: ${toRem(30)};
              line-height: ${toRem(36)};
            }
            @media ${lg} {
              margin-bottom: ${toRem(8)};
              font-size: ${toRem(40)};
              line-height: ${toRem(46)};
            }
          }
          &__subheading {
            @media ${sm} {
              font-size: ${baseVariables.font.fontS};
              line-height: ${baseVariables.font.lineHeightM};
              &.vertical__image__hero-3x1 {
                font-size: ${baseVariables.font.fontM};
              }
            }
          }
          &__subheading__assetsVariation {
            @media ${sm} {
              font-size: ${baseVariables.font.fontS};
              line-height: ${baseVariables.font.lineHeightM};
            }
          }
        }
        .primaryButton-3x1,
        .secondaryButton-3x1 {
          margin-top: ${toRem(18)};
        }
      }
      .break-word {
        word-wrap: break-word;
      }
      .brand-logo-WH,
      .brand-logo-wh {
        &::before {
          font-size: ${toRem(18.56)};
        }
      }
    }

    &__heading {
      margin-bottom: ${toRem(8)};
      line-height: ${toRem(32)};
      @media ${md} {
        margin-bottom: ${toRem(0)};
        line-height: ${toRem(36)};
      }
      @media ${lg} {
        margin-bottom: ${toRem(25)};
        line-height: ${toRem(46)};
      }
      &.vertical__image__hero-3x1 {
        @media ${md} {
          line-height: ${toRem(36)};
        }
        @media ${lg} {
          font-size: ${toRem(40)};
          line-height: ${toRem(44)};
        }
      }
      &.vertical__image__hero-16x9 {
        @media ${lg} {
          font-size: ${toRem(40)};
          line-height: ${toRem(44)};
          margin-bottom: ${toRem(8)};
        }
      }
    }

    &__heading__assetsVariation {
      @media ${lg} {
        margin-bottom: ${toRem(8)};
        font-size: ${toRem(40)};
        line-height: ${toRem(44)};
      }
    }

    &__heading__cobrandMathHero {
      margin-bottom: ${toRem(10)};
      font-size: ${toRem(30)};
      line-height: ${toRem(32)};
      @media ${md} {
        margin-bottom: ${toRem(6)};
        line-height: ${toRem(31)};
      }
      @media ${lg} {
        margin-bottom: ${toRem(16)};
        font-size: ${toRem(40)};
        line-height: ${toRem(40)};
      }
    }

    &__heading-cobrand {
      @media ${xl} {
        font-size: ${toRem(60)};
        line-height: ${toRem(60)};
      }
    }

    &__subheading {
      font-weight: ${baseVariables.font.fontWeightRegular};
      margin-bottom: 0;
      line-height: ${baseVariables.font.lineHeightL};
      @media ${md} {
        font-size: ${toRem(14)};
        line-height: ${toRem(20)};
      }
      @media ${lg} {
        font-size: ${toRem(16)};
        line-height: ${toRem(22)};
      }
      &.vertical__image {
        &__hero-16x9 {
          line-height: ${baseVariables.font.lineHeightM};
          @media ${lg} {
            line-height: ${baseVariables.font.lineHeightXl};
          }
        }
        &__hero-3x1 {
          line-height: ${baseVariables.font.lineHeightM};
          @media ${md} {
            margin-top: 0 !important;
          }
          @media ${lg} {
            margin-top: 0.25rem !important;
            font-size: ${baseVariables.font.fontM};
            line-height: ${baseVariables.font.lineHeightXl};
          }
        }
      }
    }
    &__subheading__cobrandMathHero {
      font-size: ${baseVariables.font.fontM};
      line-height: ${baseVariables.font.lineHeightM};
      @media ${md} {
        font-size: ${baseVariables.font.fontS};
      }
      @media ${lg} {
        font-size: ${baseVariables.font.fontXxl};
        line-height: ${toRem(29)};
      }
    }
  }
  &.hb__video-enabled-desktop {
    .hb-item {
      ${StyledMerchandisingHeroBannerImage},
      .hb__cnt-sec {
        display: none;
      }
    }
  }
  &.hb__video-enabled-mobile-only {
    @media screen and (max-width: 767.5px) {
      .hb-item {
        ${StyledMerchandisingHeroBannerImage},
        .hb__cnt-sec {
          display: none;
        }
      }
    }
  }
  &.hb__video-enabled-desktop-only {
    @media ${md} {
      .hb-item {
        ${StyledMerchandisingHeroBannerImage},
        .hb__cnt-sec {
          display: none;
        }
      }
    }
  }
`;

export const BaseCtaButton = styled(Button)<{ forwardedClassName: string }>`
  ${({ forwardedClassName }) => {
    return forwardedClassName.includes(constants.COBRAND_MATH_HERO_CTA_CLASS)
      ? css`
          @media ${md} {
            margin-top: ${toRem(4)};
          }
          @media ${lg} {
            margin-top: ${toRem(17)};
          }
        `
      : css`
          @media ${md} {
            margin-top: ${toRem(16)};
          }
          @media ${lg} {
            margin-top: ${toRem(30)};
          }
        `;
  }}
  margin-top: ${toRem(12)};
  &:nth-child(2) {
    margin-left: ${toRem(16)};
  }
  .verticalImage & {
    margin-top: ${toRem(18)};
    @media ${md} {
      margin-top: ${toRem(16)};
    }
    @media ${lg} {
      margin-top: ${toRem(19)};
    }
  }
`;

export const PrimaryButton = styled(BaseCtaButton).attrs(({ className }) => ({
  forwardedClassName: className,
}))<{ className: string }>`
  && {
    padding-left: ${toRem(16)};
    padding-right: ${toRem(16)};
  }
`;

export const SecondaryButton = styled(BaseCtaButton).attrs(({ className }) => ({
  forwardedClassName: className,
}))<{ className: string }>`
  && {
    padding-left: ${toRem(16)};
    padding-right: ${toRem(16)};
  }
`;

export const LocationButton = styled(Button)`
  top: unset;
  bottom: ${toRem(8)};
  right: ${toRem(20)};
  position: absolute;
  z-index: 1;
  border-radius: ${toRem(3)};
  background: ${baseVariables.color['base10']};
  opacity: 0.54;
  padding: ${toRem(3)} ${toRem(8)};
  text-decoration: none;
  @media ${md} {
    display: inline-block;
    bottom: ${toRem(16)};
    right: calc(50vw - ${toRem(360)});
  }
  @media ${lg} {
    right: calc(50vw - ${toRem(480)});
  }
  @media ${xl} {
    bottom: ${toRem(32)};
    right: calc(50vw - ${toRem(570)});
  }
  &.t-label-xs {
    color: ${baseVariables.color['base20']};
  }
  .icon-location {
    padding-right: ${toRem(6)};
    &::before {
      color: ${baseVariables.color['base20']};
    }
  }
  &:hover {
    color: ${baseVariables.color['base20']};
    text-decoration: none;
    font-weight: inherit;
  }
  .hero-2x1 & {
    @media ${md} {
      margin-bottom: ${toRem(19)};
    }
    @media ${xl} {
      margin-bottom: ${toRem(35)};
    }
  }
  .hero-3x1 & {
    top: ${toRem(16)};
    right: ${toRem(30)};
    bottom: auto;
    @media ${xl} {
      top: ${toRem(20)};
      right: ${toRem(158)};
    }
  }
`;
export const CoBrandWrapper = styled.div`
  .hb {
    &__cobrand {
      width: auto;
      margin-top: ${toRem(10)};
      color: ${baseVariables.color['base20']};
      @media ${md} {
        display: flex;
        margin-top: ${toRem(6)};
      }
      @media ${lg} {
        margin-top: ${toRem(20)};
      }
      .equal-icon {
        @media ${md} {
          font-weight: ${baseVariables.font.fontWeightMedium};
          margin: 0 ${toRem(3)} ${toRem(1)};
          font-size: ${toRem(22)};
          line-height: ${toRem(40)};
          align-items: flex-end;
          justify-content: center;
          width: ${toRem(32)};
          color: ${baseVariables.color['base20']};
        }

        @media ${lg} {
          font-size: ${toRem(30)};
          line-height: ${toRem(40)};
          margin-bottom: ${toRem(3)};
        }
      }

      &.nightsVariation .hb__cobrand-balance,
      &.nightsVariation .hb__cobrand-bonus {
        max-width: 45%;
        @media ${sm} {
          max-width: none;
        }
      }

      & > div,
      span {
        display: inline-block;
        font-weight: ${baseVariables.font.fontWeightMedium};
        &.icon-plus,
        &.icon-minus {
          margin: 0 ${toRem(11)} 0 ${toRem(3)};
          font-weight: ${baseVariables.font.fontWeightRegular};
          &:before {
            font-size: ${toRem(22)};
            line-height: ${toRem(40)};
          }

          @media ${md} {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-bottom: ${toRem(1)};

            &:before {
              font-size: ${toRem(22)};
              line-height: ${toRem(40)};
            }
          }

          @media ${lg} {
            margin-bottom: ${toRem(3)};

            &:before {
              font-size: ${toRem(30)};
              line-height: ${toRem(40)};
            }
          }
        }

        div {
          &:last-child {
            font-weight: ${baseVariables.font.fontWeightMedium};
            font-size: ${toRem(22)};
            line-height: ${toRem(40)};
            @media ${lg} {
              margin-top: ${toRem(7)};
              font-size: ${toRem(30)};
            }
          }
        }

        &.hb__cobrand-total {
          display: flex;
          align-items: center;
          border-top: ${toRem(2)} solid ${baseVariables.color['neutral30']};
          margin-top: ${toRem(6)};
          padding-top: ${toRem(6)};
          gap: ${toRem(3)};
          @media ${md} {
            display: inline-block;
            border: none;
            margin-top: 0;
            padding-top: 0;
          }
          div {
            font-weight: ${baseVariables.font.fontWeightMedium};
            &:first-child {
              order: 2;
            }
            &:last-child {
              float: left;
              margin-top: ${toRem(0)};
              font-size: ${toRem(30)};
              line-height: ${toRem(40)};
              font-weight: ${baseVariables.font.fontWeightMedium};
              @media ${lg} {
                margin-top: ${toRem(2)};
                font-size: ${toRem(40)};
                line-height: ${toRem(40)};
              }
              order: 1;
            }
          }
        }
        .hb__cobrand-total {
          &__points-wrapper {
            margin-right: ${toRem(4)};
            @media ${sm} {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;
