/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import clsx from 'clsx';

import { IconTextBlock } from '@marriott/mi-ui-library';

import { StyledAutoSuggestionsDiv } from './AutoSuggestions.styles';
import { searchFormClickTrackingLoc, autosuggestionsClickTrackingLoc } from '../../../constants/lib/constants';

//TODO : to remove types with any
interface AutoSuggestionsProp {
  optionsList: any;
  getItemProps: any;
  highlightedIndex: number | null;
  selectedItem: any;
}
export const AutoSuggestions: FC<AutoSuggestionsProp> = ({
  optionsList,
  getItemProps,
  highlightedIndex,
  selectedItem,
}) => {
  return (
    <StyledAutoSuggestionsDiv data-testid="Suggested-Place">
      {optionsList?.map((item: any, index: number) => (
        <div
          className={clsx(
            highlightedIndex === index && 'mi-icon-text-block',
            selectedItem === item && 'mi-icon-text-block',
            'custom_click_track'
          )}
          role="option"
          aria-selected={item}
          custom_click_track_value={`${searchFormClickTrackingLoc} | ${autosuggestionsClickTrackingLoc} ${item?.node?.primaryDescription} | internal`}
          {...getItemProps({
            key: item?.node?.description,
            index,
            item,
            className: highlightedIndex === index ? 'highlighted-item custom_click_track' : 'custom_click_track',
          })}
        >
          <IconTextBlock
            icon="location"
            primaryTextContent={item?.node?.primaryDescription}
            secondaryTextContent={item?.node?.secondaryDescription ? item?.node?.secondaryDescription : ''}
            primaryCustomClass={'text-primary-custom'}
            secondaryCustomClass={'text-secondary'}
          />
        </div>
      ))}
    </StyledAutoSuggestionsDiv>
  );
};
