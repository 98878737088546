import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledRecentViewAndSearch = styled.div`
  ${rtl`
  position: relative;
  
    margin-top: ${toRem(8)};
    height: 100%;
    background-color: ${baseVariables.color.base20};
    overflow: hidden;
  
  
  .search-history-btm-cta-wrapper {
    display: flex;
    padding: 0 ${toRem(8)};
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    button {
      border: none;
      &.new-search-btn {
        display: flex;
        flex-direction: row-reverse;
        @media ${baseVariables.mediaQuery.sm} {
          padding-right: ${toRem(16)};
        }
        &:before {
          transform: rotateY(180deg);
          margin-left: ${toRem(5)};
        }
      }
    }   
  }

  

`}
`;
