export enum EyebrowEnum {
  Overlay = 'overlay',
  Default = 'normal',
}

export interface EyebrowProps {
  text: string | undefined;
  variation?: EyebrowEnum.Default | EyebrowEnum.Overlay;
  customClass?: string;
}
