import { FC } from 'react';
import { TextProps } from './Text.types';
import clsx from 'clsx';
import { size, tags } from '../../utils/enums/enums';

export const Text: FC<TextProps> = ({
  copyText,
  fontSize = size.medium,
  customClass,
  element = tags.paragraph,
  ...props
}) => {
  const Tag = element;
  return (
    <Tag
      data-component-name="a-ui-library-Text"
      data-testid="ui-library-Text"
      className={clsx(`t-font-${fontSize}`, customClass, 'm-0')}
      {...props}
    >
      {copyText}
    </Tag>
  );
};
