import { Types } from '@marriott/mi-ui-library';

export interface BrandRibbonProps {
  componentId?: string;
  enableScrollingBehavior?: boolean;
  variations?: BrandribbonVariation;
  brandLogoTag?: string;
  logoLinkText?: string;
  ctaLink?: string;
  categorylists: linkLists[];
  trackingProperties?: Types.TrackingPropsInterface;
  styleclass?: string;
  cqPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model?: any;
}

export type linkLists = {
  linklists: List[];
  categorylabel: string;
};

export enum BrandribbonVariation {
  PortfolioCollection = 'portfolioribbon',
  PortfolioBrands = 'portfoliobrands',
}

export type List = { linkurl: string; openinanewtab: boolean; brandtag: string; linkText: string };
