/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
import { createAppStore } from './zustand';
import { NEXT_DATA } from 'next/dist/shared/lib/utils';

interface globalState {
  sessionData: Record<string, any>;
}

declare global {
  interface Window {
    __NEXT_DATA__: NEXT_DATA;
    dataLayer?: Record<string, unknown>;
  }
}

const initialState: globalState = {
  sessionData: {},
};

export const globalStore: StateCreator<globalState> = () => {
  if (typeof window !== 'undefined') {
    // hydration
    initialState.sessionData = window?.__NEXT_DATA__?.props?.['pageProps']?.sessionData;
  }
  return {
    ...initialState,
  };
};

export const useGlobalStore = createAppStore(globalStore, {
  usePersistentStore: false,
});
