import { AssetVariation, CopyBlockWidthEnum, HeaderTextStyle, HeroSize } from '../../utils/enums';

interface ImageSource {
  altText: string;
  assetPath: string;
  dynamic: boolean;
  damPath: string;
  renditions: Array<{
    renditionPath: string;
    mediaValue: string;
    dynamic: boolean;
    damPath: string;
    mediaQuery: string;
    width: number;
    height: number;
  }>;
}

export interface HeroBannerProps {
  location?: string;
  description?: string;
  assetsVariation?: AssetVariation;
  fileReferenceImage?: string;
  imgAltText?: string;
  font_icon?: string;
  fontIconAltText?: string;
  fileReferenceImageVertical?: string;
  verticalImageAltText?: string;
  fileReferenceBackgroundImagedesktop?: string;
  backgroundimagealttext?: string;
  fileReferenceBackgroundImagemobile?: string;
  eyebrowtext?: string;
  headertextstyle: HeaderTextStyle;
  headertext?: string;
  subheadingtext?: string;
  primaryctalabel?: string;
  primaryctalink?: string;
  primaryopeninanewtab?: boolean;
  secondaryctalabel?: string;
  secondaryctalink?: string;
  secondaryopeninanewtab?: boolean;
  locationctalabel?: string;
  locationctalink?: string;
  locationopeninanewtab?: boolean;
  hideLocationCtaMobile?: boolean;
  horizontalgradient?: boolean;
  verticalgradient?: boolean;
  aspectRatioMobileVideo?: string;
  accountId?: string;
  playerId?: string;
  playerDomain?: string;
  cobrandmathhero?: boolean;
  balance?: string;
  bonus?: string;
  newtotal?: string;
  staticpoint?: string;
  videoaccountId?: string;
  enableCardImage?: boolean;
  enableMaskingOnDesktop?: boolean;
  enableVerticalCardImage?: boolean;
  enableAutoPlay?: boolean;
  enableMute?: boolean;
  enableVideoLoop?: boolean;
  heroSize?: HeroSize;
  enableContentStyle?: boolean;
  imageLoading?: 'eager' | 'lazy';
  videoEnabledDesktop?: boolean;
  videoEnabledMobile?: boolean;
  appliedCssClassNames?: string;
  enableTarget?: boolean;
  trackingProperties?: TrackingProps;
  opensInNewTab?: string;
  videoIdDesktop?: string;
  videoIdMobile?: string;
  fileReferenceVideoCoverImage?: string;
  backgroundMedia?: ImageSource;
  componentId?: string;
  copyBlockWidth?: CopyBlockWidthEnum;
  mboxParameter?: string;
}

export interface TrackingProps {
  atCCeVar48?: string;
  trackingDescription?: string;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  trackingTag2?: string;
  trackingTag1?: string;
  merchandisingCategory?: string;
  trackingContentPosition?: string;
  impressionCount?: boolean;
  trackingOfferType?: string;
}
//TODO:Move these to global styles
export const HEROBANNER_GRADIENTS = {
  EXTRATHIN: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 34.9%, rgba(0,0,0,0.54) 67.94%, rgba(0,0,0,0.62) 100%)`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37.37%, rgba(0,0,0,0.54) 68.33%, rgba(0,0,0,0.62) 100%)`,
  },
  THIN: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 26.52%, rgba(0,0,0,0.54) 59.73%, rgba(0,0,0,0.62) 100%)`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30.78%, rgba(0,0,0,0.54) 63.78%, rgba(0,0,0,0.62) 100%);`,
  },
  DEFAULT: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 18.39%, rgba(0,0,0,0.54) 51.39%, rgba(0,0,0,0.62) 100%);`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 24.19%, rgba(0,0,0,0.54) 55.22%, rgba(0,0,0,0.62) 100%);`,
  },
  WIDE: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 9.92%, rgba(0,0,0,0.54) 43.12%, rgba(0,0,0,0.62) 100%);`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 17.55%, rgba(0,0,0,0.54) 50.59%, rgba(0,0,0,0.62) 100%);`,
  },
};
