/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { usePageModelStore } from '@marriott/mi-store-utils';
import { getOffers } from './useDynamicModelUtils';

export const useDynamicModel = (mboxParam?: string) => {
  if (!mboxParam) {
    //If mbox parameter is not present isTargetContentLoading is false and component behavior is not effected by target.
    return { isTargetContentLoading: false, targetData: {} };
  }
  const [isTargetContentLoading, setisTargetContentLoading] = useState(mboxParam ? true : false);
  const [compKey, setCompkey] = useState<string | undefined>('');
  const { pageModel, updateComponent } = usePageModelStore((state: unknown) => state) as any;

  useEffect(() => {
    const compKey = Object.keys(pageModel?.cqItems).find(
      item => pageModel?.cqItems?.[item]?.mboxParameter === mboxParam
    );
    setCompkey(compKey);
  }, []);

  useEffect(() => {
    const fetchAndUpdateTargetData = async () => {
      if (mboxParam) {
        try {
          const fetchedTargetData = await getOffers(mboxParam);
          fetchedTargetData && updateComponent(compKey, fetchedTargetData);
          setisTargetContentLoading(false);
        } catch {
          setisTargetContentLoading(false);
          return;
        }
      } else {
        setisTargetContentLoading(false);
      }
    };
    fetchAndUpdateTargetData();
  }, [mboxParam, updateComponent, compKey]);

  return {
    targetData: !isTargetContentLoading ? pageModel.cqItems[compKey as string] || {} : {},
    isTargetContentLoading: isTargetContentLoading,
  };
};
