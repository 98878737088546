import { canUseDOM } from '@marriott/mi-ui-library';

export const getFromSession = (key: string) => {
  return canUseDOM && sessionStorage.getItem(key);
};

/**
 *
 * @param url
 * This is used for prefix the url. This will check for subdirectory prefix
 * if present then it will be prefixed otherwise the url is returned without prefix.
 */
export function addSubDirectoryPrefix(url: string) {
  const SUBDIRECTORY_PREFIX = getFromSession('SUBDIRECTORYCONTEXTPATH') || '';
  if (
    SUBDIRECTORY_PREFIX &&
    url?.startsWith('/') &&
    url?.substring(1, findNthOccurence(url, 1, '/'))?.toLowerCase() !== SUBDIRECTORY_PREFIX?.toLowerCase()
  ) {
    return SUBDIRECTORY_PREFIX ? '/' + SUBDIRECTORY_PREFIX + url : url;
  } else return url;
}

/**
 *
 * @param url as str
 * This is used find the nth occurence of a char in string
 */
const findNthOccurence = (str: string, nth: number, char: string) => {
  let index = 0;
  for (let i = 0; i < nth; i += 1) {
    if (index !== -1) index = str.indexOf(char, index + 1);
  }
  return index;
};
