import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const slideIn = keyframes`
  from {
    right: -1%; 
  }
  to {
    right: 0;
  }
`;

const slideOut = keyframes`
  from {
    right: 0;
  }
  to {
    right: -100%; 
  }
`;

export const StyledAccountPanel = styled.div<{ isOpen: boolean }>`
  position: fixed;
  height: 100vh;
  top: 0;
  right: -100%;
  background-color: ${baseVariables.color['base20']};
  z-index: 999;
  overflow: auto;

  ${props =>
    props.isOpen &&
    css`
      animation: ${slideIn} 0.3s forwards;
    `}

  ${props =>
    !props.isOpen &&
    css`
      animation: ${slideOut} 0.3s forwards;
    `}

  width: 100vw;
  @media ${baseVariables.mediaQuery.sm} {
    width: ${toRem(364)};
  }
  @media ${baseVariables.mediaQuery.lg} {
    width: ${toRem(440)};
  }

  .headerWrapper {
    height: ${toRem(53)};
    border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral30']};
    @media ${baseVariables.mediaQuery.lg} {
      height: ${toRem(88)};
    }
  }
  .panelLogo {
    height: ${toRem(53)};
    position: absolute;
    left: 0;
    @media ${baseVariables.mediaQuery.lg} {
      height: ${toRem(88)};
      position: unset;
    }
    a {
      color: ${baseVariables.color['base10']};
      font-size: ${toRem(43.2)};
      @media ${baseVariables.mediaQuery.sm} {
        padding-top: ${toRem(16)};
        padding-left: ${toRem(16)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(60.8)};
        padding-left: ${toRem(32)};
        padding-top: ${toRem(32)};
      }
    }
  }
  .closeButton {
    position: absolute;
    right: 0;
    margin-right: ${toRem(12)};
    @media ${baseVariables.mediaQuery.lg} {
      margin-right: ${toRem(36)};
    }
  }
  .authenticated {
    .closeButton {
      margin-top: ${toRem(12)};
      @media ${baseVariables.mediaQuery.lg} {
        margin-top: ${toRem(28)};
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  body.modal-open {
    overflow: hidden !important;
  }
`;
