// Imports for external libraries go here.
import React, { FC } from 'react';
import { useCheckBreakpoint } from '@marriott/mi-ui-library';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.

// These are skeleton loaders that are being imported into import-components.js for use with next/dynamic.
// They're not suitable for use outside that file as they are built with the assumption that they no very little about the underlying component
// Loaders closer to the actual render should be used when have a better idea of the final output.
import {
  CarouselElementContainer,
  CarouselContainer,
  CarouselElement,
  CarouselTitle,
  BannerLayeredBlock,
  CardMiniBlock,
  FlexContainer,
  CarouselCta,
  CarouselPaginationCircle,
  CarouselPaginationContainer,
  FullWidthContainer,
} from './SkeletonLoader.styles';

// Use named rather than default exports.
export const CarouselContainerSkeletonLoader: FC = () => {
  const isViewportL = useCheckBreakpoint('viewportL');

  return (
    <CarouselContainer
      data-component-name="m-homepage-CarouselContainerSkeletonLoader"
      data-testid="homepage-CarouselContainerSkeletonLoader"
    >
      <FlexContainer>
        <CarouselTitle />
        <CarouselCta />
      </FlexContainer>
      <CarouselElementContainer>
        <CarouselElement />
        {isViewportL && (
          <>
            <CarouselElement />
            <CarouselElement />
          </>
        )}
      </CarouselElementContainer>
      <FullWidthContainer>
        <CarouselPaginationContainer>
          {[...Array(5).keys()].map((_, i) => (
            <CarouselPaginationCircle key={`CarouselPaginationCircle${i}`} size={i === 0 ? 10 : null} />
          ))}
        </CarouselPaginationContainer>
      </FullWidthContainer>
    </CarouselContainer>
  );
};

export const BannerLayeredSkeletonLoader: FC = () => <BannerLayeredBlock data-testid="banner-layered-block" />;

export const CardHorizontalMiniSkeletonLoader: FC = () => <CardMiniBlock data-testid="card-mini-block" />;
