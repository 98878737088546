import React, { FC, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Button } from '../../atoms/Button';
import { Heading } from '../../atoms/Heading';
import { Image } from '../../atoms/Image';
import { Icon } from '../../atoms/Icon';
import { headingType, tags, size } from '../../utils/enums/enums';

import { BannerLayeredProps } from './BannerLayered.types';
import { StyledBannerLayered } from './BannerLayered.styles';

export const BannerLayered: FC<BannerLayeredProps> = ({
  header,
  headerTag = tags.h2,
  description,
  ctaType,
  primaryCtaText,
  primaryCtaLink,
  primaryCtaOpenInNewTab = true,
  primaryCtaAriaLabel,
  tertiaryLinkText,
  tertiaryLinkUrl,
  tertiaryLinkOpenInNewTab = false,
  tertiaryCtaAriaLabel,
  secondaryCtaText,
  secondaryCtaLink,
  secondaryCtaOpenInNewTab = true,
  secondaryCtaAriaLabel,
  iconText,
  iconPath,
  linkUrl,
  styleclass,
  trackingProperties,
  dynamicMedia,
  dynamicMediaMobile,
  imageLoading,
  position,
  ...props
}) => {
  const renditions = dynamicMedia?.renditions;
  const mobileRenditions = dynamicMediaMobile?.renditions;
  const hasPrimaryButton = primaryCtaText && primaryCtaLink && primaryCtaText.length > 0 && primaryCtaLink.length > 0;
  const hasSecondaryButton =
    secondaryCtaText && secondaryCtaLink && secondaryCtaText.length > 0 && secondaryCtaLink.length > 0;
  const hasTertiaryLink =
    tertiaryLinkText && tertiaryLinkUrl && tertiaryLinkText.length > 0 && tertiaryLinkUrl.length > 0;
  const isCaptionLink = linkUrl !== undefined && linkUrl.length > 0 ? true : false;
  const isMobileImage = Object.prototype.hasOwnProperty.call(dynamicMediaMobile || {}, 'assetPath');

  const CardLinkPrimaryBtnRef = useRef(null);
  const CardLinkTertiaryBtnRef = useRef(null);
  const customAttributes = trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': props?.componentId }
    : {};
  const [mQuery, setMQuery] = useState(true);

  useEffect(() => {
    handleScreenResize();
    window.addEventListener('resize', handleScreenResize);
  }, []);

  const handleScreenResize = () => {
    const mobileSize = window.matchMedia('(max-width: 767px)');
    const tabletSize = window.matchMedia('(min-width:768px)');
    if (mobileSize.matches) {
      setMQuery(true);
    } else if (tabletSize.matches) {
      setMQuery(false);
    }
  };

  return (
    <StyledBannerLayered
      data-component-name="m-ui-library-BannerLayered"
      data-testid="ui-library-BannerLayered"
      scrimGradient={styleclass}
      className={`row bl ${styleclass ? styleclass : ''} m-container-fullbleed`}
    >
      <div className="bl-item" {...customAttributes}>
        <div className={clsx('d-none d-sm-block', { 'd-block': !isMobileImage })}>
          <Image
            customClass="feature-img"
            altText={dynamicMedia?.altText}
            renditions={renditions}
            dynamic={dynamicMedia?.dynamic}
            defaultImageURL={dynamicMedia?.assetPath}
            loading={imageLoading}
          />
        </div>
        {isMobileImage && (
          <div className={clsx('d-block d-sm-none')}>
            <Image
              customClass="feature-img"
              altText={dynamicMediaMobile?.altText}
              renditions={mobileRenditions}
              dynamic={dynamicMediaMobile?.dynamic}
              defaultImageURL={dynamicMediaMobile?.assetPath}
              loading={imageLoading}
            />
          </div>
        )}
        <div className={`bl__cnt-sec gradient`}>
          <div className="container-sm bl__cnt-ctr">
            <div className="row">
              <div className="bl_content col-12 col-sm-6 col-lg-6">
                {header && (
                  <Heading
                    customClass={clsx('bl_head', description ? '' : 'mb-1 mb-sm-3 mb-lg-1')}
                    titleText={header}
                    variation={headingType.title}
                    fontSize={size.medium}
                    element={headerTag}
                  />
                )}
                {description && <p className={clsx('bl_desc t-font-s')}> {description} </p>}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-12">
                <div className="bl_cta-wrapper bl_cta-center">
                  {hasTertiaryLink && (
                    <Button
                      isLink={true}
                      href={tertiaryLinkUrl}
                      className={`bl_cta m-link-tertiary-button ${
                        tertiaryLinkOpenInNewTab === true ? 'm-link-tertiary-button-external' : ''
                      } ${ctaType === 'tertiarylink' ? 'd-block' : 'd-none'}`}
                      target={tertiaryLinkOpenInNewTab === true ? '_blank' : ''}
                      trackingProperties={trackingProperties}
                      ref={CardLinkTertiaryBtnRef}
                      ariaLabel={tertiaryCtaAriaLabel ?? tertiaryLinkText}
                    >
                      {tertiaryLinkText}
                    </Button>
                  )}
                  {hasPrimaryButton && (
                    <Button
                      isLink
                      href={primaryCtaLink}
                      className={`mb-2 bl_cta m-button-primary ${mQuery === true ? 'm-button-s' : 'm-button-m'} ${
                        primaryCtaOpenInNewTab === true ? 'm-button-external' : ''
                      } ${ctaType === 'primarybutton' ? 'd-block' : 'd-none'}`}
                      target={primaryCtaOpenInNewTab === true ? '_blank' : ''}
                      trackingProperties={trackingProperties}
                      ref={CardLinkPrimaryBtnRef}
                      custom_click_track_value={props?.custom_click_track_value_primary}
                      ariaLabel={primaryCtaAriaLabel ?? primaryCtaText}
                    >
                      {primaryCtaText}
                    </Button>
                  )}
                  {hasSecondaryButton && (
                    <Button
                      isLink
                      href={secondaryCtaLink}
                      className={`bl_cta bl_sec-cta m-button-secondary ${
                        mQuery === true ? 'm-button-s' : 'm-button-m'
                      } ${secondaryCtaOpenInNewTab === true ? 'm-button-external' : ''} ${
                        ctaType === 'primarybutton' ? 'd-block' : 'd-none'
                      }`}
                      target={secondaryCtaOpenInNewTab === true ? '_blank' : ''}
                      custom_click_track_value={`${header}|${secondaryCtaText}|${
                        secondaryCtaOpenInNewTab === true ? 'external' : 'internal'
                      }`}
                      ariaLabel={secondaryCtaAriaLabel ?? secondaryCtaText}
                    >
                      {secondaryCtaText}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {iconText && (
          <div className={clsx('bl_iconText-wrapper', position)}>
            <div className={`t-label-inverse-xs`}>
              <Icon iconClass={`${isCaptionLink === true ? 'pe-auto' : 'pe-none'} ${iconPath}`} ariaLabel={iconText} />
              <Button
                isLink={true}
                href={linkUrl}
                className={`banner-layerd-anchor ${isCaptionLink === true ? 'pe-auto' : 'pe-none'}`}
                target="_blank"
                custom_click_track_value={`${header}|${iconText}|external`}
                ariaLabel={iconText}
              >
                {iconText}
              </Button>
            </div>
          </div>
        )}
      </div>
    </StyledBannerLayered>
  );
};
