import { create } from 'zustand';

export type PropertyDetails = {
  hotelReviewUrl: string;
  hotelDetailUrl: string;
};

type PropertyBarData = {
  showPropertyDetails: boolean;
  propertyDetails?: PropertyDetails;
};

interface PropertyBarState {
  showPropertyDetails: boolean;
  propertyDetails?: PropertyDetails;
}

interface PropertyBarActions {
  setPropertyDetails: (data: PropertyBarData) => void;
}

/**
 * Store for communicating display of property details to consuming components.
 * @see './README.md' for more information.
 */
export const usePropertyBar = create<PropertyBarState & PropertyBarActions>(set => ({
  showPropertyDetails: false,
  setPropertyDetails: ({ showPropertyDetails, propertyDetails }: PropertyBarData) => {
    set({ showPropertyDetails, propertyDetails });
  },
}));
