import { Container } from '@adobe/aem-react-editable-components';

import { LanguageSelector } from './LanguageSelector';
import { AemContainerConfig } from '../Navigation.types';
import { LanguageSelectorProps } from './LanguageSelector.types';
import clsx from 'clsx';

export const LanguageSelectorWrapper = (props: LanguageSelectorProps) => {
  const LanguageSelectorConfig: AemContainerConfig = {
    emptyLabel: 'Marriott Language Selector',
    isEmpty: () => false,
    resourceType: 'mi-aem-homepage-spa/components/content/language-selector',
    isInEditor: !!props?.isAuthorMode,
    cqPath: props?.cqPath || '',
  };

  return (
    <Container
      {...LanguageSelectorConfig}
      className={clsx({ 'py-0': props?.isAuthorMode })}
      childPages={<LanguageSelector {...props} />}
    />
  );
};
