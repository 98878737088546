/* eslint-disable @typescript-eslint/no-explicit-any */
export const getOffers = async (mboxParam: string) => {
  //Trigger target call based on mbox parameter
  if (!(window as any).adobe || !(window as any).adobe.target) {
    return;
  }
  const offerResponse = await (window as any).adobe.target.getOffers({
    request: {
      execute: {
        mboxes: [
          {
            index: 0,
            name: mboxParam,
          },
        ],
      },
    },
  });
  return offerResponse?.execute?.mboxes?.[0]?.options?.[0]?.content;
};
