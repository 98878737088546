/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, MouseEvent } from 'react';
import clsx from 'clsx';
import { PopupFooterProps } from '../Modal.types';
import { StyledApplyButtonDiv, StyledPopupFooterDiv } from './index.styles';

declare module 'react' {
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}

const PopupFooter: FC<PopupFooterProps> = ({
  popupHeaderOnCLoseFunc,
  applyBtnClickHandler,
  clearClickHandler,
  setPopUpOpenState,
  clearButtonLabel,
  applyLabel,
  setFeatureSelected,
  clickTrackingLoc,
  viewRatesURL,
  className,
  fromToolTip,
  viewRateLabel,
  applyButtonDisabled,
  applyBtnClickTrackValue,
  errorMessageAriaLabel,
  clearBtnClickTrackValue,
  clearBtnClassname,
}) => {
  const onHandleApplyClick = (ev: MouseEvent): void => {
    if (setFeatureSelected) {
      setFeatureSelected(true);
    }
    if (applyBtnClickHandler) {
      applyBtnClickHandler(ev);
    }
    if (popupHeaderOnCLoseFunc) {
      popupHeaderOnCLoseFunc(false);
    }
    if (setPopUpOpenState) {
      setPopUpOpenState(false);
    }
  };
  const clearButtonHandler = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      if (clearClickHandler) {
        clearClickHandler(event);
      }
    }
  };
  return (
    <StyledPopupFooterDiv className={className}>
      {!fromToolTip && (
        <>
          {clearButtonLabel && (
            <button
              onClick={(e): void => clearButtonHandler(e)}
              onKeyDown={(e): void => clearButtonHandler(e)}
              className={clsx(
                'clear',
                clearBtnClassname ? clearBtnClassname : 'm-link-tertiary-button',
                'custom_click_track'
              )}
              {...{
                custom_click_track_value: clearBtnClickTrackValue
                  ? clearBtnClickTrackValue
                  : `${clickTrackingLoc}| Footer Button: Clear |internal`,
              }}
            >
              {clearButtonLabel}
            </button>
          )}
          <StyledApplyButtonDiv>
            <button
              type="button"
              aria-label={errorMessageAriaLabel ? errorMessageAriaLabel : applyLabel}
              role="button"
              onClick={(e): void => onHandleApplyClick(e)}
              tabIndex={applyButtonDisabled ? -1 : 0}
              className={clsx(
                'm-button-m',
                'apply-button',
                'm-button-primary',
                'custom_click_track',
                applyButtonDisabled ? 'disabled' : ''
              )}
              {...{
                custom_click_track_value: applyBtnClickTrackValue
                  ? applyBtnClickTrackValue
                  : `${clickTrackingLoc}| Footer Button: Apply Button |internal`,
              }}
            >
              {applyLabel}
            </button>
          </StyledApplyButtonDiv>
        </>
      )}
      {fromToolTip && (
        <a
          href={viewRatesURL}
          className={clsx('view-rates-button-container', 'analytics-click', 'custom_click_track')}
          {...{ custom_click_track_value: `${clickTrackingLoc}| View Rates |internal` }}
          tabIndex={-1}
        >
          <button
            type="button"
            className={clsx(
              'm-button-m',
              'view-rates-button',
              'apply-button',
              'm-button-primary',
              'custom_click_track'
            )}
            {...{ custom_click_track_value: `${clickTrackingLoc}| Footer Button: View Rate Button |internal` }}
          >
            {viewRateLabel}
          </button>
        </a>
      )}
    </StyledPopupFooterDiv>
  );
};

export default PopupFooter;
