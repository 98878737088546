// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';

export const StyledTab = styled.div`
  border-radius: ${toRem(12)};
  box-shadow: 0 ${toRem(3)} ${toRem(16)} 0 rgba(0, 0, 0, 0.15);
  padding: ${toRem(32)} ${toRem(0)} ${toRem(27)} ${toRem(0)};
  .m-standard-tab-list-item:first-child {
    margin-right: ${toRem(17)};
  }
  @media ${baseVariables.mediaQuery.md} {
    padding: ${toRem(24)} ${toRem(0)} ${toRem(27)} ${toRem(0)};
  }
  @media ${baseVariables.mediaQuery.lg} {
    padding: ${toRem(32)} ${toRem(0)} ${toRem(27)} ${toRem(0)};
  }
  .tab-desc {
    margin-top: ${toRem(-5)} !important;
  }
  .inactive-tab {
    opacity: 0.4;
  }
`;
