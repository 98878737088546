// // Imports for external libraries go here.
import { FC } from 'react';
import { CurrentLocationFeaturesProps } from './CurrentLocationFeature.types';
import CurrentLocationGoogle from './CurrentLocation/CurrentLocationGoogle';
import CurrentLocationBaidu from './CurrentLocation/CurrentLocationBaidu';
import { LOCALE_ZH_CN } from '../../constants/ApplicationConstants';

// Use named rather than default exports.
export const CurrentLocationFeature: FC<CurrentLocationFeaturesProps> = ({
  inputFieldHandler,
  currentLocationHiddenFieldHandler,
  locale,
  apiKey,
  currentLocationLabel,
  render,
  iconTextBlockCustomClass,
}) => {
  if (locale === LOCALE_ZH_CN) {
    return (
      <div data-component-name="m-ui-library-CurrentLocationFeature" data-testid="ui-library-CurrentLocationFeature">
        <CurrentLocationBaidu
          inputFieldHandler={inputFieldHandler}
          currentLocationHiddenFieldHandler={currentLocationHiddenFieldHandler}
          apiKey={apiKey}
          currentLocationLabel={currentLocationLabel}
          iconTextBlockCustomClass={iconTextBlockCustomClass}
          render={render}
        />
      </div>
    );
  } else {
    return (
      <div data-component-name="m-ui-library-CurrentLocationFeature" data-testid="ui-library-CurrentLocationFeature">
        <CurrentLocationGoogle
          inputFieldHandler={inputFieldHandler}
          currentLocationHiddenFieldHandler={currentLocationHiddenFieldHandler}
          apiKey={apiKey}
          currentLocationLabel={currentLocationLabel}
          iconTextBlockCustomClass={iconTextBlockCustomClass}
          render={render}
        />
      </div>
    );
  }
};
