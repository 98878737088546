import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledUsePoints = styled.div`
  ${rtl`
  .checkbox-label > label {
    font-size: ${baseVariables.font.fontM};
    font-weight:${baseVariables.font.fontWeightRegular};
    padding: 0 0 0 ${toRem(26)};
    @media ${baseVariables.mediaQuery.desktop} {
      font-size: ${baseVariables.font.fontXs};
    }
    width: fit-content;
  }
  .checkbox-switch-wrapper {
    justify-content: space-between;
  }
  .adv-searchform > label{
    padding-left:${toRem(26)};    
    }
`}
`;
