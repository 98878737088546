import React, { FC, useEffect, useRef, useState } from 'react';
import { AccountPanelProps } from './AccountPanel.types';
import { StyledAccountPanel, GlobalStyle } from './AccountPanel.styles';
import { MemberPanel } from '../MemberPanel';

import { SignInForm } from '../SignInForm';

import { HeaderLogo, Button, Icon } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { useUserDetailsStore } from '@marriott/mi-store-utils';

export const AccountPanel: FC<AccountPanelProps> = props => {
  const { isSignedInUser } = useUserDetailsStore();
  const { togglePanel, headerLogoProps, memberPanelProps, signInFormProps } = props;
  const panelRef = useRef<HTMLDivElement>(null);
  const focusableElements = useRef<HTMLElement[]>([]);
  const [isPanelOpen, setIsPanelOpen] = useState(true);

  const handlePanelClose = () => {
    setIsPanelOpen(false);
    setTimeout(togglePanel, 300);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef?.current && !panelRef?.current?.contains(event.target as Node)) {
      handlePanelClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.classList.add('modal-open');
    focusableElements.current = Array.from(
      panelRef.current?.querySelectorAll(
        'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      ) || []
    );
    focusableElements?.current[0]?.focus();
    return () => {
      setIsPanelOpen(false);
      document.body.classList.remove('modal-open');
    };
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      const firstFocusableElement = focusableElements.current[0];
      const lastFocusableElement = focusableElements.current[focusableElements.current.length - 1];

      if (event.shiftKey && document.activeElement === firstFocusableElement) {
        event.preventDefault();
        lastFocusableElement.focus();
      } else if (!event.shiftKey && document.activeElement === lastFocusableElement) {
        event.preventDefault();
        firstFocusableElement.focus();
      }
    }
    if (event.key === 'Escape') {
      handlePanelClose();
    }
  };

  return (
    <>
      <StyledAccountPanel isOpen={isPanelOpen} ref={panelRef} onKeyDown={handleKeyDown} role="dialog">
        <div
          className={clsx({
            'd-flex align-items-center justify-content-between p-0 headerWrapper': !isSignedInUser,
            authenticated: isSignedInUser,
          })}
        >
          {!isSignedInUser && <HeaderLogo {...headerLogoProps} />}
          <Button
            className={'m-icon-button-primary closeButton'}
            callback={handlePanelClose}
            id="closeButton"
            testId="closeButton"
          >
            <Icon iconClass="icon-clear icon-s" ariaLabel="close account panel" />
          </Button>
        </div>
        <div className="mx-3 mx-lg-5 px-lg-2">
          {isSignedInUser ? <MemberPanel {...memberPanelProps} /> : <SignInForm {...signInFormProps} />}
        </div>
      </StyledAccountPanel>
      <GlobalStyle />
    </>
  );
};
