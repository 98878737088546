// Imports for external libraries go here.
import React, { FC } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { StepperProps } from './Stepper.types';
import { StyledStepper } from './Stepper.styles';
import clsx from 'clsx';

// Use named rather than default exports.
export const Stepper: FC<StepperProps> = ({
  stepperText,
  handleDecrement,
  handleIncrement,
  disableIncrement,
  disableDecrement,
  analyticsVal,
  increamentLabel,
  decreamentLabel,
}) => {
  return (
    <StyledStepper data-component-name="m-ui-library-Stepper" data-testid="ui-library-Stepper">
      <div className="m-stepper-wrapper">
        <div className="m-stepper">
          <div className="disable-hover">
            <button
              type="button"
              className={clsx('custom_click_track', disableDecrement && 'btn-disable')}
              {...{ custom_click_track_value: `${analyticsVal} Decrement button |internal` }}
              aria-disabled={disableDecrement}
              data-testid="remove-btn"
              tabIndex={disableDecrement ? -1 : 0}
              onClick={handleDecrement}
            >
              <span aria-hidden="true" className="icon-minus"></span>
              <span className="sr-only">{decreamentLabel}</span>
            </button>
          </div>
          <span aria-live="polite">{stepperText}</span>
          <div className="disable-hover">
            <button
              type="button"
              className={clsx('custom_click_track', disableIncrement && 'btn-disable')}
              {...{ custom_click_track_value: `${analyticsVal} Increment button |internal` }}
              aria-disabled={disableIncrement}
              data-testid="add-btn"
              tabIndex={disableIncrement ? -1 : 0}
              onClick={handleIncrement}
            >
              <span aria-hidden="true" className="icon-plus"></span>
              <span className="sr-only">{increamentLabel}</span>
            </button>
          </div>
        </div>
      </div>
    </StyledStepper>
  );
};
