// Styles for SkeletonLoader go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const CarouselContainer = styled.div`
  margin: ${toRem(55)} 0;
`;

export const CarouselElement = styled.div`
  width: 100%;
  height: ${toRem(343)};
  background-color: ${baseVariables.color['neutral20']};
  margin-right: ${toRem(16)};

  @media ${baseVariables.mediaQuery.xl} {
    width: ${toRem(364)};
    height: ${toRem(364)};
  }
`;

export const CarouselElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${CarouselElement}:last-child {
    margin-right: 0;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${toRem(16)};
  align-items: baseline;

  @media ${baseVariables.mediaQuery.xl} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const CarouselCta = styled.div`
  background-color: ${baseVariables.color['neutral20']};
  height: ${toRem(16)};
  width: ${toRem(100)};

  @media ${baseVariables.mediaQuery.xl} {
    width: ${toRem(100)};
    height: ${toRem(16)};
  }
`;

export const CarouselTitle = styled.div`
  background-color: ${baseVariables.color['neutral20']};
  height: ${toRem(28)};
  width: 100%;
  margin-bottom: ${toRem(16)};

  @media ${baseVariables.mediaQuery.xl} {
    width: ${toRem(350)};
    height: ${toRem(32)};
    margin-bottom: 0;
    width: 60%;
  }
`;

export const FullWidthContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const CarouselPaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${toRem(16)};
  width: ${toRem(58)};
  align-items: center;
`;

export const CarouselPaginationCircle = styled.div<{ size: number | null }>`
  height: ${({ size }) => toRem(size ?? 6)};
  width: ${({ size }) => toRem(size ?? 6)};
  background-color: ${baseVariables.color['neutral20']};
  border-radius: 50%;
  display: inline-block;
`;

export const BannerLayeredBlock = styled.div`
  position: relative;
  background-color: ${baseVariables.color['neutral20']};
  margin: ${toRem(55)} -50vw;
  left: 50%;
  right: 50%;
  height: ${toRem(515)};
  width: 100vw;

  @media ${baseVariables.mediaQuery.xl} {
    height: ${toRem(576)};
  }
`;

export const CardMiniBlock = styled.div`
  height: ${toRem(100)};
  width: 100%;
  background-color: ${baseVariables.color['neutral20']};

  @media ${baseVariables.mediaQuery.xl} {
    height: ${toRem(120)}
`;
