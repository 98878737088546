let SUBDIRECTORY_PREFIX = '';
let LANGUAGE = '';

export function setSubDirectoryPrefix(prefix = '') {
  SUBDIRECTORY_PREFIX = prefix;
}

/**
 *
 * @param url
 * This is used for prefix the url. This will check for subdirectory prefix
 * if present then it will be prefixed otherwise the url is returned without prefix.
 */
export const addSubDirectoryPrefix = function (url = '') {
  if (
    SUBDIRECTORY_PREFIX &&
    url?.startsWith('/') &&
    url?.substring(1, findNthOccurence(url, 1, '/'))?.toLowerCase() !== SUBDIRECTORY_PREFIX?.toLowerCase()
  ) {
    return SUBDIRECTORY_PREFIX ? '/' + SUBDIRECTORY_PREFIX + url : url;
  } else return url;
};

/**
 *
 * @param url as str
 * This is used find the nth occurence of a char in string
 */
const findNthOccurence = function (str: string, nth: number, char: string) {
  let index = 0;
  for (let i = 0; i < nth; i += 1) {
    if (index !== -1) index = str.indexOf(char, index + 1);
  }
  return index;
};

/**
 *
 * @param language
 * This is used to set the LANGUAGE value initially from model json called from _app.tsx
 */
export const setLanguage = (language = '') => {
  if (language && LANGUAGE !== language) {
    LANGUAGE = language;
  }
};

/**
 * returns language
 * This is used to get the langauge which is initially set from _app.tsx
 */
export const getLanguage = () => {
  return LANGUAGE;
};
