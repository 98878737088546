import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '@marriott/mi-ui-library';

export const StyledSearchField = styled.div`
  ${rtl`
  .text-secondary {
    font-size: ${toRem(12)} !important;
  }
  `}
`;
