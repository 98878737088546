import { OperationSignature, DeployedEnvType, LogType, UXLHttpHeader, UXLHttpHeaders, ApolloEnvVars } from './types';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

// Fetch env vars from webpack Dotenv
// Must be destructured one at a time.
const { APOLLOGRAPHQL_FULL_NGINX_ENDPOINT } = process.env;
const { APOLLOGRAPHQL_PUBLIC_LOCAL_DEV } = process.env;
const { APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH } = process.env;
const { APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH } = process.env;
const { APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW } = process.env;
const { APOLLOGRAPHQL_PUBLIC_DEBUG_LOG } = process.env;

// Normalize destructured env vars.
// TODO: This should be done for all vars to allow for use outside of NextJS/webpack-dotenv setup.

export const getEnvFullNginxEndpoint = () => {
  return (
    APOLLOGRAPHQL_FULL_NGINX_ENDPOINT ??
    process.env['APOLLOGRAPHQL_FULL_NGINX_ENDPOINT'] ??
    useClientEnvVarsStore.getState().envVarsObject['APOLLOGRAPHQL_FULL_NGINX_ENDPOINT'] ??
    ''
  );
};

export const getEnvPublicLowerEnvPath = () => {
  return (
    APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH ??
    process.env['APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH'] ??
    useClientEnvVarsStore.getState().envVarsObject['APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH'] ??
    ''
  );
};

export const getEnvPublicLocalDev = () => {
  return (
    APOLLOGRAPHQL_PUBLIC_LOCAL_DEV ??
    process.env['APOLLOGRAPHQL_PUBLIC_LOCAL_DEV'] ??
    useClientEnvVarsStore.getState().envVarsObject['APOLLOGRAPHQL_PUBLIC_LOCAL_DEV'] ??
    ''
  );
};

export const getEnvPublicHigherEnvPath = () => {
  return (
    APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH ??
    process.env['APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH'] ??
    useClientEnvVarsStore.getState().envVarsObject['APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH'] ??
    ''
  );
};
//For backward compatibility adding check for components making UXL call in preview mode
export const getActiveInAemPreview = () => {
  return (
    APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW ??
    process.env['APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW'] ??
    useClientEnvVarsStore.getState().envVarsObject['APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW'] ??
    ''
  );
};
/**
 * Check if UXL call is from preview mode
 */
export const isPreviewMode = () => {
  const pathName = typeof window != 'undefined' ? window?.location?.href : '';
  const paramWcmMode = pathName?.includes('?') ? pathName.split('?') : '';
  const isPreview = paramWcmMode?.includes('wcmmode=disabled') && getActiveInAemPreview() === 'true';
  return isPreview;
};

export const isServer = !(typeof window != 'undefined' && window.document);
export const isLocalDev = getEnvPublicLocalDev() === 'true';
export const isLocalClientSide = !isServer && isLocalDev;
export const isDeployedClientSide = !isServer && !isLocalDev;
export const isPreview = isPreviewMode();

/**
 * Normalize string input for deployed env type.
 * @param input
 * @returns
 */
export const normalizeDeployedEnvType = (input: string): DeployedEnvType => {
  // doDebugLog(`normalizeDeployedEnvType: ' ${input}, ${process.env['DEPLOYED_ENV_TYPE']}`, 'info');
  // doDebugLog(`DEPLOYED_ENV_TYPE: ' ${process.env['DEPLOYED_ENV_TYPE']}`, 'info');
  return input === 'higher' ? 'higher' : 'lower';
};

/**
 * Retrieve deployed environment type from env variables during runtime.
 * @returns
 */
export const getDeployedEnvType = (): DeployedEnvType => {
  return normalizeDeployedEnvType(
    process.env['DEPLOYED_ENV_TYPE'] || useClientEnvVarsStore.getState().envVarsObject['DEPLOYED_ENV_TYPE'] || ''
  );
};

export const doLogging = () => {
  return (
    getDeployedEnvType() === 'lower' ||
    APOLLOGRAPHQL_PUBLIC_DEBUG_LOG === 'true' ||
    process.env['APOLLOGRAPHQL_PUBLIC_DEBUG_LOG'] === 'true' ||
    useClientEnvVarsStore.getState().envVarsObject['APOLLOGRAPHQL_PUBLIC_DEBUG_LOG'] === 'true'
  );
};

/**
 * Extremely small logger utility.
 *
 * Logs according to env variable APOLLOGRAPHQL_PUBLIC_FORCE_DEBUG_LOG.
 *
 * @param msg
 * @param msgType
 * @param deployedEnvType
 */
export const debugPreamble = '[mi-apollo-client-utils log]: ';
export const doDebugLog = (msg: string, msgType: LogType) => {
  if (doLogging()) {
    if (msgType === 'log') {
      console.log(debugPreamble, msg);
    }
    if (msgType === 'error') {
      console.error(debugPreamble, msg);
    }
    if (msgType === 'warn') {
      console.warn(debugPreamble, msg);
    }
    if (msgType === 'info') {
      console.info(debugPreamble, msg);
    }
    if (msgType === 'debug') {
      console.debug(debugPreamble, msg);
    }
  }
};

/**
 * Constructs UXL endpoint.
 *
 * Assumptions:
 *  - Client-side from localhost for local dev, hit UXL endpoint directly.
 *  - SSR, hit full NGINX endpoint, NGINX attaches credentials.
 *  - Client-side from deployed environment:
 *    - Lower environments: /query
 *    - Higher environments: /mi/query
 *
 * @param operationName
 * @returns
 */
export const useGetUxlEndpoint = (operationName: string, envVars: ApolloEnvVars) => {
  doDebugLog(
    `useGetUxlEndpoint: isServer: ${isServer}, isLocalDev: ${isLocalDev}, isLocalClientSide: ${isLocalClientSide}, isDeployedClientSide: ${isDeployedClientSide}`,
    'info'
  );

  // TODO: grab everything from envVars directly, remove all this fallback logic
  const envVarsFromStore = useClientEnvVarsStore.getState().envVarsObject;
  const fullNginxEndpoint = envVars?.APOLLOGRAPHQL_FULL_NGINX_ENDPOINT ?? getEnvFullNginxEndpoint();
  const isPublicLocalDev = envVars?.APOLLOGRAPHQL_PUBLIC_LOCAL_DEV ?? getEnvPublicLocalDev();
  const publicLowerEnvPath = envVars?.APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH ?? getEnvPublicLowerEnvPath();
  const publicHigherEnvPath = envVars?.APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH ?? getEnvPublicHigherEnvPath();

  // We need custom endpoint for client-side localhost calls (but not server-side local dev calls).
  let endpoint = '';

  // Server-side, return full NGINX endpoint.
  if (isServer || isPreview) {
    if (!fullNginxEndpoint) {
      doDebugLog(
        '🚨 Unable to construct UXL endpoint. Environment variable APOLLOGRAPHQL_FULL_NGINX_ENDPOINT not provided.',
        'error'
      );
      return;
    } else {
      endpoint = fullNginxEndpoint;
    }
  }

  // Localhost client-side, hit UXL endpoint directly.
  const localEndpoint =
    envVars?.APOLLOGRAPHQL_LOCAL_ENDPOINT ||
    process.env['APOLLOGRAPHQL_LOCAL_ENDPOINT'] ||
    envVarsFromStore['APOLLOGRAPHQL_LOCAL_ENDPOINT'];
  if (isLocalClientSide && !isPreview) {
    if (!localEndpoint) {
      doDebugLog(
        '🚨 Unable to construct UXL endpoint for local client side dev. Environment variable APOLLOGRAPHQL_LOCAL_ENDPOINT not provided.',
        'error'
      );
      return;
    } else {
      endpoint = localEndpoint;
    }
  }
  //Localhost client-side AEM preview mode check
  if (isLocalClientSide && isPreview) {
    if (!localEndpoint) {
      doDebugLog(
        '🚨 Unable to construct UXL endpoint for local client side dev. Environment variable APOLLOGRAPHQL_LOCAL_ENDPOINT not provided.',
        'error'
      );
      return;
    } else {
      endpoint = fullNginxEndpoint;
    }
  }
  if (!isServer && isPublicLocalDev !== 'true' && !isPreview) {
    // For lower environment, use lower env path.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isLowerEnv = envVars.DEPLOYED_ENV_TYPE === 'lower';
    if (isLowerEnv) {
      if (!publicLowerEnvPath) {
        doDebugLog(
          '🚨 Unable to construct UXL endpoint for local client side dev. Environment variable APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH not provided.',
          'error'
        );
      } else {
        endpoint = publicLowerEnvPath;
      }
    } else {
      // For higher env, use higher env path.
      if (!publicHigherEnvPath) {
        doDebugLog(
          '🚨 Unable to construct UXL endpoint for local client side dev. Environment variable APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH not provided.',
          'error'
        );
      } else {
        endpoint = publicHigherEnvPath;
      }
    }
  }

  if (!operationName) {
    doDebugLog('🚨 Unable to construct UXL endpoint. operationName not provided.', 'error');
    return;
  }

  doDebugLog(`useGetUxlEndpoint endpoint: ${endpoint}, operationName: ${operationName}`, 'info');

  return `${endpoint}/${operationName}`;
};

// Returns operation signature from an array of OperationSignature objects.
export const useGetOperationSignature = (operationName: string, operationSignatures: OperationSignature[]) => {
  const signature =
    operationSignatures?.find((el: OperationSignature) => el.operationName === operationName)?.signature ?? '';
  // eslint-disable-next-line no-extra-boolean-cast
  if (!Boolean(signature)) {
    doDebugLog(
      `🚨 useGetOperationSignatures: Signature for operationName ${operationName} not found. Have you added this signature to your operationSignatures constants object?`,
      'error'
    );
    return '';
  }
  return signature;
};

export const validateOperationSignatures = (operationSignatures: OperationSignature[]) => {
  const opSigNames = operationSignatures?.map((item: OperationSignature) => {
    return item.operationName;
  });
  const opSigHashes = operationSignatures?.map((item: OperationSignature) => {
    return item.signature;
  });
  // Check for duplicate operation names
  const opSigNamesHasDuplicates = opSigNames?.length !== new Set(opSigNames).size;
  // Check for duplicate signatures
  const opSigHashesHasDuplicates = opSigHashes?.length !== new Set(opSigHashes).size;
  if (opSigNamesHasDuplicates || opSigHashesHasDuplicates) {
    if (opSigNamesHasDuplicates) {
      doDebugLog(
        '🚨 You have duplicate operation signature names!! Please check your operation signatures file.',
        'error'
      );
    }
    if (opSigHashesHasDuplicates) {
      doDebugLog('🚨 You have duplicate operation signatures!! Please check your operation signatures file.', 'error');
    }
    return false;
  }
  return true;
};

export const validateHeaders = (headers: Partial<UXLHttpHeaders>) => {
  const validHeaders: Record<string, UXLHttpHeader> = {};
  // For all keys in headers object...
  for (const key in headers) {
    // ... if not an empty string,
    // copy over key and value.
    if (headers[key] && String(headers[key])?.replace(/\s+/g, '').length > 0) {
      validHeaders[key] = headers[key] as UXLHttpHeader;
    }
  }
  doDebugLog(`validateHeaders: ${JSON.stringify(validHeaders)}`, 'info');
  return validHeaders;
};

export const isEmptyObject = (obj: Record<string, unknown>): boolean => {
  return Object.keys(obj).length === 0;
};
