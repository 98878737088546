/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import clsx from 'clsx';

import { LinksCollectionProps, LinksCollectionVariations, LinksCollectionAlignment } from './LinksCollection.types';
import { StyledLinkCollection } from './LinksCollection.styles';
import { Icon } from '../../atoms/Icon';
import { Link } from '../../atoms/Link';
import { Heading } from '../../atoms/Heading';
import { tags, headingType } from '../../utils/enums/enums';

export const LinksCollection: React.FC<LinksCollectionProps> = ({
  variation = LinksCollectionVariations.Standard,
  id,
  heading,
  hasIconArrow = true,
  linkListData,
  alignment = 'vertical-align',
  children,
  trackingProperties,
  ...props
}) => {
  const listCollectionClass =
    variation === LinksCollectionVariations.Standard
      ? `${clsx('cl__list', 'm-accordion__list', 'collapsed hide-visibility')}`
      : '';
  const linkListClass = variation === LinksCollectionVariations.Standard ? `${clsx('cl__list-item')}` : '';
  const linkItemClass = variation === LinksCollectionVariations.Standard ? `${clsx('cl__item-link t-font-s')}` : '';
  const customAttributes = trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': props?.componentId }
    : {};

  return (
    <>
      {heading && (
        <Heading
          element={tags.h2}
          variation={headingType.subtitle}
          titleText={heading}
          customClass={props.headingClassName}
        />
      )}
      <StyledLinkCollection
        data-component-name="m-ui-library-LinksCollection"
        data-testid="ui-library-LinksCollection"
        id={id}
        className={clsx(
          listCollectionClass,
          props?.listCollectionClassname,
          alignment === LinksCollectionAlignment.Horizontal ? 'horizontal-align' : 'vertical-align'
        )}
        {...customAttributes}
      >
        {linkListData?.map((el: any, idx: number) => (
          <li className={clsx(linkListClass, props?.linkListClassName)} key={`${el}_${idx}`} id={el?.id}>
            <Link
              linkHref={el?.linkURL}
              linkClassName={clsx(
                linkItemClass,
                props?.listItemClassName,
                `${trackingProperties?.clickTrack ? 'custom_click_track' : ''}`,
                variation === LinksCollectionVariations.Standard && hasIconArrow
                  ? el?.openInNewTab
                    ? 'icon-m icon-arrow-right-external'
                    : ''
                  : ''
              )}
              custom_click_track_value={
                trackingProperties?.clickTrack
                  ? `${trackingProperties?.location}|${el?.linkLabel}|${el?.openInNewTab ? 'external' : 'internal'}`
                  : ''
              }
              rel={el?.openInNewTab ? 'noopener noreferrer' : ''}
              text={el?.linkLabel}
              target={el.openInNewTab ? '_blank' : '_self'}
            >
              {variation === LinksCollectionVariations.Standard && hasIconArrow && (
                <Icon iconClass={clsx('icon-m', !el?.openInNewTab ? 'icon-arrow-right' : '')} />
              )}
              <span className="sr-only">{el?.openInNewTab ? 'Opens a new window' : ''}</span>
              {children}
            </Link>
          </li>
        ))}
      </StyledLinkCollection>
    </>
  );
};
