import { AEMModel, renditions } from '@marriott/mi-ui-library';
import { MutableRefObject } from 'react';

export type MegaMenuItem = {
  openInaNewTab: string;
  title: string;
  url: string;
};

export type SubNavigationProps = {
  megamenuitems: AEMModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subNavItems: any;
  isArticleCardEnabled?: boolean;
  i18nOpensInNewTab: string;
  maxItemsPerColumn?: number;
  forwardedRefs: MutableRefObject<HTMLElement | null>[];
  //TODO: this might need refactoring while integarting Article Card Component
  cardArticleData?: {
    titletext?: string;
    descriptiontext?: string;
    ctalink?: string;
    ctalabel?: string;
    openinanewtab?: string;
  };
  dynamicData?: renditions;
  handleMegaMenuClose: () => void;
  primaryParentLabel?: string;
};

export const MAX_ITEMS_IN_A_COLUMN = 8;

export type FocusHandler = {
  focus: () => void;
};
