/* eslint-disable @typescript-eslint/no-explicit-any */
import { generateRandomString } from '.';
import { cleanObject, mtGC, mtGCV } from '../helpers';
import { DCA_CONSTANTS } from '../constants';
import Cookies from 'js-cookie';

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>;
    Visitor: {
      getInstance(gcv: string): {
        getMarketingCloudVisitorID(): string;
        getLocationHint(): string;
        getAudienceManagerBlob(): string;
      };
    };
  }
}

export function targetPayloadRequest(req: any, datalayer: Record<string, unknown> = window?.['dataLayer'] || {}) {
  const sessionData = window?.sessionData || {};
  const satellite = window?._satellite;
  const visitor = window?.['Visitor']?.getInstance(DCA_CONSTANTS.orgId);
  const isUxl = req.isUxl === 'false' ? false : true;
  const targetParameters = targetRequestPayload([
    'env_is_prod',
    'env_site_id',
    'env_platform',
    'env_site_name',
    'env_server_id',
    'mr_prof_authentication_state',
    'search_advance_purchase_days',
    'prop_address_city_state_country',
    'search_google_places_destination',
    'country',
    'canBuyPoints',
    'daysSinceJoining',
    'daysToCheckin',
    'miRecentlyViewedProperties',
    'mr_prof_upcoming_stay_consolidated',
    'search_cluster_code',
    'page_url_query_string',
    'cookie_merchViewed',
    'firstTimeUserCopy',
    'rpcCode',
    'offerCode',
    'specialMessage',
    'memState',
    'invokeFlushErrors',
    'device_language_preferred',
    'roomkey_api_key',
    'roomkey_api_version',
    'page_url_path_branch',
    'browser_akamai_loc_country',
    'ensighten_settings',
    'browser_akamai_loc_long',
    'rk_enabled',
    'page_requested_uri',
    'rk_errorMessage',
    'rk_roomKeyBaseURL',
    'env_img_path',
    'prof_address_state',
    'env_jacket',
    'rk_isProfileAuthenticated',
    'session_jsession_id',
    'cookie_mi_site',
    'sessionId',
    'rk_currency',
    'mr_isLinkedUser',
    'rk_cList',
    'browser_akamai_loc_lat',
    'env_date_time',
    'mvp_prequal_endpoint',
    'brwsrLang',
    'page_domain_name',
    'cookie_mi_visitor',
    'request_id',
    'page_data_layer_ready',
    'previous_page',
    'browser_akamai_loc_state',
    'mr_prof_cc_all',
    'mr_prof_address_country',
    'browser_akamai_loc_city',
    'mr_prof_nights_to_next_level',
    'mr_prof_points_balance',
    'mr_prof_member_program_level',
    'mvpOffers_source',
  ]);

  function getWEBGLRenderer() {
    const gl = document.createElement('canvas').getContext('webgl');
    if (!gl) {
      return '';
    }
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    return debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : '';
  }

  function getTnTId() {
    const MI_Visitor = mtGC('MI_Visitor') || datalayer?.['cookie_mi_visitor'];
    return MI_Visitor;
  }
  function getTnTIdforNonUxl() {
    const mboxCookieLength = Cookies?.get('mbox')?.split('#')?.length;
    if (Cookies?.get('mbox') && mboxCookieLength && mboxCookieLength > 2) {
      return Cookies?.get('mbox')?.split('#')?.[3] ?? getTnTId() ?? '';
    } else {
      return getTnTId();
    }
  }

  function getThirdPartyId() {
    const consumerID = sessionData?.cacheData?.data?.consumerID ?? sessionData?.data?.consumerID;
    const loginState = datalayer['memState'];
    const isUnAuthenticated = !(loginState === 'authenticated' || loginState === 'remembered');
    return isUnAuthenticated ? null : consumerID;
  }

  function targetRequestPayload(variables: any) {
    const result: any = {};
    const parameters = variables.map((variable: string) => {
      let value: any = ''; // Default value if no valid value found
      const getSatelliteVar = satellite?.['getVar'];
      if (satellite && satellite?.['getVar'] && getSatelliteVar(variable)?.length) {
        value = getSatelliteVar(variable);
      } else if (Array.isArray(datalayer?.[variable]) || typeof datalayer?.[variable] === 'string') {
        value = datalayer[variable];
      }
      if (variable === 'mvpOffers_source' && window?.mvpOffers?.source) {
        value = window.mvpOffers.source;
      }
      if (variable === 'mr_prof_authentication_state') {
        value = value || 'unauthenticated'; // Set default value 'unauthenticated' if value is falsy
      }

      if (variable === 'previous_page') {
        value = value ? value : '';
        datalayer[variable] = value ? value : '';
      }
      result[variable] = value;
      return { name: variable, value: value };
    });
    if (!isUxl) {
      if (datalayer && Object.keys(datalayer)?.length > 0) {
        const combinedObj = { ...result, ...datalayer };
        Object.keys(combinedObj).forEach(function (key) {
          if (combinedObj[key] === null) {
            combinedObj[key] = '';
          }
        });
        return combinedObj;
      } else {
        return result;
      }
    } else {
      return parameters;
    }
  }

  const tempPayload: any = {
    context: {
      userAgent: window?.navigator?.userAgent || 'test',
      channel: 'WEB',
      screen: {
        width: window?.screen?.width,
        height: window?.screen?.height,
        orientation: window?.screen?.orientation?.type?.split('-')[0].toUpperCase(),
        colorDepth: window?.screen?.colorDepth,
        pixelRatio: window?.screen?.pixelDepth,
      },
      window: {
        width: window?.innerWidth,
        height: window?.innerHeight,
      },
      browser: {
        host: window?.location?.host,
        webGLRenderer: getWEBGLRenderer(),
      },
      address: {
        url: window?.location?.href,
        referringUrl: window?.location?.origin,
      },
      timeOffsetInMinutes: -new Date().getTimezoneOffset(),
    },
    id: cleanObject({
      tntId: getTnTIdforNonUxl(),
      thirdPartyId: getThirdPartyId(),
      marketingCloudVisitorId:
        mtGCV(encodeURIComponent(DCA_CONSTANTS.gcv)) || visitor?.getMarketingCloudVisitorID() || '',
    }),
    property: {
      token: req.propertyToken,
    },
    experienceCloud: {
      audienceManager: {
        locationHint: visitor?.getLocationHint()?.toString() || null,
        // : (mtGC(encodeURIComponent(DCA_CONSTANTS.gcv)) &&
        //     mtGC(encodeURIComponent(DCA_CONSTANTS.gcv)).split('|')[4] !== 'NONE' &&
        //     mtGC(encodeURIComponent(DCA_CONSTANTS.gcv)).split('|')[4]) ||
        //   visitor.getLocationHint() ||
        //   '',
        blob: visitor?.getAudienceManagerBlob() || null,
        // : (mtGC(encodeURIComponent(DCA_CONSTANTS.gcv)) &&
        //     mtGC(encodeURIComponent(DCA_CONSTANTS.gcv)).split('|')[6]) ||
        //   visitor.getAudienceManagerBlob() ||
        //   '',
      },
      analytics: {
        trackingServer: process.env['TARGET_TRACKING_SERVER'] || DCA_CONSTANTS.trackingServer,
        trackingServerSecure: process.env['TARGET_TRACKING_SERVER_SECURE'] || DCA_CONSTANTS.trackingServerSecure,
        // logging: process.env['TARGET_TRACKING_SERVER_LOGGING'] || 'server_side',
        supplementalDataId: generateRandomString(),
      },
    },
    mboxes: [],
    execute: {},
  };

  // Conditionally add mboxes or execute
  if (isUxl) {
    tempPayload.mboxes = [
      {
        name: req.mboxName,
        localeOfContent: req.locale,
        parameters: targetParameters,
      },
    ];
    delete tempPayload.execute;
  } else {
    tempPayload.execute = {
      mboxes: [
        {
          name: req.mboxName,
          index: 0,
          parameters: targetParameters,
        },
      ],
    };
    delete tempPayload.mboxes;
  }
  return tempPayload;
}
