// Imports for external libraries go here.
import React, { useRef, useEffect, forwardRef } from 'react';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// The closer the import is to the file the lower it should be in this list.
import { MegaMenuProps } from './MegaMenu.types';
import { StyledMegaMenu } from './MegaMenu.styles';
import { Button, Icon, useWindowSize, useCheckBreakpoint } from '@marriott/mi-ui-library';

// Use named rather than default exports.
export const MegaMenu = forwardRef<HTMLButtonElement | null, MegaMenuProps>(
  (
    {
      handleCloseButtonClick,
      changeNavOverFlowStyle,
      currentMainNav,
      handleKeyDown,
      megaMenuCloseButtonLabel,
      children,
    },
    forwardedRef
  ) => {
    const megaMenuRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();

    const isDesktopViewport = useCheckBreakpoint('viewportL');

    const handleScroll = () => {
      if (megaMenuRef.current) {
        const { bottom } = megaMenuRef.current.getBoundingClientRect();
        const isInViewport = bottom <= 0 ? false : true;
        if (!isInViewport) {
          handleCloseButtonClick();
        }
      }
    };

    useEffect(() => {
      //prevents megamenu from overflowing to the right (outside of screen)
      if (megaMenuRef.current) {
        const megaMenuRect = megaMenuRef.current.getBoundingClientRect();
        const distanceToRight = window.innerWidth - megaMenuRect?.right;
        if (isDesktopViewport && distanceToRight <= 0) {
          megaMenuRef.current.style.left = `${parseInt(`${distanceToRight}`) - 10}px`;
        }
      }

      //add overflow hidden on navigation when megaMenu is mounted
      if (!isDesktopViewport) {
        changeNavOverFlowStyle('hidden');
      }

      //Handles Closing of MegaMenu when it is open and the user has scrolled past it
      const handleResize = () => {
        if (isDesktopViewport) {
          window.addEventListener('scroll', handleScroll);
        } else {
          window.removeEventListener('scroll', handleScroll);
        }
      };

      handleResize(); // Check on initial render

      window.addEventListener('resize', handleResize);

      return () => {
        //removes any overflow that was set on nav.
        changeNavOverFlowStyle('');

        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
      };
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, isDesktopViewport]);

    return (
      <StyledMegaMenu ref={megaMenuRef} className="standard t-background-color" onKeyDown={handleKeyDown}>
        <div className={clsx('sub-nav__back-btn-wrapper', 'd-lg-none')} onClick={handleCloseButtonClick}>
          <Button testId="backtomainnav">
            <Icon iconClass={'icon-back-arrow-cropped'} ariaLabel="back to main nav" />
          </Button>
          <span className="t-subtitle-l">{currentMainNav}</span>
        </div>
        <div className={clsx('sub-nav__content', 'sub-nav__open')}>
          <div className="sub-nav__close-btn-wrapper d-none d-lg-block">
            <Button
              //TODO: this value should come as an i18n value. can be taken as part of ticket: FSD-80288
              ariaLabel={megaMenuCloseButtonLabel}
              className="sub-nav__close-btn d-lg-flex t-font-alt-xs p-0"
              callback={handleCloseButtonClick}
              buttonCopy={megaMenuCloseButtonLabel}
              isTextBeforeChildren={false}
              testId="close megamenu"
              id="close-megamenu-button"
              ref={forwardedRef}
            >
              <Icon iconClass="icon-clear icon-s" ariaLabel={megaMenuCloseButtonLabel} />
            </Button>
          </div>
          <div className={clsx('sub-nav__nav')}>{children}</div>
        </div>
      </StyledMegaMenu>
    );
  }
);
