// Styles for IconFeatureBanner go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledIconFeatureBanner = styled.div`
  background-color: ${baseVariables.color['base20']};
  border-radius: ${toRem(14)};
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  padding: ${toRem(40)} ${toRem(28)};
  @media ${baseVariables.mediaQuery.md} {
    padding: ${toRem(64)} ${toRem(60)};
  }
  @media ${baseVariables.mediaQuery.lg} {
    padding: ${toRem(80)} ${toRem(95)};
  }
  .ifb {
    &-head,
    &-desc {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &-desc {
      -webkit-line-clamp: 3;
    }
    &-content-center {
      .ifb-head,
      .ifb-desc {
        text-align: center;
      }
      .btn-container {
        justify-content: center;
      }
    }
  }
  .wrapper-container {
    justify-content: space-between;
  }
  .content-container {
    margin: auto 0;
    @media ${baseVariables.mediaQuery.md} {
      flex: 0 0 52%;
    }
    @media ${baseVariables.mediaQuery.lg} {
      flex: 0 0 40%;
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    @media ${baseVariables.mediaQuery.md} {
      flex-direction: row;
    }
  }
  .icon-block-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &.has-width {
      margin: auto 0;
      @media ${baseVariables.mediaQuery.md} {
        flex: 0 0 38%;
      }
      @media ${baseVariables.mediaQuery.lg} {
        flex: 0 0 45%;
      }
    }
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      background: ${baseVariables.color['neutral20']};
    }
    &:before {
      height: 100%;
      width: ${toRem(1)};
      left: 50%;
    }
    &:after {
      width: 100%;
      height: ${toRem(1)};
      top: 50%;
    }
    .icon-block-item {
      display: flex;
      width: 50%;
      justify-content: center;
      &-inner {
        display: flex;
        flex-direction: column;
        width: ${toRem(120)};
        align-items: center;
        justify-content: center;
        min-height: ${toRem(120)};
        @media ${baseVariables.mediaQuery.md} {
          min-height: ${toRem(101)};
          width: ${toRem(95)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          min-height: ${toRem(106)};
          width: ${toRem(165)};
        }
        .icon-label {
          text-transform: uppercase;
          margin-top: ${toRem(12)};
          text-align: center;
          letter-spacing: ${toRem(1.2)};
          width: min-content;
          min-width: 90%;
          &.force-wr {
            min-width: inherit;
            @media ${baseVariables.mediaQuery.lg} {
              min-width: 90%;
            }
          }
        }
        span[class^='icon-'] {
          color: ${baseVariables.color['accent10']};
          font-size: ${toRem(36)};
        }
      }
    }
  }
`;
