import breakpoints from '@marriott/global-styles/dist/scss/base/variables/_mediaQueries.module.scss';

export const toRem = (pixels: number): string => `${pixels * 0.0625}rem`;
//To get browser useragent
export const getUserAgentType = (): string => {
  if (typeof window !== 'undefined' && navigator) {
    const userAgentType = navigator.userAgent.toLowerCase();
    if (userAgentType.indexOf('android') > -1 || userAgentType.indexOf('iphone') > -1) {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }
  return 'desktop';
};

// parsing breakpoints fetched from global styles and sending as int values
export const APPLICATION_BREAKPOINTS = (() => {
  const parsed: { [key: string]: number } = {};
  Object.keys(breakpoints).forEach(key => {
    parsed[key] = parseInt(breakpoints[key].replace('px', ''), 10);
  });
  return parsed;
})();
