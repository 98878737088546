import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledCurrentLocation = styled.div`
  ${rtl`
  margin: 0;
  .highlighted-item: focus {
    background: ${baseVariables.color.neutral20};
    border-radius: ${toRem(8)};
    outline:none;
  }
  a {
    position: relative;
    margin: 0 ${toRem(-16)};
    display: block;
    text-decoration: none;

    [class^='icon-'] {
      color: ${baseVariables.color.base10};
    }
    span {
      position: relative;
    }
    :hover,
    :focus {
      background-color: ${baseVariables.color.neutral20};
      font-weight: ${baseVariables.font['fontWeightMedium']};
    }
  }
  @media ${baseVariables.mediaQuery.lg} {
    padding:0 ;
  }
`}
`;
