import styled from 'styled-components';
import { toRem } from '../../styles';

export const StyledIconCollection = styled.ul`
  display: flex;
  &.vertical-align {
    flex-direction: column;
    [class*='portfolio-icon-'],
    [class*='brand-logo-'] {
      padding: ${toRem(8)} 0;
      display: inline-block;
    }
  }
  &.horizontal-align {
    flex-direction: row;
    [class*='portfolio-icon-'],
    [class*='brand-logo-'] {
      padding: 0 ${toRem(10)};
    }
  }
  li {
    list-style: none;
  }
`;
