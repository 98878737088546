import React, { FC } from 'react';
import { MessagesProps } from './Messages.types';
import clsx from 'clsx';
import { StyledMessages } from './Messages.styles';

/*
  Information!
  Atom : Messages will act as inline Message variations based on the requirements
  Props    : className - class name or clsx statement can be passed
             show - Flag to show and hide the dropdown
             setLinkClick - Flag to perform click Handler on link,
             messageType - Flag to set the variation of alert type,
             messageHeading - Flag to set the message heading,
             children - Helps to pass the content which might be additionally required,
             messageText - The alert message text value,
             linkText - link text value ,
             messageLink - Flag to check the redirection when clicked on the link,
             MessageRedirection - Redirects when clicked on the link,
             AlertLinkTextLabel - if we have the link in the alert message this prop
                                  helps to detect it so as to print the value on ui in correct format,
             clickTrackingText - click tracking value,
             messageVariation - Flag to check the in line alert message variations,
*/

declare module 'react' {
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}

export const Messages: FC<MessagesProps> = ({
  setLinkClick,
  className,
  messageType,
  messageHeading,
  children,
  messageText,
  linkText,
  messageLink,
  MessageRedirection,
  AlertLinkTextLabel,
  clickTrackingText,
  messageVariation,
}) => {
  /**
   * hasLink - flag to check the link based on the AlertLinkTextLabel exists in messageText or not
   * messageTextWithLink - helps to split the alert message value
   *                      so we can display the messageText as we require.
   */

  const hasLink = linkText && messageText?.includes(`${AlertLinkTextLabel}`);
  const messageTextWithLink = hasLink && messageText ? messageText.split(`${AlertLinkTextLabel}`) : [];

  return (
    <StyledMessages data-component-name="a-ui-library-Messages" data-testid="ui-library-Messages">
      <div className={clsx('container', className)}>
        <div className={clsx('row', 'justify-content-center')}>
          <div className={clsx('m-message-inline', 'col-12', messageType)}>
            <div className="m-message-content-wrap">
              <div className="m-message-content">
                {messageHeading ? <span>{messageHeading}</span> : ''}
                {messageText ? (
                  <div className="m-message-sub-content">
                    {/*
                    Message in line ul-li variation if there is no link in alert message
                  */}

                    {messageText && !hasLink && messageVariation ? (
                      <ul>
                        <li>{messageText}</li>
                      </ul>
                    ) : (
                      ''
                    )}

                    {/*
                    Message in line block variation if there is no link in alert message
                  */}
                    {messageText && !hasLink && !messageVariation ? messageText : ''}

                    {/*
                    Message in line variation if there is any link in alert message
                  */}
                    {messageTextWithLink ? messageTextWithLink[0] : ''}
                    {hasLink ? (
                      <a
                        href={clsx(messageLink ? MessageRedirection : 'javascript:void(0)')}
                        className={clsx('t-font-s message-link', clickTrackingText ? 'custom_click_track' : '')}
                        onClick={(_e): void => {
                          if (setLinkClick) setLinkClick();
                        }}
                        {...{
                          custom_click_track_value: clickTrackingText ?? '',
                        }}
                      >
                        {linkText}
                      </a>
                    ) : (
                      ''
                    )}
                    {messageTextWithLink[1] ?? ''}
                  </div>
                ) : (
                  ''
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledMessages>
  );
};
