// Types for Navigation go here.

import { UtilityNavigationType } from './NavigationUtilityLink';
import { AEMModel, AEMContainerProps, SortedModelArray } from '@marriott/mi-ui-library';
import { BrandribbonVariation, linkLists } from '../../organisms/BrandRibbon/BrandRibbon.types';

export interface AemContainerConfig {
  emptyLabel: string;
  isEmpty: () => boolean;
  resourceType: string;
  cqPath: string;
  isInEditor: boolean;
}

export enum PrimaryNavEnum {
  PANEL_TITLE = 'cq:panelTitle',
  PANEL_URL = 'cq:panelUrl',
  RESOURCE_TYPE = ':type',
}

export enum ComponentTypeEnum {
  MEGA_MENU = 'mi-aem-homepage-spa/components/content/mega-menu',
  BRAND_RIBBON = 'mi-aem-common-spa/components/content/brandribbon',
}
export interface PrimaryNavItemProps {
  [PrimaryNavEnum.PANEL_TITLE]: string;
  [PrimaryNavEnum.PANEL_URL]?: string;
  openInaNewTab: string;
  megaMenuItems?: SortedModelArray;
  maxColumnItems: number;
  [PrimaryNavEnum.RESOURCE_TYPE]: string;
  categorylists?: linkLists[];
  variations?: BrandribbonVariation;
  cqPanelTitle?: string;
  cqPanelUrl?: string;
  exploreLabel?: string;
}
export interface PrimaryNavItemWrapperProps extends AEMContainerProps {
  index: number;
  activeMainNav: number | null;
  setActiveMainNav?: (f: number | null) => void;
  isFocused: boolean;
  setIsFocused?: (f: boolean) => void;
  primaryNavRefs: React.MutableRefObject<HTMLAnchorElement[]>;
  changeNavOverFlowStyle: (overflow: 'hidden' | 'auto' | 'visible' | '') => void;
  primaryNavigationItem: AEMModel;
  utilityNavigation?: UtilityNavigationType;
  setIsMegaMenuActive: (f: boolean) => void;
  megaMenuCloseButtonLabel?: string;
}

export interface NavigationProps extends AEMContainerProps {
  primaryNavigationItems: SortedModelArray;
  utilityNavigation?: UtilityNavigationType;
  setIsMegaMenuActive: (f: boolean) => void;
  megaMenuCloseButtonLabel?: string;
}

export interface NavigationWrapperProps extends AEMContainerProps {
  model: AEMModel;
  utilityNavigation?: UtilityNavigationType;
  setIsMegaMenuActive: (f: boolean) => void;
}
