import { createStore, useStore } from 'zustand';
import { produce } from 'immer';
import { createContext, useContext } from 'react';

export interface PageModelState {
  pageModel: unknown;
  setPageModel?: (pageModel: unknown) => void;
  updateComponent?: (componentKey: string, data: unknown) => void;
}

const initialState: PageModelState = {
  pageModel: {},
};
export type PageModelStoreType = ReturnType<typeof createPageModelStore>;

export const createPageModelStore = (initProps?: PageModelState) => {
  //creating vanila zustand store instead of react zustand store so that initial state can be passed. As per Zustand recommendation: https://docs.pmnd.rs/zustand/guides/initialize-state-with-props

  return createStore<PageModelState>()(set => ({
    ...initialState,
    ...initProps,
    setPageModel: (data: unknown) => {
      set({ pageModel: data });
    },
    updateComponent: (componentKey: string, data: unknown) => {
      set((state: PageModelState) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        produce(state, (draft: any) => {
          if (draft.pageModel.cqItems[componentKey]) {
            draft.pageModel.cqItems[componentKey] = data;
          }
        })
      );
    },
  }));
};

export const PageModelContext = createContext<PageModelStoreType | null>(null);

export function usePageModelStore<T>(selector: (state: PageModelState) => T): T {
  //mimicing the actual zustand hook so that we can pass inital state with pue re-renderer
  const store = useContext(PageModelContext);
  if (!store) throw new Error('Missing usePageModelStore.Provider in the tree');
  return useStore(store, selector);
}
