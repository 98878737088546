import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledMemberPanel = styled.div`
  margin-top: ${toRem(40)};
  @media ${baseVariables.mediaQuery.lg} {
    margin-top: ${toRem(52)};
  }
  .heading {
    max-width: fit-content;
  }
  .subHeading {
    font-size: ${toRem(14)};
    line-height: ${toRem(24)};
    color: ${baseVariables.color['neutral40']};
    max-width: fit-content;
    margin-bottom: ${toRem(16)};
    font-weight: ${baseVariables.font.fontWeightRegular};
    white-space: break-spaces;
    @media ${baseVariables.mediaQuery.lg} {
      margin-bottom: ${toRem(24)};
    }
  }
  &.authenticated {
    margin-top: ${toRem(52)};
    @media ${baseVariables.mediaQuery.lg} {
      margin-top: ${toRem(100)};
    }
    .listCollection {
      list-style: none;
      .listClassName {
        font-size: ${toRem(16)};
        line-height: ${toRem(19)};
        &:hover {
          border-bottom-width: ${toRem(1)};
          padding-bottom: ${toRem(1.5)};
        }
      }
    }

    .signout {
      color: ${baseVariables.color['alert40']};
      font-size: ${toRem(12)};
      letter-spacing: ${toRem(1.15)};
      border-bottom: none;
      line-height: ${toRem(19)};
      background: none;
      .signoutLabel :hover {
        border-bottom: ${toRem(1)} solid ${baseVariables.color['alert40']};
      }
    }

    .separator {
      width: 100%;
      margin-block: inherit;
      margin-top: ${toRem(24)};
      @media ${baseVariables.mediaQuery.lg} {
        margin-top: ${toRem(28)};
      }
    }
  }
  .separator {
    color: ${baseVariables.color['neutral30']};
    border-bottom: 0;
  }
`;
