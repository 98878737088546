export const encryptPassword = async (password: string, publicKey?: string) => {
  if (!publicKey) {
    return password;
  }

  // substring off the PEM header/footer
  const key = publicKey.substring(26, publicKey.length - 24);
  // ascii string->binary string->array->arraybuffer
  const keyBuffer = Uint8Array.from(Array.from(window.atob(key)).map(c => c.charCodeAt(0)));
  // generate the key
  const encryptionKey = await window.crypto.subtle.importKey(
    'spki',
    keyBuffer,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    true,
    ['encrypt']
  );

  const encryptedPasswordEncoded = await crypto.subtle.encrypt(
    { name: 'RSA-OAEP' },
    encryptionKey,
    new TextEncoder().encode(password)
  );
  // arraybuffer->array->binary string->ascii string
  return window.btoa(String.fromCharCode(...new Uint8Array(encryptedPasswordEncoded)));
};
