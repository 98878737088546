import styled from 'styled-components';
import rtl from 'styled-components-rtl';
// import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledAutoSuggestionsDiv = styled.div`
  ${rtl`
  margin-top: ${toRem(16)};

  //using these custom classes for font as IconTextBlock is overriding t-font global style classes
  .text-primary-custom{
    font-size: ${toRem(14)};
    font-weight: 400;
    line-height: ${toRem(21)};
    letter-spacing: 0;
    text-align: left;
  }
  .text-secondary{
    font-size: ${toRem(12)};
    font-weight: 400;
    line-height: ${toRem(18)};
    letter-spacing: 0;
    text-align: left;
    color: ${baseVariables.color.neutral40};
  }
  .highlighted-item {
    background: ${baseVariables.color.neutral20};
    border-radius: ${toRem(8)};;
    & :hover{
      cursor:pointer;
    }
  }

  @media ${baseVariables.mediaQuery.lg} {
    margin-top: 0;

  }
  `}
`;
