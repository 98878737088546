/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC, KeyboardEvent, useRef, useEffect } from 'react';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { ChildrenAgeCollectionType, RoomsAndGuestsProps } from './RoomsAndGuests.types';
import { StyledRoomsAndGuests } from './RoomsAndGuests.styles';
import { StepperTextBlock, Types, DropDownModal, CustomSelectBlock, InputTextField } from '@marriott/mi-ui-library';

import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { useStaticDataContext } from '../../modules/context/StaticDataContext';
import {
  ROOMS_AND_GUESTS,
  FOCUS_INTERACTIONS,
  UNIT_OF_ROOM,
  ROOMS_AND_GUESTS_CLICK_TRACK,
  ROOMS_CLICK_TRACK,
  ADULTS_CLICK_TRACK,
  CHILD_CLICK_TRACK,
  CHILD_AGE_CLICK_TRACK,
} from '../../modules/store/store.constants';
import clsx from 'clsx';
import {
  LOCALES_FOR_ROOMS_AND_GUEST_DISPLAY_VALUE_CHANGES,
  searchFormClickTrackingLoc,
} from '../../constants/lib/constants';
import { capitalize } from '../../utils/src/helper';
import { getRoomRange } from '../../utils/src/utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

// Use named rather than default exports.
export const RoomsAndGuests: FC<RoomsAndGuestsProps> = ({
  onCancel,
  onChange: changeMobileState,
  isDesktopView,
  isAvailabilityScenario,
}) => {
  const { tags, size } = Types;

  const compRef = useRef<HTMLDivElement>(null);
  const roomsAndGuests = useSearchFormStore((state: any) => state.roomsAndGuests);
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);
  // const roomCountMapForJPLocale: Record<string, string> = {
  //   '4〜9': '4',
  //   '10〜25': '10',
  //   '26以上': '26',
  // };

  const {
    roomsAndGuestEybrowText,
    roomsAndGuestEyebrowText,
    rooms,
    room,
    guests,
    guest,
    roomListLabel,
    roomsAndGuestsResetCta,
    maxGuestPerRoom,
    adults,
    children,
    done,
    roomsAndGuestAdultHelperText,
    maxChildAgeLabel,
    ageBasedRatesLabel,
    add,
    remove,
    ageLabel,
    smallerThanOneLabel,
    isTabbedSearchForm,
    hideDestination,
    unitOfRoom = '',
    childAgeRequired = 'Child {x}: Age (Required)',
  } = useStaticDataContext();
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { ACCEPT_LANGUAGE: currentLocale } = clientEnvVars;

  const searchFormTopPosition = useSearchFormStore((state: any) => state?.searchFormTopPosition);
  const tempArr = roomListLabel?.split(', ');
  const roomListItems = tempArr?.map((item: string) => ({ roomQuantity: item }));
  const [popupOpenState, setPopupOpenState] = React.useState(false);

  const arrayInitializer = (length: number, key: string, incrementValue = 0): Array<Record<string, string>> =>
    Array.from({ length }, (_, i) => {
      return { [key]: `${i + incrementValue}` };
    });

  const roomsList = roomListItems as Array<{ roomQuantity: string }>;
  const roomsMinValue: { roomQuantity: string } = { ...roomsList?.[0] };
  const roomsMaxValue: { roomQuantity: string } = { ...roomsList?.[roomsList.length - 1] };

  const adultsList = arrayInitializer(+(maxGuestPerRoom as string), 'adultsQuantity', 1);
  const adultsMinValue = { ...adultsList[0] };

  const childrenList = arrayInitializer(+(maxGuestPerRoom as string), 'childrenQuantity');
  const childrenMinValue = { ...childrenList[0] };

  const childAgeList = arrayInitializer(+(maxChildAgeLabel as string) + 1, 'val', 0);
  childAgeList[0] = { val: smallerThanOneLabel };

  const childrenAgeMinValue = { ...childAgeList[0] };
  const childrenAgeMaxValue = { ...childAgeList[childAgeList.length - 1] };
  const totalGuests = +(maxGuestPerRoom as string);

  const roomsCountSaved = isAvailabilityScenario
    ? getRoomRange(roomsAndGuests?.numRooms, roomsList)
    : roomsAndGuests?.numRooms;

  const adultsCountSaved = roomsAndGuests?.numAdultsPerRoom;
  const childrenCountSaved = roomsAndGuests?.numChildrenPerRoom ?? 0;
  const childrenAgesSaved = roomsAndGuests?.childrenAges ?? [];

  // sometimes childrenAgesSaved is coming as "" causing component to break
  const childrenAgesCollectionSaved =
    typeof childrenAgesSaved === 'object'
      ? childrenAgesSaved?.map((val: string, index: any) => {
          //changed to less than label since 0 is not an option anymore
          const value =
            !isDesktopView && val !== smallerThanOneLabel
              ? val
              : val === smallerThanOneLabel
              ? smallerThanOneLabel
              : childAgeList[parseInt(val, 10)]?.['val']; //if val is less than label then donot parse it
          return { id: index, value, isValueSet: value !== smallerThanOneLabel };
        })
      : childrenAgesSaved?.split(',')?.map((val: string, index: any) => {
          let value =
            !isDesktopView && val !== smallerThanOneLabel
              ? val
              : val === smallerThanOneLabel
              ? smallerThanOneLabel
              : childAgeList[parseInt(val, 10)]?.['val'];
          if (!value || value === '0') {
            value = smallerThanOneLabel;
          }
          return { id: index, value, isValueSet: value !== smallerThanOneLabel };
        });

  // Initialize states as default values
  const [roomsCount, setRoomsCount] = React.useState<Record<string, string>>({
    roomQuantity: roomsAndGuests?.numRooms,
  });
  const [adultsCount, setAdultsCount] = React.useState<Record<string, string>>({
    adultsQuantity: roomsAndGuests?.numAdultsPerRoom,
  });
  const [childrenCount, setChildrenCount] = React.useState<Record<string, string>>({
    childrenQuantity: roomsAndGuests?.numChildrenPerRoom ?? '0',
  });
  const [childrenAgeCollection, setChildrenAgeCollection] = React.useState<Array<ChildrenAgeCollectionType>>(
    childrenAgesCollectionSaved ?? []
  );

  const bodyRef = useRef<HTMLBodyElement | null>(null);
  useEffect(() => {
    bodyRef.current = document.body as HTMLBodyElement;
  }, []);

  //once the store value changes , set states for each of them
  useEffect(() => {
    //before setting each state , check if the previous value is not equal to new value

    if (roomsCount['roomQuantity']?.toString() !== roomsCountSaved?.toString()) {
      setRoomsCount(roomsCountSaved ? { roomQuantity: roomsCountSaved?.toString() } : roomsMinValue);
    }
    if (adultsCount['adultsQuantity']?.toString() !== adultsCountSaved?.toString()) {
      setAdultsCount(adultsCountSaved ? { adultsQuantity: adultsCountSaved } : adultsMinValue);
    }
    if (childrenCount['childrenQuantity']?.toString() !== childrenCountSaved?.toString()) {
      setChildrenCount(childrenCountSaved ? { childrenQuantity: childrenCountSaved?.toString() } : childrenMinValue);
    }
    if (childrenAgeCollection?.length !== childrenAgesSaved?.length) {
      childrenAgeCollection?.forEach((item: any, index: number) => {
        if (item[index]?.toString() !== childrenAgesSaved[index]?.toString()) {
          return false;
        }
        return true;
      });
      setChildrenAgeCollection(childrenAgesCollectionSaved ? childrenAgesCollectionSaved : []);
    }
  }, [roomsAndGuests]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click target is not within the popup or the button or the icons
      if (
        !event.target.closest('.party-mix-dropdown-container') &&
        !event.target.closest('.party-mix-input-container') &&
        !event.target.closest('.party-mix-arrow-icon')
      ) {
        setPopupOpenState(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (popupOpenState) {
      setFocusOnFirstBtn();
    } else {
      if (isDesktopView) {
        applyBtnHandler();
      }
    }
  }, [popupOpenState, roomsCount]);

  //if user changes values using plus minus button , set store values
  useEffect(() => {
    setSearchFormState([ROOMS_AND_GUESTS], {
      [ROOMS_AND_GUESTS]: {
        numRooms: roomsCount['roomQuantity'],
        numAdultsPerRoom: parseInt(adultsCount['adultsQuantity'], 10),
        numChildrenPerRoom: parseInt(childrenCount['childrenQuantity'], 10),
        childrenAges: childrenAgeCollection?.map(obj => obj?.value),
      },
    });
    // }

    // when rooms and guests are changed update the values in formValues
  }, [roomsCount, adultsCount, childrenCount, childrenAgeCollection]);

  const setFocusOnFirstBtn = (): void => {
    const popupElemBtn = compRef.current?.querySelector('.rg-popup button') as HTMLElement;
    popupElemBtn?.focus();
  };

  //To update the children collection based on children row
  const updateChildrenCollection = (count: number): void => {
    if (childrenAgeCollection.length !== count) {
      const childrenAgeCollectionClone: Array<ChildrenAgeCollectionType> = childrenAgeCollection?.map(element => {
        return { ...element };
      });

      //To handle decrement button click
      if (childrenAgeCollectionClone.length > count) {
        childrenAgeCollectionClone.pop();
      }
      //To handle increment button click
      if (childrenAgeCollectionClone.length < count) {
        const childToAdd = {
          id: 0,
          value: smallerThanOneLabel, // set default value to -1 before user interaction
          isValueSet: false, // set this to true on first user interaction
        };
        if (childrenAgeCollectionClone.length > 0) {
          childToAdd.id = childrenAgeCollectionClone[childrenAgeCollectionClone.length - 1].id + 1;
        }
        childrenAgeCollectionClone.push(childToAdd);
      }
      setChildrenAgeCollection(childrenAgeCollectionClone);
    }
  };

  //To reset the values in all the rows
  const resetHandler = (e?: React.MouseEvent): void => {
    e?.preventDefault();
    setFocusOnFirstBtn();
    setRoomsCount(roomsMinValue);
    setAdultsCount(adultsMinValue);
    setChildrenCount(childrenMinValue);
    setChildrenAgeCollection([]);
  };

  //To handle rooms state count change
  const roomsChangeHandler = (type: string): void => {
    const i = roomsList.findIndex(ele => ele?.roomQuantity === roomsCount['roomQuantity']?.toString());

    if (type === 'inc') {
      setRoomsCount({ ...roomsList[i + 1] });
    } else {
      setRoomsCount({ ...roomsList[i - 1] });
    }
  };

  //To handle adults state count change
  const adultsChangeHandler = (type: string): void => {
    const i = adultsList.findIndex(ele => ele['adultsQuantity'] === adultsCount['adultsQuantity']?.toString());
    if (type === 'inc') {
      setAdultsCount({ ...adultsList[i + 1] });
    } else {
      setAdultsCount({ ...adultsList[i - 1] });
    }
  };

  //To handle children state count change
  const childrenChangeHandler = (type: string): void => {
    const i = childrenList.findIndex(ele => ele['childrenQuantity'] === childrenCount['childrenQuantity']?.toString());
    if (type === 'inc') {
      setChildrenCount({ ...childrenList[i + 1] });
      updateChildrenCollection(parseInt(childrenList[i + 1]['childrenQuantity'], 10));
    } else {
      setChildrenCount({ ...childrenList[i - 1] });
      updateChildrenCollection(parseInt(childrenList[i - 1]['childrenQuantity'], 10));
    }
  };

  //To handle each child state count change
  const childAgeChangeHandler = (index: number, type?: string, age?: number): void => {
    const childrenAgeCollectionClone: Array<ChildrenAgeCollectionType> = childrenAgeCollection?.map(element => {
      return { ...element };
    });
    // if value is not already set then set the value to minimum age value
    if (!childrenAgeCollectionClone[index]?.isValueSet) {
      childrenAgeCollectionClone[index].isValueSet = true;
      childrenAgeCollectionClone[index].value = childrenAgeMinValue['val'];
    }
    // } else
    if (childrenAgeCollectionClone[index]?.isValueSet) {
      const i = childAgeList.findIndex(ele => ele['val'] === childrenAgeCollectionClone[index].value);
      if (type) {
        if (type === 'inc') {
          childrenAgeCollectionClone[index].value = childAgeList[i + 1]['val'];
        } else {
          childrenAgeCollectionClone[index].value = childAgeList[i - 1]['val'];
        }
      }
    }
    if (typeof age !== 'undefined') {
      // store child's age in the clone array
      childrenAgeCollectionClone[index].value = childAgeList[age]['val'];
    }
    // update the chidlren age collection
    setChildrenAgeCollection(childrenAgeCollectionClone);
  };

  // To handle adults and following rows hide
  const checkHideRows = (): boolean => {
    if (roomsList?.findIndex(ele => ele.roomQuantity === roomsCount['roomQuantity']) >= 4) {
      return false;
    }
    return true;
  };

  // to show popup on desktop on button click
  const showPopup = (): void => {
    setPopupOpenState(true);
    !isDesktopView && bodyRef.current && (bodyRef.current.style.overflow = 'hidden'); // Disable scrolling on the background
    setSearchFormState([FOCUS_INTERACTIONS], {
      [FOCUS_INTERACTIONS]: {
        isUserInteractWithComp: true,
      },
    });
  };

  // function to handle the casing for guest display value
  const getLocalizedGuestsDisplayValue = (guestText: string) => {
    if (LOCALES_FOR_ROOMS_AND_GUEST_DISPLAY_VALUE_CHANGES.includes(currentLocale?.toLowerCase() as string)) {
      return guestText;
    } else {
      return capitalize(guestText);
    }
  };

  // function to handle the casing for room display value
  const getLocalizedRoomsDisplayValue = (roomsText: string) => {
    if (LOCALES_FOR_ROOMS_AND_GUEST_DISPLAY_VALUE_CHANGES.includes(currentLocale?.toLowerCase() as string)) {
      return roomsText.toLowerCase();
    } else {
      return roomsText;
    }
  };

  /**
   * returns the string format of rooms and guest count for display
   * @param roomsCount
   * @param adultsCount
   * @param childrenCount
   */
  const getRoomsAndGuestCount = (roomsCount: string, adultsCount: number, childrenCount: number): string => {
    const guestCount = adultsCount + childrenCount;
    const guestText = guestCount > 1 ? guests : guest;
    const guestsDisplayValue = guestText?.includes('{x}')
      ? guestText.replace('{x}', guestCount.toString())
      : `${guestCount} ${getLocalizedGuestsDisplayValue(guestText)}`;
    const roomsText = roomsCount.toString() !== '1' ? rooms : room;

    // CNWEB-2606 `unitOfRoom` is only applicable for certain locales like CN
    // For CN pages where it's not applicable, it returns the constant `UNIT_OF_ROOM`
    const unit = unitOfRoom !== UNIT_OF_ROOM ? unitOfRoom : '';
    const roomsDisplayValue = roomsText?.includes('{x}')
      ? roomsText.replace('{x}', roomsCount.toString())
      : `${roomsCount} ${unit}${getLocalizedRoomsDisplayValue(roomsText)}`;

    if (checkHideRows()) {
      return `${roomsDisplayValue}, ${guestsDisplayValue}`;
    } else {
      return `${roomsDisplayValue}`;
    }
  };

  // To check total guests count (adults + children)
  const totalGuestsCheck = (): boolean => {
    return parseInt(adultsCount['adultsQuantity'], 10) + parseInt(childrenCount['childrenQuantity'], 10) >= totalGuests;
  };
  // To hide reset handler when initial values are set
  const resetHideCheck = (): boolean => {
    return (
      roomsCount['roomQuantity']?.toString() !== roomsMinValue.roomQuantity ||
      adultsCount['adultsQuantity']?.toString() !== adultsMinValue['adultsQuantity'] ||
      childrenCount['childrenQuantity']?.toString() !== childrenMinValue['childrenQuantity']
    );
  };

  const applyBtnHandler = (): void => {
    setSearchFormState([ROOMS_AND_GUESTS], {
      [ROOMS_AND_GUESTS]: {
        numRooms: roomsCount['roomQuantity'],
        numAdultsPerRoom: parseInt(adultsCount['adultsQuantity'], 10),
        numChildrenPerRoom: parseInt(childrenCount['childrenQuantity'], 10),
        childrenAges: childrenAgeCollection?.map(obj => obj?.value),
      },
    });
    // when Apply button is clicked, update mobile state
    // TODO:
    if (changeMobileState) {
      changeMobileState();
    }
  };

  const formatChildAge = (childAgeReq: string, index: number) => {
    //TODO:
    return childAgeReq.replace('{x}', (index + 1)?.toString()).replace('(Required)', '');
  };
  const renderChildAges = () => {
    return (
      <div className="child-collection">
        {childrenAgeCollection?.map((element: ChildrenAgeCollectionType, index: number) => (
          <StepperTextBlock
            stepperValue={element.value}
            handleDecrement={(): void => childAgeChangeHandler(index, 'dec')}
            handleIncrement={(): void => childAgeChangeHandler(index, 'inc')}
            copyText={formatChildAge(childAgeRequired, index)}
            elementType={Types.tags.div}
            headingFontSize={Types.size.small}
            disableIncrement={childrenAgeMaxValue['val'] === element.value}
            disableDecrement={!element.isValueSet || childrenAgeMinValue['val'] === element.value}
            increamentLabel={element.isValueSet ? `${add} ${element.value}` : `${add} ${ageLabel}`}
            decreamentLabel={element.isValueSet ? `${remove} ${element.value}` : `${remove} ${ageLabel}`}
            clickTrackloc={CHILD_AGE_CLICK_TRACK}
          />
        ))}
      </div>
    );
  };

  const renderStepperOptions = () => {
    const handleResetKeyDown = (e: KeyboardEvent<HTMLElement>) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        resetHandler();
        setPopupOpenState(false);
      }
    };
    return (
      <div className={isDesktopView ? `px-3 pb-3` : ''}>
        <div className="stepper-text-contianer">
          <StepperTextBlock
            stepperValue={roomsCount['roomQuantity']}
            handleDecrement={(): void => roomsChangeHandler('dec')}
            handleIncrement={(): void => roomsChangeHandler('inc')}
            copyText={rooms.includes('{x}') ? rooms.split('{x}')[0].trim() : rooms}
            elementType={Types.tags.div}
            headingFontSize={Types.size.small}
            disableIncrement={roomsMaxValue.roomQuantity === roomsCount['roomQuantity']?.toString()}
            disableDecrement={roomsMinValue.roomQuantity === roomsCount['roomQuantity']?.toString()}
            increamentLabel={`${add} ${rooms}`}
            decreamentLabel={`${remove} ${rooms}`}
            clickTrackloc={ROOMS_CLICK_TRACK}
          />
        </div>
        {checkHideRows() && (
          <>
            <div className="stepper-text-contianer">
              <StepperTextBlock
                stepperValue={adultsCount['adultsQuantity']}
                handleDecrement={(): void => adultsChangeHandler('dec')}
                handleIncrement={(): void => adultsChangeHandler('inc')}
                copyText={adults}
                elementType={Types.tags.div}
                subHeadingType={tags.div}
                subHeadingText={roomsAndGuestAdultHelperText?.replace('{x}', maxGuestPerRoom)}
                subHeadingFontSize={size.extraSmall}
                headingFontSize={Types.size.small}
                disableIncrement={totalGuestsCheck()}
                disableDecrement={adultsMinValue['adultsQuantity'] === adultsCount['adultsQuantity']?.toString()}
                increamentLabel={`${add} ${adults}`}
                decreamentLabel={`${remove} ${adults}`}
                clickTrackloc={ADULTS_CLICK_TRACK}
              />
            </div>
            <div className={isDesktopView ? 'stepper-text-contianer' : ''}>
              <StepperTextBlock
                stepperValue={childrenCount['childrenQuantity']}
                handleDecrement={(): void => childrenChangeHandler('dec')}
                handleIncrement={(): void => childrenChangeHandler('inc')}
                copyText={children}
                elementType={Types.tags.div}
                subHeadingType={tags.div}
                subHeadingText={roomsAndGuestAdultHelperText?.replace('{x}', maxGuestPerRoom)}
                helperText={ageBasedRatesLabel}
                subHeadingFontSize={size.extraSmall}
                headingFontSize={Types.size.small}
                disableIncrement={totalGuestsCheck()}
                disableDecrement={
                  childrenMinValue['childrenQuantity'] === childrenCount['childrenQuantity']?.toString()
                }
                increamentLabel={`${add} ${children}`}
                decreamentLabel={`${remove} ${children}`}
                clickTrackloc={CHILD_CLICK_TRACK}
              />
              {childrenCount['childrenQuantity'] ? renderChildAges() : ''}
            </div>
          </>
        )}
        {isDesktopView && resetHideCheck() && (
          <div className="text-right pt-3">
            <a
              href="#"
              className="m-link-action custom_click_track"
              onClick={resetHandler}
              title={roomsAndGuestsResetCta}
              data-testid={roomsAndGuestsResetCta}
              onKeyDown={handleResetKeyDown}
              custom_click_track_value={ROOMS_AND_GUESTS_CLICK_TRACK}
            >
              {roomsAndGuestsResetCta}
            </a>
          </div>
        )}
      </div>
    );
  };
  let blurTimeout: string | number | NodeJS.Timeout | undefined; // a timeout function to handle blur after specific amount of time
  const handleIconPopUpClick = (event: KeyboardEvent<Element>): void => {
    event.preventDefault(); // preventing default action so that on focus event does not interfer with on click event
    clearTimeout(blurTimeout); // clearing timeout
    setPopupOpenState(!popupOpenState);
    //capture user interactions
    setSearchFormState([FOCUS_INTERACTIONS], { [FOCUS_INTERACTIONS]: { isUserInteractWithComp: true } });
  };
  const inputRef = useRef<HTMLInputElement>(null); // ref to point parent div of input element

  // Function to close dropdown when focus leaves both input and dropdown
  const handleBlur = (event: any) => {
    const relatedTarget = event.relatedTarget;
    if (relatedTarget && !inputRef?.current?.contains(relatedTarget) && !compRef.current?.contains(relatedTarget)) {
      setPopupOpenState(false);
    }
  };
  return (
    <StyledRoomsAndGuests
      data-component-name="m-shop-RoomsAndGuests"
      data-testid="shop-RoomsAndGuests"
      $isTabbedSearchForm={isTabbedSearchForm === true ? true : false}
      // data-testid="rooms-and-guests"
      onClick={() => {
        !isDesktopView && !popupOpenState && showPopup();
      }}
      ref={inputRef}
    >
      {isTabbedSearchForm || hideDestination ? (
        <InputTextField
          custom_click_track_value={`${searchFormClickTrackingLoc} | Rooms and Guests |internal`}
          handleKeyDown={() => {
            setPopupOpenState(!popupOpenState);
          }}
          onBlur={handleBlur}
          className={'m-input-text-field party-mix-input-container'} // these 2 class names are mandatory , please do not remove
          label={roomsAndGuestEyebrowText}
          inputValue={getRoomsAndGuestCount(
            roomsCount['roomQuantity'],
            +adultsCount['adultsQuantity'],
            +childrenCount['childrenQuantity']
          )}
          inputTextFieldClassName={isTabbedSearchForm ? 'rooms-guests-m-font' : ''}
          showIcon={true}
          isClickEnabled={isTabbedSearchForm && true}
          iconClass={isTabbedSearchForm && popupOpenState ? 'icon-arrow-up' : 'icon-arrow-down'}
          iconOnClick={handleIconPopUpClick}
          trailingIconProps={{
            className: clsx('trailing-element', 'party-mix-arrow-icon'), // these 2 class names are mandatory , please do not remove
            onKeyDown: (e: KeyboardEvent) => {
              if (e.key === 'Enter' || e.code === '13') {
                handleIconPopUpClick(e);
              }
            },
          }}
          getInputProps={() => ({
            readOnly: true,
          })}
          variation={isTabbedSearchForm ? 'default' : 'line-type'}
          withinModal={!isDesktopView}
          showUnderline={!isDesktopView}
          iconAriaLabel={popupOpenState ? 'close' : 'open'}
        ></InputTextField>
      ) : isDesktopView ? (
        <CustomSelectBlock
          customClickTrackValue={`${searchFormClickTrackingLoc} | Rooms and Guests |internal`}
          selectTitle={''}
          selectDescription={getRoomsAndGuestCount(
            roomsCount['roomQuantity'],
            +adultsCount['adultsQuantity'],
            +childrenCount['childrenQuantity']
          )}
          handleClick={showPopup}
          name={roomsAndGuestEybrowText}
          id={roomsAndGuestEybrowText}
          isExpanded={popupOpenState}
          customButtonClass="t-font-weight-m t-font-xs"
        />
      ) : (
        <InputTextField
          handleKeyDown={() => {
            setPopupOpenState(!popupOpenState);
          }}
          isClickEnabled={true}
          custom_click_track_value={`${searchFormClickTrackingLoc} | Rooms and Guests |internal`}
          label={roomsAndGuestEyebrowText}
          inputValue={getRoomsAndGuestCount(
            roomsCount['roomQuantity'],
            +adultsCount['adultsQuantity'],
            +childrenCount['childrenQuantity']
          )}
          showIcon={true}
          iconClass="icon-arrow-right"
          iconOnClick={showPopup}
          getInputProps={() => ({
            readOnly: true,
          })}
          variation="line-type"
          withinModal={!isDesktopView}
          showUnderline={!isDesktopView}
          trailingIconProps={{
            'aria-expanded': popupOpenState,
            onKeyDown: (e: KeyboardEvent) => {
              if (e.key === 'Enter' || e.code === '13') {
                handleIconPopUpClick(e);
              }
            },
          }}
        ></InputTextField>
      )}
      <div ref={compRef}>
        {popupOpenState && (
          <DropDownModal
            role="dialog"
            shouldAutoFocusOnPopup={true}
            isAutoScrollEnabled={true}
            parentElementTopValue={searchFormTopPosition?.value}
            applyDefaultHeight={isTabbedSearchForm === true ? true : false}
            children={renderStepperOptions()}
            className={clsx(
              'dropdown-container',
              isTabbedSearchForm && 'rooms-guests-dropdown-container',
              isDesktopView && isTabbedSearchForm ? 'tabbed-form-dropdown-container' : '',
              'party-mix-dropdown-container' // this class name is mandatory , please do not remove
            )}
            childrenClassName={clsx('room-guest-dropdown')}
            show={popupOpenState}
            clearButtonLabel={resetHideCheck() ? roomsAndGuestsResetCta : ''}
            applyLabel={done}
            mobileModalHeading={roomsAndGuestEyebrowText}
            dropdownModalCloseClassName={'rooms-guests-close'}
            clickTrackingLoc={'Rooms & Guests Dropdown'}
            labelledBy={'dropdown-modal-container'}
            mobileTakeOver={!isDesktopView ? true : false}
            handleBlur={true}
            dropDownSpecificFlag={true}
            mobileHeaderEnabled={true}
            mobileFooterEnabled={true}
            scrollDownModalCloseState={true}
            setDropdownModalOpenState={setPopupOpenState}
            dropdownModalOpenState={popupOpenState}
            callhandleBlur={handleBlur}
            dropdownModalOnCLoseFunc={() => {
              setTimeout(() => {
                //using use timeout as ADA is not working without it
                !isDesktopView && isTabbedSearchForm && bodyRef.current && (bodyRef.current.style.overflow = 'auto'); // Disable scrolling on the background
                onCancel?.();
                setPopupOpenState(false);
              }, 100);
            }}
            clearClickHandler={
              resetHideCheck()
                ? resetHandler
                : (): void => {
                    return;
                  }
            }
          />
        )}
      </div>
    </StyledRoomsAndGuests>
  );
};
