/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

import { MapTo, Container } from '@adobe/aem-react-editable-components';
import { respGridUtil } from '@marriott/mi-headless-utils';

const ExperienceFragmentVariationConfig = {
  emptyLabel: 'Experience Fragment',

  isEmpty: function (props) {
    return !props || !props.configured;
  },
};

MapTo(`mi-aem-common-spa/components/experiencefragment`)(props => {
  return (
    <Container
      isInEditor
      {...props}
      isPage={false}
      model={respGridUtil(props?.model?.cqItems?.root?.[':items']?.responsivegrid)}
    />
  );
}, ExperienceFragmentVariationConfig);
