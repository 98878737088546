export default {
  MAX_OFFERS_COUNT: '10',
  IMAGE_CACHE_DOMAIN: 'https://cache.marriott.com',
  GRAPHQL_CLIENT_NAME: 'phoenix_offers',
  GRAPHQL_CLIENT_VERSION: 'v1',
  GRAPHQL_QUERY_OPERATION_NAME: 'phoenixOffersCarousel',
  GRAPHQL_QUERY_FALLBACK_OPERATION_NAME: 'phoenixOffersFallbackOffers',
  OFFERS_REQUEST_ID: 'offerSearch~X~invalidjson232',
  OFFERS_CAROUSEL_SIGNATURE:
    process.env['OFFERS_CAROUSEL_SIGNATURE'] ?? '447ef1a279319922e34f8992e6f7e89635940464e367136c39b78b3aa297bc54',
  OFFERS_FALLBACK_OFFERS_SIGNATURE:
    process.env['OFFERS_FALLBACK_OFFERS_SIGNATURE'] ??
    '2dfd2dd3f3d4da21fbec5670325c64dd32f1e1cd7e937c83e82864730ecbcb85',
};
