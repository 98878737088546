const LOCALE_ZH_CN = 'zh-CN';
const LOCALE_JP = 'ja-JP';
const LOCALE_KO = 'ko-KR';

/**
 * isCNLocale Used to determine if it is CN page
 * @param currentLocale
 * @returns boolean
 */
export const isCNLocale = (currentLocale: string | undefined): boolean => {
  const locale = currentLocale?.replace('_', '-');
  return locale?.toLowerCase() === LOCALE_ZH_CN.toLowerCase();
};

/**
 * Checks if the provided locale is Japanese.
 *
 * @param {string} [locale] - The locale string to check. If no locale is provided, it defaults to `undefined`.
 * @returns {boolean} - Returns `true` if the locale is Japanese, otherwise returns `false`.
 */
export const isJPLocale = (locale?: string): boolean => {
  return locale === LOCALE_JP;
};

/**
 * Checks if the provided locale is Korean.
 *
 * @param {string} [locale] - The locale string to check. If no locale is provided, it defaults to `undefined`.
 * @returns {boolean} - Returns `true` if the locale is Korean, otherwise returns `false`.
 */
export const isKOLocale = (locale?: string): boolean => {
  return locale === LOCALE_KO;
};

/**
 * Checks if the provided locale matches Japanese, Korean, or Chinese locales.
 *
 * @param {string} [locale] - The locale string to be checked. It can be `undefined` or a string value.
 * @returns {boolean} - Returns `true` if the locale matches Japanese, Korean, or Chinese locales. Otherwise, returns `false`.
 */
export const isJpKoCnLocale = (locale?: string): boolean =>
  isJPLocale(locale) || isKOLocale(locale) || isCNLocale(locale);
