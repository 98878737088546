export const DESTINATION = 'destination';
export const MEETINGS_DESTINATION = 'meetingsDestination';
export const SELECTED_TAB = 'selectedTab';
export const TABBED_SEARCHFORM_CONTAINER = 'tabbedSearchFormContainer';
export const ROOMS_AND_GUESTS = 'roomsAndGuests';
export const DATES = 'dates';
export const MEETINGS_DATES = 'eventsDates';
export const SPECIAL_RATES = 'specialRates';
export const USE_POINTS = 'usePoints';
export const BRANDS = 'brands';
export const RESET = 'reset';
export const DESTINATION_HIDDEN_FIELDS = 'destinationHiddenFields';
export const EVENTS_DESTINATION_HIDDEN_FIELDS = 'meetingsDestinationHiddenFields';
export const LATITUDE_AND_LONGITUDE = 'latitudeAndLongitude';
export const FOCUS_INTERACTIONS = 'focusInteractions';
export const childAgeRequired = 'Child {x}: Age (Required)';
export const maxGuestPerRoom = '8';
export const MILESTOMETERS = 1609.344;
export const RESORTS_HIDDEN_FIELDS = 'resortsHiddenFields';
export const CORP = 'corp';
export const ALERT_BANNER_H = 16;
export const GUEST_ROOMS = 'guestRooms';
export const EVENT_SPACE = 'eventSpace';
export const EVENT_PURPOSE = 'eventPurpose';
export const UNIT_OF_ROOM = 'unitOfRoom';
export const ERROR_MESSAGE = 'errorMessage';
export const EVENTS_ERROR_MESSAGE = 'meetingsErrorMessage';
export const ROOMS_AND_GUESTS_INC_CLICK_TRACK = 'Search form | Room and Guest';
export const ROOMS_AND_GUESTS_CLICK_TRACK = 'Search Form | Room and Guest reset button | internal';
export const SPECIAL_RATES_DONE_CLICK_TRACK = 'Search Form | Special Rates done button | internal';
export const SPECIAL_RATES_CLOSE_CLICK_TRACK = 'Search Form | CORP code clear button | internal';
export const ROOMS_CLICK_TRACK = 'Search form | Rooms';
export const ADULTS_CLICK_TRACK = 'Search form | Adults';
export const CHILD_CLICK_TRACK = 'Search form | Children';
export const CHILD_AGE_CLICK_TRACK = 'Search form | Children age';
