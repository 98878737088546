import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledOfferCarouselContainer, StyledOfferCardsDivPreviewMode } from './OfferCardCaousel.styles';
import { OfferCardsProps } from './OfferCardCarousel.types';
import { CardCarousel } from './CardCarousel';

export const OfferCardCarouselWrapperConfig = {
  emptyLabel: 'OfferCardCarousel',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offers-cards`,
};

export const OfferCardCarouselWrapper: React.FC<OfferCardsProps> = props => {
  return <CardCarousel {...props} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OfferCardCarousel = (props: any) => (
  <div>
    <EditableComponent config={OfferCardCarouselWrapperConfig} {...props}>
      {props?.offersData?.isPreview === 'true' ? (
        <StyledOfferCardsDivPreviewMode data-component-name="o-offers-cards" data-testid="offers-cards">
          <OfferCardCarouselWrapper {...props} />
        </StyledOfferCardsDivPreviewMode>
      ) : (
        <StyledOfferCarouselContainer data-component-name="o-offers-cards" data-testid="offers-cards">
          <OfferCardCarouselWrapper {...props} />
        </StyledOfferCarouselContainer>
      )}
    </EditableComponent>
  </div>
);
