import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '../../styles';
import { StyledDropdownModalProps } from './DropdownModal.types';

export const StyledDropdownContainerDiv = styled.div<StyledDropdownModalProps>`
  ${rtl`
    ${(props: StyledDropdownModalProps): string =>
      !props.show
        ? `display:none;`
        : `
        .dropdown-to-modal {
          max-height: calc(100% - ${props.calculatedTop}px);
          position: fixed;
          overflow: visible;
          width: 100%;
          z-index:1012;
          top: auto;
          left:0;
          right:0;
          bottom:0;
          height: auto;
          .m-dropdown-content {
            height: 80vh;
            max-height: 90vh;
            @media only ${baseVariables.mediaQuery.lg}{
              min-height:auto;
              max-height:100vh;
              height:auto;
            }
          }
          .m-dropdown-action-bar {
            position: absolute;
            background:none;
            padding-bottom:${toRem(24)};
            bottom: 0;
            @media only ${baseVariables.mediaQuery.lg} {
              position: relative;
            }
          }
          @media only ${baseVariables.mediaQuery.lg} {
            position: static;
          }
        }
        .dropdown-to-modal-background {
          position: fixed;
          width: 100%;
          z-index:1011;
          top: 0;
          left:0;
          right:0;
          bottom:0;
          height: auto;
          background:rgba(0,0,0,0.5);
        }
        .m-dropdown-container{
          border: 0 !important;
        }
    `}
  `}
`;

export const StyledDropdownContentDiv = styled.div`
  ${rtl`
      z-index: 1;
      position: relative;
      border-radius: ${toRem(14)};
      padding: 0 !important;
      .clear{
        outline:revert;
      }
      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border-radius: ${toRem(9999)};
        background-color: ${baseVariables.color.alert20};
      }

      ::-webkit-scrollbar {
        width: ${toRem(14)};
      }
      ::-webkit-scrollbar-track {
        background-color:transparent;
        @media only ${baseVariables.mediaQuery.desktop} {
          background-color:${baseVariables.color.alert10};
        }
      }

      .m-dropdown-header {
        // border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};
        padding-bottom: ${toRem(32)};
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        .m-icon-button-secondary{
          padding: 0;
          &:focus,&:hover{
            background:none;
            color:${baseVariables.color.base10};
          }
        }
        @media ${baseVariables.mediaQuery.desktop}{
          padding: ${toRem(16)} ${toRem(24)};
        }
    
      }

      .m-dropdown-children {
        overflow: hidden;
        overflow-y: auto;
      }
  `}
`;
