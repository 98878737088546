// Imports for external libraries go here.
import React, { FC } from 'react';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Icon } from '@marriott/mi-ui-library';
import { NavigationUtilityLinkProps, RestPropType } from './NavigationUtilityLink.types';
import { StyledNavigationUtilityLink } from './NavigationUtilityLink.styles';
import { SignInDrawerWrapper } from '../../SignInDrawer/SignInDrawerWrapper';
import { useUserDetailsStore } from '@marriott/mi-store-utils';
import { LanguageSelectorWrapper } from '../LanguageSelector/LanguageSelectorWrapper';
import { getClickTrackValue } from '../../../utils/TrackingPropertyUtils';
import { DEFAULT_TRACKING_VALUES } from '../../../utils/constants';

export const NavigationUtilityLink: FC<NavigationUtilityLinkProps> = props => {
  const {
    utilityNavigation,
    className,
    isRenderedInsideOverlay,
    logoUrl = '',
    logoImageAltText = '',
    logoType = '',
    cqPath,
    isAuthorMode,
  } = props;
  const { ':itemsOrder': itemsOrder, ':items': items } = utilityNavigation;
  const { userProfileData, isSignedInUser } = useUserDetailsStore(state => ({
    userProfileData: state.userProfileData,
    isSignedInUser: state.isSignedInUser,
  }));
  const myTripsCount = isSignedInUser ? `(${userProfileData['trips']?.['upcomingReservationsCount']})` : '';

  const getIconClass = (iconClassTag: string): string => {
    const lastIndex = iconClassTag.lastIndexOf('/');
    if (lastIndex !== -1) {
      return iconClassTag.substring(lastIndex + 1);
    }
    return iconClassTag;
  };

  const renderUtilityLink = (
    restProps: RestPropType,
    defaultIconClass: string,
    buttonText?: string,
    buttonType?: string
  ) => {
    const {
      labelTag: iconClassTag,
      'cq:panelUrl': href,
      openInaNewTab,
      'cq:panelTitle': linkText,
      labelName: ariaLabel,
      trackingContentPosition,
    } = restProps;

    let clickTrackValue;

    if (defaultIconClass === 'help') {
      clickTrackValue = getClickTrackValue({
        trackingProperties: {
          trackingContentPosition,
        },
        url: href,
        fallbacks: {
          description: linkText || buttonText || DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
          position: DEFAULT_TRACKING_VALUES.GLOBAL_NAV,
        },
      });
    }

    //TODO: Keeping the fallback class as globe as of now as currentlty no option from AEM to author icon. Later should be changed.
    const iconClass = getIconClass(iconClassTag || defaultIconClass);
    const target = openInaNewTab === 'true' ? '_blank' : '_self';
    const rel = openInaNewTab === 'true' ? 'noopener noreferrer' : undefined;
    return (
      <>
        <Icon
          iconClass={`icon-${iconClass} utility-icon custom_click_track`}
          ariaLabel={ariaLabel}
          iconHref={href}
          target={target}
          rel={rel}
          custom_click_track_value={clickTrackValue}
          enableLinkClsName
        >
          {href && (
            <span
              aria-hidden="true"
              className={clsx('utility-link-text', {
                'd-none d-xl-inline-block': !isRenderedInsideOverlay,
              })}
            >
              {linkText || buttonText}
            </span>
          )}
        </Icon>
        {!href && (
          <span
            aria-hidden="true"
            className={clsx('utility-link-text', {
              'd-none d-xl-inline-block': !isRenderedInsideOverlay,
              'd-lg-inline-block': buttonType === 'signin',
            })}
          >
            {linkText || buttonText}
          </span>
        )}
      </>
    );
  };

  return (
    <StyledNavigationUtilityLink
      className={clsx(className, 'pr-3 pr-md-4 pr-xl-0', { 'align-items-baseline': isAuthorMode })}
      isRenderedInsideOverLay={isRenderedInsideOverlay}
    >
      {itemsOrder.map((key: string) => {
        const utilityNavigationItem = items[key];

        const { ':type': type, ...restProps } = utilityNavigationItem;
        const trackingContentPosition = items?.['trackingProperties']?.trackingContentPosition;

        switch (type) {
          case 'mi-aem-homepage-spa/components/content/navigation-link':
            return (
              <li
                key={restProps['cq:panelTitle']}
                className={clsx(
                  'utility-item',
                  'utility-link',
                  { 't-label-inverse-xs d-none d-lg-block': !isRenderedInsideOverlay },
                  { 't-label-alt-xs': isRenderedInsideOverlay }
                )}
              >
                {renderUtilityLink({ trackingContentPosition, ...restProps }, 'help')}
              </li>
            ); //later a seperate component for utility link can be created: <UtilityLink {...restProps} />; //Logic for Links such as help, covid-19

          case 'mi-aem-homepage-spa/components/content/language-selector':
            return (
              <li
                key={restProps['cq:panelTitle'] || restProps?.resourceProperties['cq:panelTitle']}
                className={clsx(
                  'utility-item',
                  'utility-language-selector',
                  { 't-label-inverse-xs d-none d-lg-block': !isRenderedInsideOverlay },
                  { 't-label-alt-xs': isRenderedInsideOverlay }
                )}
              >
                <LanguageSelectorWrapper
                  restProps={{ trackingContentPosition, ...restProps }}
                  renderUtilityLink={renderUtilityLink}
                  buttonClassName="t-label-inverse-xs"
                  cqPath={`${cqPath}/${key}`}
                  isAuthorMode={isAuthorMode}
                />
              </li>
            );

          case 'mi-aem-homepage-spa/components/content/mytrips':
            return !isRenderedInsideOverlay ? (
              <li key={restProps['cq:panelTitle']} className={clsx('utility-item', 'utility-trips')}>
                <button
                  className="utility-mytrips-button t-label-inverse-xs custom_click_track"
                  data-custom_click_track_value={getClickTrackValue({
                    trackingProperties: {
                      trackingContentPosition,
                    },
                    url: restProps['cq:panelUrl'],
                    fallbacks: {
                      description: restProps['cq:panelTitle'],
                      position: DEFAULT_TRACKING_VALUES.GLOBAL_NAV,
                    },
                  })}
                >
                  {renderUtilityLink(restProps, 'nav---trips')}
                  {!!myTripsCount && (
                    <span className="utility-link-text pl-1 d-none d-xl-inline-block">{myTripsCount}</span>
                  )}
                </button>
              </li>
            ) : null; //later a seperate component for mytrips can be created: <MyTrips {...restProps} />; //Logic for Trips

          case 'mi-aem-homepage-spa/components/content/signin':
            return !isRenderedInsideOverlay ? (
              <li key={'signinbutton'} className={clsx('utility-item', 'utility-sigin')}>
                <SignInDrawerWrapper
                  logoUrl={logoUrl}
                  logoImageAltText={logoImageAltText}
                  logoType={logoType}
                  restProps={restProps}
                  renderUtilityLink={renderUtilityLink}
                  cqPath={`${cqPath}/${key}`}
                  isAuthorMode={isAuthorMode}
                />
              </li>
            ) : null;

          default:
            return null;
        }
      })}
    </StyledNavigationUtilityLink>
  );
};
