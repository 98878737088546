import { FC } from 'react';
import clsx from 'clsx';
import DOMPurify from 'isomorphic-dompurify';

import { Link } from '@marriott/mi-ui-library';

import { ItemsModel } from './Footer.types';
import { LinkWrapper } from './Footer.styles';
import { parseBoolean } from './helper';
import { getClickTrackValue, ClickTrackingProps } from '../../utils/TrackingPropertyUtils';

/**
 * This component accounts for the potential to add not only links,
 * but also icons and templated HTML tags to the link lists
 * in the footer component.
 *
 * Destination links are sorted by label, so they should not
 * be made to consume this component, since this component
 * handles some cases where there is no link or link label.
 */
export const LinkFeature: FC<{ item: ItemsModel; trackingPropertiesObject: ClickTrackingProps }> = ({
  item,
  trackingPropertiesObject,
}) => {
  const hasLink = item.linkLabel && item.linkURL;
  const hasHTML = item.htmlContent;
  if (hasHTML && !hasLink) {
    // If there is htmlContent only, render htmlContent.
    return (
      <LinkWrapper>
        {hasHTML && (
          <span
            className={clsx('html-content')}
            dangerouslySetInnerHTML={{
              __html: (DOMPurify.sanitize(item.htmlContent as string, { RETURN_DOM: true }) as HTMLElement).outerHTML,
            }}
          />
        )}
      </LinkWrapper>
    );
  } else if (hasLink && hasHTML) {
    // Link AND HTML, render both, with htmlContent inside the link.
    return (
      <LinkWrapper>
        <Link
          target={parseBoolean(item.openInaNewTab) ? '_blank' : '_self'}
          linkHref={item.linkURL ?? ''}
          linkClassName="t-font-alt-s"
          custom_click_track_value={getClickTrackValue(trackingPropertiesObject, item.linkLabel)}
          trackingProperties={trackingPropertiesObject.trackingProperties}
        >
          <span
            className="html-content"
            dangerouslySetInnerHTML={{
              __html: (DOMPurify.sanitize(item.htmlContent as string, { RETURN_DOM: true }) as HTMLElement).outerHTML,
            }}
          ></span>{' '}
          <span>{item.linkLabel}</span>
        </Link>
      </LinkWrapper>
    );
  } else if (hasLink && !hasHTML) {
    // Link and no HTML, render a regular old link.
    return (
      <LinkWrapper>
        <Link
          target={parseBoolean(item.openInaNewTab) ? '_blank' : '_self'}
          linkHref={item.linkURL ?? ''}
          text={item.linkLabel}
          linkClassName="t-font-alt-s"
          custom_click_track_value={getClickTrackValue(trackingPropertiesObject, item.linkLabel)}
          trackingProperties={trackingPropertiesObject.trackingProperties}
        />
      </LinkWrapper>
    );
  } else {
    return null;
  }
};
