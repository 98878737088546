import dynamic from 'next/dynamic';

export * from './organisms/MerchandisingHeroBanner';
export const MerchandisingSmallBanner = dynamic(() =>
  import('./organisms/MerchandisingSmallBanner').then(mod => mod.MerchandisingSmallBanner)
);

/**
 * this is non editable component, and will use in shop - AEM author
 */
export const MerchandisingSmallBannerComp = dynamic(() =>
  import('./organisms/MerchandisingSmallBanner').then(mod => mod.MerchandisingSmallBannerComponent)
);
