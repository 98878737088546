// Imports for external libraries go here.
import React, { FC } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// The closer the import is to the file the lower it should be in this list.
import { StyledSubNavigation, StyledCardArticle } from './SubNavigation.styles';
import { IconBlock, Link } from '@marriott/mi-ui-library';
import { Ref } from 'react';
import { MAX_ITEMS_IN_A_COLUMN, MegaMenuItem, SubNavigationProps } from './SubNavigation.types';
import { getClickTrackValue } from '../../../utils/TrackingPropertyUtils';
import { DEFAULT_TRACKING_VALUES } from '../../../utils/constants';

export const SubNavigation: FC<SubNavigationProps> = props => {
  const {
    subNavItems,
    isArticleCardEnabled,
    i18nOpensInNewTab,
    cardArticleData,
    maxItemsPerColumn = MAX_ITEMS_IN_A_COLUMN,
    dynamicData,
    forwardedRefs,
    primaryParentLabel = DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
  } = props;

  const subNavItemsLength: number = subNavItems?.length || 0;

  return (
    <StyledSubNavigation
      subNavLength={subNavItemsLength}
      maxItemsPerColumn={maxItemsPerColumn}
      data-testid="sub-navigation-item"
      className="sub-nav__nav__container"
    >
      <ul className="sub-navigation-list" role="list" aria-label="Sub Navigation List">
        {subNavItems?.map((item: MegaMenuItem, index: number) => (
          <li
            className={`list-item t-subtitle-m ${(index + 1) % maxItemsPerColumn === 0 ? 'last-list-item' : ''}`}
            key={index}
          >
            <Link
              linkHref={item?.url}
              ref={forwardedRefs[index] as Ref<HTMLAnchorElement>}
              text={item?.title}
              linkClassName="list-item-link custom_click_track"
              target={item.openInaNewTab === 'true' ? '_blank' : '_self'}
              rel={item.openInaNewTab === 'true' ? 'noopener noreferrer' : undefined}
              custom_click_track_value={getClickTrackValue({
                trackingProperties: {
                  trackingContentPosition: subNavItems?.trackingProperties?.trackingContentPosition,
                },
                url: item?.url,
                fallbacks: {
                  position: primaryParentLabel,
                  description: item?.title ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
                },
              })}
            >
              {item.openInaNewTab === 'true' && <span className="sr-only">{i18nOpensInNewTab}</span>}
            </Link>
          </li>
        ))}
      </ul>
      {isArticleCardEnabled && (
        <StyledCardArticle>
          <IconBlock
            ctaLink={cardArticleData?.ctalink ?? ''}
            assetVariation="image"
            ctaType="secondaryButton"
            variation="test"
            contentAlignment="left"
            ctaLinkText={cardArticleData?.ctalabel}
            descriptionText={cardArticleData?.descriptiontext}
            dynamicMedia={dynamicData}
            header={cardArticleData?.titletext}
            isRichText={true}
            openInNewTab={cardArticleData?.openinanewtab === 'true'}
            componentId="cardarticle"
            ref={forwardedRefs[forwardedRefs.length - 2]}
          />
        </StyledCardArticle>
      )}
    </StyledSubNavigation>
  );
};
