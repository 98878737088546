// Styles for CityPicklist go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledCityPicklist = styled.div`
  width: 100%;
  @media ${baseVariables.mediaQuery.xl} {
    width: ${toRem(1060)};
  }
  .picklist-subtitle-container {
    .items-container {
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral20};
      display: grid;
      grid-template-columns: 50% 50%;
      padding-top: ${toRem(16)};
      padding-bottom: ${toRem(16)};
      @media ${baseVariables.mediaQuery.lg} {
        padding-top: ${toRem(4)};
        margin-top: ${toRem(-1)};
        padding-bottom: ${toRem(4)};
        grid-template-columns: 20% 20% 20% 20% 20%;
      }
    }
    .button-container {
      padding: ${toRem(8)} 0;
      .button-element {
        cursor: pointer;
      }
      .icon-arrow-up:before {
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${toRem(24)};
        width: ${toRem(24)};
      }
    }
  }
  .highlighted-item: focus {
    background: ${baseVariables.color.neutral20};
    border-radius: ${toRem(8)};
    outline: none;
    z-index: 100;
  }
  .list-heading {
    margin-bottom: 0;
    padding: ${toRem(8)} 0 ${toRem(8)} ${toRem(8)};
    line-height: ${baseVariables.font.lineHeightS};
    font-weight: ${baseVariables.font.fontWeightRegular};
  }

  .icon-nearby {
    width: ${toRem(16)};
    height: ${toRem(16)};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-location {
    width: ${toRem(16)};
    height: ${toRem(16)};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-t-font-m {
    font-size: ${toRem(16)};
    font-weight: ${baseVariables.font.fontWeightRegular};
    line-height: ${baseVariables.font.lineHeightXl};
  }
`;
