/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';

import { LanguageSelectorProps } from './LanguageSelector.types';

import { LinksCollection, Button, useWindowSize } from '@marriott/mi-ui-library';
import {
  StyledLanguageSelector,
  StyledModal,
  StyledColumn,
  StyledModalHeader,
  StyledBorder,
} from './LanguageSelector.styles';
import { getClickTrackValue } from '../../../utils/TrackingPropertyUtils';
import { DEFAULT_TRACKING_VALUES } from '../../../utils/constants';

// Use named rather than default exports.
export const LanguageSelector: FC<React.PropsWithChildren<LanguageSelectorProps>> = ({
  restProps,
  renderUtilityLink,
  buttonClassName,
}) => {
  const { width } = useWindowSize();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { resourceProperties } = restProps;
  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  type JsonArray = any[];
  type JsonObject = { [key: string]: any };
  const isObject = (o: any): o is object => typeof o === 'object' && o !== null;

  const sortJSON = (o: string): any => {
    if (Array.isArray(o)) {
      return o.sort().map((item: any) => sortJSON(item)) as JsonArray;
    } else if (isObject(o)) {
      return Object.keys(o)
        .sort()
        .reduce((acc: JsonObject, key: string) => {
          acc[key] = sortJSON(o[key]);
          return acc;
        }, {}) as JsonObject;
    }
    return o;
  };

  const regionData = Object.values(sortJSON(restProps?.regions || {}));

  return (
    <>
      <Button
        className={`utility-language-selector-button ${buttonClassName}`}
        callback={toggleHandler}
        custom_click_track_value={getClickTrackValue({
          trackingProperties: {
            trackingContentPosition: restProps.trackingContentPosition,
            trackingDescription: restProps.trackingProperties?.trackingDescription,
          },
          url: restProps['cq:panelUrl'],
          fallbacks: {
            description: restProps['cq:panelTitle'],
            position: DEFAULT_TRACKING_VALUES.GLOBAL_NAV,
          },
        })}
      >
        {restProps?.resourceProperties && renderUtilityLink(restProps.resourceProperties, 'globe')}
      </Button>
      {!resourceProperties ? null : (
        <StyledLanguageSelector>
          <StyledModal
            className="modal-popup-container"
            secondaryClassName="modal-main-container"
            show={isOpen}
            popupOpenState={isOpen}
            handleBlur={true}
            setPopupOpenState={setIsOpen}
          >
            <StyledModalHeader
              className="modal-header px-4"
              labelText={restProps?.resourceProperties?.title || ''}
              popupHeaderOnCLoseFunc={setIsOpen}
            ></StyledModalHeader>
            <StyledColumn className="row">
              {regionData?.map((column: any, index) => (
                <div key={index} className="col-12 col-sm-4 col-lg-4 custom-header-font">
                  {Object.keys(column)?.map(title => (
                    <div className="test">
                      <LinksCollection
                        {...column}
                        linkListData={column && column[title]}
                        heading={title
                          .replace('-', ' ')
                          .split('')
                          .map((char, index) => (index === 0 ? char.toUpperCase() : char))
                          .join('')}
                        headingClassName={`region-header px-3 pt-4 t-subtitle-xl header-${title}`}
                        listCollectionClassname="custom-link px-3"
                        listItemClassName="py-3"
                        hasIconArrow={false}
                        key={title}
                      />
                      {width && width <= 575 && <StyledBorder className={`mobile-border-${index}`} />}
                    </div>
                  ))}
                </div>
              ))}
            </StyledColumn>
          </StyledModal>
        </StyledLanguageSelector>
      )}
    </>
  );
};
