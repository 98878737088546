/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
import { createAppStore } from './zustand';
import { produce } from 'immer';

interface GlobalDataState {
  globalData: Record<string, any>;
  setGlobalData?: (data: Record<string, any>) => void;
  updateGlobalData?: (key: string, value: unknown) => void;
}

const initialState: GlobalDataState = {
  globalData: {},
};

export const globalPereistentStore: StateCreator<GlobalDataState> = set => {
  return {
    ...initialState,
    setGlobalData: (data: Record<string, any>) => {
      //To set global data
      set({ globalData: data });
    },
    updateGlobalData: (key: string, value: unknown) => {
      //to update the existing data or add new attribute to state
      set((state: GlobalDataState) =>
        produce(state, (draft: any) => {
          draft.globalData[key] = value;
        })
      );
    },
  };
};

export const usePersistentGlobalStore = createAppStore(globalPereistentStore, {
  usePersistentStore: true,
  persistentStoreName: 'mi-global-data',
});
