import React from 'react';
import { IconProps } from './Icon.types';
import clsx from 'clsx';
import { Link } from '../Link/Link';

export const Icon: React.FC<IconProps> = ({
  iconClass,
  ariaLabel,
  iconHref,
  trackingProperties,
  children,
  target,
  rel,
  custom_click_track_value,
  ariaLive,
  enableLinkClsName = false,
}) => {
  return iconHref ? (
    <Link
      linkHref={iconHref}
      ariaLabel={ariaLabel}
      text=""
      linkClassName={enableLinkClsName ? iconClass || '' : ''}
      trackingProperties={trackingProperties || {}}
      target={target}
      rel={rel}
      custom_click_track_value={custom_click_track_value}
    >
      <span
        data-component-name="a-ui-library-Icon"
        data-testid="ui-library-Icon"
        aria-label={ariaLabel}
        className={clsx(!enableLinkClsName ? iconClass : '')}
        role="status"
      />
      {children}
    </Link>
  ) : (
    <span
      data-component-name="a-ui-library-Icon"
      data-testid="ui-library-Icon"
      aria-label={ariaLabel}
      aria-live={ariaLive}
      aria-atomic="true"
      className={clsx(iconClass)}
      custom_click_track_value={custom_click_track_value}
      role="status"
    >
      {children}
    </span>
  );
};
