// Styles for Navigation go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

//Adding some styles here as they are removed from shared/mi-ui-library
//TODO: we can move the alphaColor to common
const alphaColor = {
  gray1: 'rgba(28, 28, 28, 0.56)',
  gray2: 'rgba(28, 28, 28, 0.83)',
  gray3: 'rgba(28, 28, 28, 0.65)',
};

export const StyledNavigationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 50;
  backdrop-filter: blur(${toRem(4)});
  &.default-background {
    background-color: ${alphaColor[
      'gray3'
    ]}; /* TODO: Update this value to use brand global style or one from shared ui */
    transition: background-color 0.3s ease-in-out;
  }

  &.standard {
    transition: background-color 0.3s ease-in-out;
  }
  header {
    order: 1;
    height: ${toRem(52)};
    @media ${baseVariables.mediaQuery.sm} {
      height: ${toRem(88)};
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  &.header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    opacity: 1;
    animation: slideIn 0.4s ease forwards;
  }

  .primary-navigation-wrapper {
    order: -1;

    @media ${baseVariables.mediaQuery.desktop} {
      order: 0;
    }
  }

  .utility-links-wrapper {
    display: flex;
    align-items: center;
    justify-self: flex-end;
  }

  .navigation-container {
    display: grid;
    grid-template-columns: ${toRem(20)} 1fr 1fr;
    justify-content: space-between;
    width: 100%;
    height: inherit;
    position: relative;
    @media ${baseVariables.mediaQuery.lg} {
      grid-template-columns: ${toRem(138)} 1fr 1fr;
    }
    @media ${baseVariables.mediaQuery.xl} {
      grid-template-columns: ${toRem(106)} 1fr 1fr;
    }
  }
`;
