import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';

export const StyledGeneralMessage = styled.div<{
  styleclass: string | undefined;
}>`
  display: flex;
  background-color: ${props =>
    props.styleclass?.includes('urgent') ? `${baseVariables.color.alert50}` : `${baseVariables.color.base10}`};
  height: 100%;
  overflow: hidden;
  position: relative;
  border: ${toRem(1)} solid ${baseVariables.color['neutral20']};
  box-shadow: ${baseVariables.shadows['shadowSubtle04']};
  align-items: center;
  justify-content: center;
  .gm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${toRem(24)} ${toRem(16)};
    &_heading {
      text-align: center;
      margin-bottom: ${toRem(14)};
      padding: 0 ${toRem(28)};
    }
    &_text {
      color: ${baseVariables.color.base20};
    }
    &_description {
      text-align: center;
    }
    &_collapse {
      position: absolute;
      top: ${toRem(23)};
      padding-right: ${toRem(16)};
      right: 0;
      color: ${baseVariables.color.base20};
    }
    &_link {
      background-color: transparent;
    }
    .visually-hidden {
      position: absolute;
      top: 0;
      right: 0;
      font-size: ${toRem(2)};
      color: transparent;
      cursor: pointer;
    }
    @media ${baseVariables.mediaQuery.md} {
      .gm_heading {
        margin-bottom: ${toRem(16)};
        padding: 0 ${toRem(32)};
      }
    }
  }
`;
