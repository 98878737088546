import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';
export const StyledHeaderLogo = styled.div`
  height: ${toRem(52)};
  width: ${toRem(126)};
  left: ${toRem(32)};
  overflow: hidden;
  @media ${baseVariables.mediaQuery.sm} {
    height: ${toRem(72)};
    width: ${toRem(178)};
    position: relative;
    left: ${toRem(16)};
    top: ${toRem(16)};
  }
  @media ${baseVariables.mediaQuery.lg} {
    width: ${toRem(157)};
    left: ${toRem(-18)};
  }
  @media ${baseVariables.mediaQuery.xl} {
    left: ${toRem(-50)};
  }
  .t-program-logo-alt-color {
    padding-left: ${toRem(36)};
    padding-top: ${toRem(22)};
    font-size: 2.7rem;
    display: block;
    font-weight: 400;
    &::before {
      bottom: ${toRem(3)};
      right: ${toRem(2)};
    }
    @media ${baseVariables.mediaQuery.sm} {
      font-size: 3.8rem;
      padding-left: ${toRem(45)};
      padding-top: ${toRem(30)};

      &::before {
        right: ${toRem(9)};
      }
    }
    @media ${baseVariables.mediaQuery.lg} {
      padding-left: 2rem;

      &::before {
        right: ${toRem(1)};
      }
    }
    &.m-header__logo-icon:hover {
      color: inherit;
    }
  }
`;
