import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';

export const StyledIconBlock = styled.div<{ contentAlignment: string; fontIcon: string }>`
  display: flex;
  height: 100%;
  overflow: hidden;
  border: ${toRem(1)} solid ${baseVariables.color['neutral20']};
  border-radius: ${toRem(14)};
  box-shadow: ${baseVariables.shadows['shadowSubtle04']};
  justify-content: ${props => props.contentAlignment?.toLowerCase()};
  .iconblock {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: ${props => (props.contentAlignment?.toLowerCase() === 'left' ? 'start' : 'center')};
    @media ${baseVariables.mediaQuery.sm} {
      margin: ${toRem(32)} ${toRem(20)};
    }
    margin: ${toRem(20)};
    &-content {
      overflow: hidden;
      @media ${baseVariables.mediaQuery.sm} {
        margin-bottom: ${toRem(20)};
      }
      margin-bottom: ${toRem(16)};
      &.iconblock-icon {
        display: flex;
        justify-content: ${props => props.contentAlignment?.toLowerCase()};
        align-items: center;
        width: ${toRem(68.69)};
        height: ${toRem(68.69)};
        @media ${baseVariables.mediaQuery.sm} {
          width: ${toRem(82)};
          height: ${toRem(82)};
        }
        .icon-m {
          &:before {
            font-size: ${toRem(40)};
          }
        }
      }
      &.iconblock-img {
        img {
          max-height: ${toRem(96)};
        }
      }
    }
    .iconblock-body {
      display: flex;
      flex-direction: column;
      align-items: ${props => (props.contentAlignment?.toLowerCase() === 'left' ? 'start' : 'center')};
      white-space: initial;
      flex: 1;
      .heading {
        padding-bottom: ${toRem(4)};
        max-height: ${toRem(64)};
      }
      .truncate-text {
        text-align: ${props => props.contentAlignment?.toLowerCase()};
        overflow: hidden;
      }
      .description {
        padding-bottom: ${toRem(12)};
        color: ${baseVariables.color['neutral40']};
      }
      .iconblock-button {
        margin-top: auto;
        width: fit-content;
      }
    }
  }
`;
