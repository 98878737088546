import { Config, EditableComponent } from '@adobe/aem-react-editable-components';
import { PropertyBar } from './PropertyBar';
import { PropertyBarAEMProps, PropertyBarWrapperProps } from './PropertyBar.types';
import { StyledPropertyBarContainer } from './PropertyBar.styles';
import { useScrollStore, useStickyState } from '@marriott/mi-store-utils';
import { useCallback } from 'react';

export const ProertyBarConfig: Config<PropertyBarAEMProps> = {
  emptyLabel: 'Property Bar',
  isEmpty: () => false,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/propertybar`,
};
export const PropertyBarWrapper = (props: PropertyBarWrapperProps) => {
  const { model, locale } = props;

  const setRegisteredComponent = useScrollStore(state => state.setRegisteredComponent);
  const { isSticky, offset, top } = useStickyState(model.componentId as string);

  const containerRef = useCallback((ref: HTMLDivElement) => {
    if (!ref) {
      return;
    }
    setRegisteredComponent({
      componentId: model.componentId as string,
      isStickyOnScrollUp: !!model?.scrollSetting?.isStickyOnScrollUp,
      isStickyOnScrollDown: !!model?.scrollSetting?.isStickyOnScrollDown,
      height: ref.clientHeight,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {props.cqPath?.includes('experiencefragment') ? (
        <StyledPropertyBarContainer
          className="m-container-fullbleed"
          ref={containerRef}
          offset={offset}
          top={top}
          isSticky={isSticky}
        >
          <PropertyBar locale={locale} trackingProperties={model.trackingProperties} {...model.resourceProperties} />
        </StyledPropertyBarContainer>
      ) : (
        <EditableComponent config={ProertyBarConfig} {...props}>
          <StyledPropertyBarContainer
            ref={containerRef}
            offset={offset}
            top={top}
            isSticky={isSticky}
            className="m-container-fullbleed"
          >
            <PropertyBar locale={locale} trackingProperties={model.trackingProperties} {...model.resourceProperties} />
          </StyledPropertyBarContainer>
        </EditableComponent>
      )}
    </div>
  );
};
