import styled, { css } from 'styled-components';
import { baseVariables, toRem } from '../../styles';
import { FONT_ICON_CARD_LAYRED_LOGO_SIZE } from '../../utils/enums/enums';

export const StyledComp = styled.div<{
  alignElement: string;
  newTab: boolean;
  imgAlignment?: string;
  isMobileView?: boolean;
  logoIcon?: string;
}>`
  border-radius: ${toRem(14)};
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  @media ${baseVariables.mediaQuery.md} {
    flex-direction: row;
  }
  .hide-component {
    visibility: hidden;
  }
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    order: 2;
    width: 100%;
    padding: ${toRem(32)} ${toRem(24)};
    align-items: ${props => (props.alignElement === 'left' ? 'flex-start' : 'center')};
    @media ${baseVariables.mediaQuery.md} {
      order: ${props => (props.imgAlignment === 'left' ? '2' : '1')};
      padding: ${toRem(32)};
    }
    @media ${baseVariables.mediaQuery.xl} {
      padding: ${toRem(48)};
    }
    .btn-section {
      display: flex;
      gap: ${toRem(24)};
      flex-direction: row;
      margin-top: ${toRem(24)};
      align-items: center;
      font-size: ${toRem(16)};
      font-weight: 700;
      .m-link {
        padding-bottom: ${toRem(2)};
      }
      .m-link-action {
        font-size: inherit;
        font-weight: inherit;
        padding: 0 0 ${toRem(2)} 0;
      }
      .m-link-tertiary-button {
        font-size: inherit;
        font-weight: inherit;
        font-weight: 700;
      }
    }
  }
  .feature-header {
    -webkit-line-clamp: 3;
  }
  .feature-text {
    -webkit-line-clamp: 7;
    padding-top: ${toRem(4)};
    @media ${baseVariables.mediaQuery.xl} {
      -webkit-line-clamp: 5;
    }
  }
  .feature-header,
  .feature-text {
    display: -webkit-box; /* stylelint-disable-line */
    -webkit-box-orient: vertical; /* stylelint-disable-line */
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .feature-img {
    width: 100%;
    height: 100%;
  }
  .img-container {
    order: 1;
    @media ${baseVariables.mediaQuery.md} {
      order: ${props => (props.imgAlignment === 'left' ? '1' : '2')};
    }
    span {
      position: absolute;
      bottom: 0;
      margin: ${toRem(16)};
      left: 0;
      :hover {
        background-color: transparent;
      }
      ${({ imgAlignment, isMobileView }) =>
        imgAlignment === 'right' &&
        !isMobileView &&
        css`
          right: 0;
          left: auto;
        `}
    }
    [class*='brand-logo']::before {
      font-size: ${({ logoIcon, isMobileView }) => {
        const iconName = FONT_ICON_CARD_LAYRED_LOGO_SIZE[logoIcon as string] ? logoIcon : 'DEFAULT';
        const sizeType = isMobileView ? 'mobileSize' : 'deskTopSize';
        return `${FONT_ICON_CARD_LAYRED_LOGO_SIZE[iconName as string][sizeType]}px`;
      }};
    }
  }
  .gradient-color {
    position: relative;
    overflow: hidden;
    ::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 35%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.54) 34%, rgba(0, 0, 0, 0.62) 100%);
      @media ${baseVariables.mediaQuery.md} {
        height: 20%;
      }
    }
  }
  .feature-button {
    margin-top: ${toRem(24)};
  }
`;
