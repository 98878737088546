import { SavedProperty } from './PropertyBar.types';
export const SHOW_HOTEL_DETAIL_EVENT = 'showHotelDetail';

export function getSavedProperties(propertyId: string, savedProperties: SavedProperty[]) {
  const properties = [...savedProperties];
  const propertyAt = properties?.findIndex((property: SavedProperty) => property.id === propertyId);
  propertyAt !== -1 ? properties?.splice(propertyAt, 1) : properties?.push({ id: propertyId });
  return properties.map(({ id }) => ({ id }));
}

export function hasSavedProperty(propertyId: string, savedProperties: SavedProperty[]) {
  return !!savedProperties?.findIndex((property: SavedProperty) => property.id === propertyId);
}
