import { useState, useEffect } from 'react';

/**
 * useMediaQuery Hook
 *
 * This hook allows you to monitor changes in a media query condition
 * and returns the current state of the condition.
 *
 * @param query - The media query string to monitor.
 * @returns `true` if the media query matches, `false` otherwise.
 */
export const useMediaQuery = (query: string): boolean => {
  // State to store the current match status
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    // Create a media query listener
    const mediaQuery = window.matchMedia(query);

    // Event handler for media query changes
    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    // Attach the event listener to the media query
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Initial check of the media query
    setMatches(mediaQuery.matches);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [query]);

  // Return the current match status
  return matches;
};
