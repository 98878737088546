// Styles for __name__ go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledPropertyBarContainer = styled.div<{ top: number; offset: number; isSticky: boolean }>`
  @keyframes slideIns {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  position: ${({ isSticky }) => (isSticky ? 'fixed' : 'absolute')};
  top: ${({ offset, isSticky }) => (isSticky ? 0 : toRem(offset))};
  z-index: 49;
  background-color: white;
  padding: ${toRem(16)} 0;

  ${({ isSticky }) =>
    isSticky &&
    `
    animation: slideIns 0.4s ease forwards;
  `}
`;

export const FlexAnchor = styled.a`
  display: flex;
  align-items: center;
`;

export const StyledPropertyBar = styled.div`
  .wrapper {
    display: flex;
    flex-direction: column;
    @media ${baseVariables.mediaQuery.sm} {
      padding-inline-start: ${toRem(24)};
      padding-inline-end: ${toRem(24)};
    }
  }

  .property-info {
    display: flex;
    align-items: center;
    gap: ${toRem(16)};
  }

  .transparent-button {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer-cursor {
    cursor: pointer;
  }

  @media ${baseVariables.mediaQuery.lg} {
    .wrapper {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;
