/**
 * convertToCommaSeparatedNumber
 *
 * the function takes the number and converts the number into comma separated number and returns the number as a string.
 *
 * @param number - The number that has to be formatted to a localized number format.
 * @param locale - The string : locale for specific region.
 * @returns localized number format of the number that is passed based on the locale param.
 *  */
export function convertToCommaSeparatedNumber(number: number, locale = 'en-US'): string {
  return new Intl.NumberFormat(locale).format(number);
}

/**
 * Concatenate with separator
 *
 * Removes empty strings and concatenates the rest, inserting concat string in between
 *
 * @param strArr Array of strings
 * @returns String of concatenated strings
 */
export const concatenateWithSeparator = (strArr: string[], concatenator: string): string => {
  // Filter out any empty strings
  const filteredStrings = strArr.filter(s => s.length > 0);

  // Join the non-empty strings with a provided string
  return filteredStrings.join(concatenator);
};
