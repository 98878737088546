import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '../../../styles';

// TODO: remove lines 20 to 25 after GS is fixed
export const StyledPopupHeaderDiv = styled.div`
  ${rtl`
  &.hqv-modal-content-header {
    padding-top: ${toRem(16)};
    padding-bottom: ${toRem(16)};
    padding-right: ${toRem(20)};
    height: ${toRem(65)};
    visibility: hidden;
  }
  padding: ${toRem(35)};
  border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${baseVariables.mediaQuery.allTablets}{
    height: ${toRem(90)};
  }
  @media ${baseVariables.mediaQuery.desktop}{
    height: ${toRem(93)};
  }
  .popup-close {
    min-height: ${toRem(32)};
    min-width: ${toRem(32)};
    border-radius: ${toRem(4)};
    background-color: ${baseVariables.color.neutral20};
    color: ${baseVariables.color.base15};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
    &:focus {
      cursor: pointer;
    }
  }

  @media only ${baseVariables.mediaQuery.sm} {
    padding: ${toRem(32)} ${toRem(32)};
    .header-heading {
      font-weight: ${baseVariables.font['fontWeightMedium']};
      font-size: ${toRem(22)};
    }
  }
  `}
`;

export const StyledCloseDiv = styled.div`
  color: ${baseVariables.color['base10']};
`;
