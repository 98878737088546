/* eslint-disable @typescript-eslint/no-explicit-any */
import { create, StateCreator, createStore } from 'zustand';
import { devtools, persist, createJSONStorage, PersistOptions, StateStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const addMiddleWares = <T>(
  creator: StateCreator<T>,
  options: StoreOptions<T> = {
    usePersistentStore: false,
    useLocalStorage: false,
    skipHydration: true,
  }
) => {
  let wrapperCreator:
    | StateCreator<T>
    | StateCreator<T, [], [['zustand/immer', never]]>
    | StateCreator<T, [['zustand/immer', never]], []>
    | StateCreator<T, [], [['zustand/devtools', T]]>
    | StateCreator<T, [], [['zustand/persist', T]]>
    | StateCreator<T, [], [['zustand/devtools', never], ['zustand/persist', T]]>
    | StateCreator<T, [], [['zustand/immer', never], ['zustand/devtools', never], ['zustand/persist', T]]> = creator;

  const { usePersistentStore, persistentStoreName, useLocalStorage, devToolsName, customStorage, ...rest } = options;
  // persist
  if (usePersistentStore) {
    if (!persistentStoreName) {
      throw new Error('Please provide a name for the persistent store');
    }
    wrapperCreator = persist(wrapperCreator, {
      name: persistentStoreName,
      storage: createJSONStorage(() => customStorage || (useLocalStorage ? localStorage : sessionStorage)), // changed to remove deprecation warning
      ...rest,
    });
  }
  // devtools
  if (process.env['NODE_ENV'] === 'development') {
    wrapperCreator = devtools(wrapperCreator as StateCreator<T, [], [['zustand/persist', T]]>, {
      name: devToolsName,
      anonymousActionType: 'Hydrate',
      trace: true,
    });
  }
  wrapperCreator = immer(
    wrapperCreator as StateCreator<T, [['zustand/immer', never]], [['zustand/devtools', never], ['zustand/persist', T]]>
  );
  return wrapperCreator;
};

export type StoreOptions<T> = {
  usePersistentStore: boolean;
  persistentStoreName?: string;
  customStorage?: StateStorage;
  useLocalStorage?: boolean;
  devToolsName?: string;
} & Omit<PersistOptions<T>, 'name' | 'storage'>;

export const createAppStore = <T>(initState: StateCreator<T>, options?: StoreOptions<T>) => {
  return create<T>()(addMiddleWares(initState, options));
};

export const createVanillaStore = <T>(initState: StateCreator<T>, options: StoreOptions<T>) => {
  return createStore<T>()(addMiddleWares(initState, options));
};
