import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { Button } from '../../atoms/Button';
import { Heading } from '../../atoms/Heading';
import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';
import { Text } from '../../atoms/Text';
import { RichText } from '../RichText';
import { headingType, size, tags } from '../../utils/enums/enums';

import { IconBlockProps } from './IconBlock.types';
import { StyledIconBlock } from './IconBlock.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IconBlock = forwardRef((props: IconBlockProps, forwardedRef: any) => {
  const {
    variation,
    contentAlignment,
    openInNewTab,
    ctaLink,
    header,
    headerTag,
    descriptionText,
    ctaType,
    ctaLinkText,
    assetVariation,
    iconPath,
    dynamicMedia,
    trackingProperties,
    styleclass,
    componentId = '',
    openInNewWindowAriaLabel = '',
    iconType,
    isRichText = false,
  } = props;

  const renditions = dynamicMedia?.renditions;

  const tertiaryBtnClass =
    ctaType === 'tertiaryLink'
      ? openInNewTab
        ? 'm-link-tertiary-button m-link-tertiary-button-external t-background-color'
        : 'm-link-tertiary-button t-background-color'
      : '';
  const secondaryBtnClass =
    ctaType === 'secondaryButton'
      ? openInNewTab
        ? 'm-button-s m-button-secondary m-button-external'
        : 'm-button-s m-button-secondary'
      : '';
  const primaryBtnClass =
    ctaType === 'primaryButton'
      ? openInNewTab
        ? 'm-button-s m-button-primary m-button-external'
        : 'm-button-s m-button-primary'
      : '';
  const isLinkValue = ctaType === 'tertiaryLink' ? true : false;
  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  const buttonCallback = () => {
    return isLinkValue ? {} : window.open(ctaLink, openInNewTab ? '_blank' : '_self');
  };

  return (
    <StyledIconBlock
      data-component-name="m-ui-library-IconBlock"
      data-testid="ui-library-IconBlock"
      className={`col-12 p-0 ${styleclass ? styleclass : ''}`}
      contentAlignment={contentAlignment}
      fontIcon={iconPath || ''}
    >
      <div className={`iconblock`} data-variation={variation} data-testid="icon-block" {...customAttributes}>
        {assetVariation === 'iconfont' && (
          <div className="iconblock-content iconblock-icon">
            <Icon
              iconClass={clsx(iconPath, iconType === 'iconDecorative' ? 'icon-decorative' : '', 'icon-m')}
              ariaLabel={iconPath}
            />
          </div>
        )}
        {assetVariation === 'image' && (
          <div className="iconblock-content iconblock-img">
            <Image
              altText={dynamicMedia?.altText}
              renditions={renditions}
              dynamic={dynamicMedia?.dynamic}
              defaultImageURL={dynamicMedia?.assetPath}
            />
          </div>
        )}

        <div className="iconblock-body">
          <Heading
            element={headerTag}
            variation={headingType.subtitle}
            fontSize={size.medium}
            titleText={header || ''}
            disableCustomClickTrack
            customClass={'truncate-text heading m-0 '}
          />
          {descriptionText &&
            (isRichText ? (
              <RichText
                text={descriptionText}
                componentId={componentId}
                customClass={clsx('truncate-text', 'description')}
              />
            ) : (
              <Text
                customClass={clsx('truncate-text', 'description')}
                copyText={descriptionText}
                fontSize={size.small}
                element={tags.div}
              />
            ))}
          {ctaLinkText && ctaLink && (
            <Button
              href={ctaLink}
              isLink={isLinkValue}
              className={`iconblock-button ${primaryBtnClass} ${secondaryBtnClass} ${tertiaryBtnClass}`}
              target={`${openInNewTab ? '_blank' : '_self'}`}
              linkType={`${openInNewTab ? 'external' : ''}`}
              rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
              ref={forwardedRef}
              buttonCopy={ctaLinkText}
              trackingProperties={trackingProperties}
              custom_click_track_value={
                trackingProperties?.clickTrack
                  ? `${trackingProperties?.location}|${trackingProperties?.trackingDescription}|${openInNewTab ? 'external' : 'internal'}`
                  : ''
              }
              isTextBeforeChildren={false}
              callback={buttonCallback}
              linkAriaLabelOpenNewWindow={openInNewWindowAriaLabel}
            />
          )}
        </div>
      </div>
    </StyledIconBlock>
  );
});
