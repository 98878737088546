export const GOOGLE_MAP_KEY = 'gme-marriottinternational';
export const URL_CONSTANTS = {
  PLACEHOLDER_IMAGE:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiOAMAANUAz5n+TlUAAAAASUVORK5CYII=',
  BRIGHTCOVE_PLAYER_DOMAIN: 'https://players.brightcove.net',
};
export const GOOGLE_MAP_VAL_KEY = 'AIzaSyCCW6WUQGP6GoOAd-iPkaiS53x3SmhXMgQ';

export const CN_YEAR = '年';
export const CN_MONTH = '月';
export const CN_FLEXIBLE_FORMAT_YEAR_MONTH = `YYYY${CN_YEAR} M${CN_MONTH}`;
