/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// eslint-disable-next-line import/no-webpack-loader-syntax
import styles from '!!scss-extract-loader?{"plugins": ["scss-extract-js"]}!../../../mi-ui-global-styles/src/styles/base/variables/_variables.scss';
import { toRem } from './utils';

let spacers: any = {};
let shadows: any = {};
let opacity: any = {};
let mediaQueries: any = {};
let gradient: any = {};
let font: any = {};
let border: any = {};
let animations: any = {};
let color: any = {};

Object.keys(styles).forEach(function (key) {
  key.indexOf('icon') === 0 && delete styles[key];
});

Object.keys(styles).forEach(vars => {
  const varsLowercase = vars.toLowerCase();
  if (varsLowercase.indexOf('spacer') > -1) {
    spacers = { [vars]: styles[vars], ...spacers };
  } else if (varsLowercase.indexOf('shadow') > -1) {
    shadows = { [vars]: styles[vars], ...shadows };
  } else if (varsLowercase.indexOf('opacity') > -1) {
    opacity = { [vars]: styles[vars], ...opacity };
  } else if (varsLowercase.indexOf('viewport') > -1) {
    mediaQueries = { [vars]: styles[vars], ...mediaQueries };
  } else if (varsLowercase.indexOf('basegradient') > -1) {
    gradient = { [vars]: styles[vars], ...gradient };
  } else if (varsLowercase.indexOf('font') > -1 || varsLowercase.indexOf('lineheight') > -1) {
    font = { [vars]: styles[vars], ...font };
  } else if (varsLowercase.indexOf('border') > -1) {
    border = { [vars]: styles[vars], ...border };
  } else if (varsLowercase.indexOf('duration') > -1 || varsLowercase.indexOf('ease') > -1) {
    animations = { [vars]: styles[vars], ...animations };
  } else {
    color = { [vars]: styles[vars], ...color };
  }
});

// The use of the below media queries (soon to be deprecated) is discouraged. Stylings should
// ideally be from global styles and bootstrap classes
const mediaQueryDepricated = {
  largeDesktop: ` (min-width:  ${mediaQueries.viewportXl})`,
  desktop: ` (min-width:  ${mediaQueries.viewportL})`,
  tablet: ` screen and (min-width:  ${mediaQueries.viewportM})`,
  allTablets: ` screen and (min-width:  ${mediaQueries.viewportM}) and (max-width: ${mediaQueries.viewportL})`,
  mobile: ` screen and (min-width: ${mediaQueries.viewportS})`,
  smallMobile: ` screen and (min-width: ${mediaQueries.viewportXs})`,
};

shadows = {
  'white-header': `0 ${toRem(4.49)} ${toRem(13.48)} 0 rgba(0, 0, 0, 0.12)`,
  ...shadows,
};

export const baseVariables = {
  mediaQuery: {
    xl: ` screen and (min-width: ${mediaQueries.viewportXl})`,
    lg: ` screen and (min-width: ${mediaQueries.viewportL})`,
    md: ` screen and (min-width: ${mediaQueries.viewportM})`,
    sm: ` screen and (min-width: ${mediaQueries.viewportS})`,
    ...mediaQueryDepricated,
  },
  spacers,
  shadows,
  opacity,
  gradient,
  font,
  border,
  animations,
  color,
};

export const baseVals = styles;
