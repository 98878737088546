// This function is used to convert any case string to Camel Case String

const mapper: Record<string, string> = {};

type AllowedComponentType = { title: string; path: string };

const capitalize = (arr: Array<string>) => {
  return arr.map(word => {
    const firstLetter = word.charAt(0).toUpperCase();
    const rest = word.slice(1).toLowerCase();

    return firstLetter + rest;
  });
};

export const AEMReactCompMap = (allowedComponents?: Array<AllowedComponentType>) => {
  allowedComponents?.forEach((el: AllowedComponentType) => {
    const aemComponent = el.path.split('/').pop();
    if (aemComponent) {
      aemComponent === 'cardhorizontalmini'
        ? (mapper['cardhorizontalmini'] = 'CardHorizontalMini')
        : (mapper[aemComponent] = capitalize(el.title.split(/\s|\.\|_|-/)).join(''));
    }
  });
  return mapper;
};
