import { EditableComponent } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';

import { MerchandisingHeroBanner as MerchandisingHeroBannerAEM } from './MerchandisingHeroBanner';

export const MerchandisingHeroBannerConfig = {
  emptyLabel: 'Merchandising Hero Banner',
  isEmpty: () => true,
  resourceType: `mi-aem-common-spa/components/content/merchandisingherobanner`,
};

const heroGridStyles: React.CSSProperties = {
  clear: 'both' as const,
  float: 'left' as const,
  width: '100%',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MerchandisingHeroBanner = (props: any) => {
  const isTargetEnabled = props.model.appliedCssClassNames?.includes('enable-target') && !props.isAuthorMode;
  return (
    <div
      data-testid="homepage-herobanner"
      style={heroGridStyles}
      className={clsx('herobanner-fullbleed', {
        [`target-background herobanner-ratio-${props.model.heroSize}`]: isTargetEnabled,
      })}
    >
      <EditableComponent config={MerchandisingHeroBannerConfig} {...props}>
        <MerchandisingHeroBannerAEM {...props.model} />
      </EditableComponent>
    </div>
  );
};
