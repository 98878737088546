import { FC } from 'react';

import { LinkFeature } from './LinkFeature';
import { FooterAEMModel } from './Footer.types';
import { StyledRow } from './Footer.styles';
import { DEFAULT_TRACKING_VALUES } from '../../utils/constants';

export const CollapsedFooter: FC<{ model: FooterAEMModel }> = ({ model }) => {
  const trackingPropertiesObject = {
    trackingProperties: {
      trackingContentPosition: model?.trackingProperties?.trackingContentPosition,
      clickTrack: model?.trackingProperties?.clickTrack,
      trackingOfferType: model?.trackingProperties?.trackingOfferType,
      impressionCount: model?.trackingProperties?.impressionCount,
      impressionTrack: model?.trackingProperties?.impressionTrack,
      trackingDescription: model?.trackingProperties?.trackingDescription,
      trackingTag: model?.trackingProperties?.trackingTag,
    },
    fallbacks: {
      position: model?.trackingProperties?.location ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
      description: model?.trackingProperties?.trackingDescription ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
    },
  };
  return (
    <div className="col-12 inverse">
      <StyledRow className="d-flex flex-column flex-md-row row">
        {model?.minimizedItems?.map(minimizedItem => {
          return (
            <div key={minimizedItem.linkLabel} className="col-md-1.5 pl-md-0 thin-privacy-items">
              <LinkFeature item={minimizedItem} trackingPropertiesObject={trackingPropertiesObject} />
            </div>
          );
        })}
      </StyledRow>
    </div>
  );
};
