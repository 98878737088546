import { Container } from '@adobe/aem-react-editable-components';

import { AemContainerConfig } from '../Navigation.types';
import { NavigationUtilityLink as NavigationUtilityLinkReact } from './NavigationUtilityLink';
import { NavigationUtilityLinkWrapperProps } from './NavigationUtilityLink.types';
import clsx from 'clsx';

export const NavigationUtilityLinkWrapper = (props: NavigationUtilityLinkWrapperProps) => {
  const cqPath = `${props.cqPath}/utilityNavigation`;
  const { logoType = '', logoUrl = '', logoImageAltText = '' } = props;
  const UtilityConfig: AemContainerConfig = {
    emptyLabel: 'Utility Links',
    isEmpty: () => false,
    resourceType: 'mi-aem-homepage-spa/components/content/utilityNavigation',
    isInEditor: !!props?.isAuthorMode,
    cqPath: cqPath,
  };

  return (
    <Container
      className={clsx('utility-links-wrapper', { 'py-0 ': props?.isAuthorMode })}
      {...UtilityConfig}
      childPages={
        <NavigationUtilityLinkReact
          utilityNavigation={props.utilityNavigation}
          isRenderedInsideOverlay={props.isRenderedInsideOverlay}
          logoUrl={logoUrl}
          logoImageAltText={logoImageAltText}
          logoType={logoType}
          cqPath={cqPath}
          isAuthorMode={!!props?.isAuthorMode}
        />
      }
    />
  );
};
