import { APPLICATION_BREAKPOINTS } from '../styles/utils';
import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';

type keys = keyof typeof APPLICATION_BREAKPOINTS;

/**
 * Hook to return whethre current window size is greater than viewport size provided. Current sizes from
 * global styles are:
 * {
 *   "viewportXS": "0px",
 *   "viewportS": "576px",
 *   "viewportM": "768px",
 *   "viewportL": "992px",
 *   "viewportXl": "1200px"
 * }
 * @param viewportSize
 * @returns boolean
 */
export function useCheckBreakpoint(viewportSize: keys) {
  const [isGreater, setIsGreater] = useState(false);
  const { width } = useWindowSize();

  const targetViewportSize = APPLICATION_BREAKPOINTS[viewportSize] || 0;

  useEffect(() => {
    if (width && width >= targetViewportSize) {
      setIsGreater(true);
    } else {
      setIsGreater(false);
    }
  }, [width, targetViewportSize]);

  return isGreater;
}
