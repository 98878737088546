import React, { FC, useRef } from 'react';
import clsx from 'clsx';

import { Button } from '../../atoms/Button';
import { Image } from '../../atoms/Image';
import { Heading } from '../../atoms/Heading';
import { Eyebrow } from '../../atoms/Eyebrow';
import { RichText } from '../RichText';
import {
  CobrandCardHorizontalFlexibleMBoxProps,
  CobrandCardHorizontalFlexibleProps,
} from './CobrandCardHorizontalFlexible.types';
import { StyledCobrandCardHorizontalFlexible } from './CobrandCardHorizontalFlexible.styles';
import { removeParentTagRTE } from '../../utils/helper';
import { ButtonStyle, headingType, size } from '../../utils/enums/enums';

export const CobrandCardHorizontalFlexible: FC<
  CobrandCardHorizontalFlexibleProps & CobrandCardHorizontalFlexibleMBoxProps
> = ({
  openInNewTab = false,
  ctaType,
  ctaLink,
  ctaLinkText,
  header,
  headerTag,
  descriptionText,
  descriptionTextMobile,
  trackingProperties,
  styleclass,
  dynamicMedia,
  componentId,
  openInNewWindowAriaLabel = '',
  customDescriptionClass,
  customContainerClass,
  customImageClass,
  targetData = {},
}) => {
  const {
    totalStayLabel = '',
    totalStayAmount = '',
    statementCreditLabel = '',
    statementCreditAmount = '',
    totalAfterCreditLabel = '',
    totalAfterCreditAmount = '',
    pointsLabel = '',
    pointsBalanceLabel = '',
    pointsBalanceAmount = '',
    bonusPointsLabel = '',
    bonusPointsAmount = '',
    pointsTotalLabel = '',
    pointsTotalAmount = '',
    disclaimer = '',
  } = targetData;
  const renditions = dynamicMedia?.renditions;
  const CobrandCardHorizontalFlexibleRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;
  const isHeader = header !== undefined && header.length > 0 ? true : false;
  const isBodyCopy = descriptionText !== undefined && descriptionText.length > 0 ? true : false;
  const isBodyCopyMobile = descriptionTextMobile !== undefined && descriptionTextMobile.length > 0 ? true : false;

  // Using removeParentTagRTE to clean the text recieved from RTE
  const headerRTEText =
    isHeader && (header.includes('<') || header.includes('>')) ? removeParentTagRTE(header) : header;

  const isCurrencyMath = Boolean(totalAfterCreditAmount);
  const isPointsMath = Boolean(pointsTotalAmount);
  const isTargetEnabled = isCurrencyMath || isPointsMath;

  const getButtonStyle = (ctaType: string, openInNewTab: boolean): string => {
    switch (ctaType) {
      case 'primaryButton':
        return openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON;
      case 'secondaryButton':
        return openInNewTab ? ButtonStyle.SECONDARY_BUTTON_EXTERNAL : ButtonStyle.SECONDARY_BUTTON;
      default:
        return '';
    }
  };

  const buttonTypeClasses = getButtonStyle(ctaType || '', openInNewTab);

  const renderCobrandMathContentSection = (
    pointsOrStayTotalLabel: string,
    pointsOrStayTotalAmt: string,
    bonusOrStatementLabel: string,
    bonusOrStatementAmt: string,
    totalLabel: string,
    totalAmt: string,
    hasPoints: boolean
  ) => {
    return (
      <div className={clsx('d-flex cobrand-math-content-section flex-column flex-grow-1 p-0')}>
        {hasPoints && (
          <div className={clsx('d-flex mb-2 justify-content-end')}>
            <Eyebrow text={pointsLabel} />
          </div>
        )}
        <div className={clsx('d-flex mb-2 justify-content-between')}>
          <RichText text={pointsOrStayTotalLabel} componentId={'points-stay-total-label'} customClass="t-font-alt-xs" />
          <span className="t-font-s">{pointsOrStayTotalAmt}</span>
        </div>
        <div className={clsx('d-flex mb-2 justify-content-between')}>
          <RichText text={bonusOrStatementLabel} componentId={'bonus-statement-label'} customClass="t-font-alt-xs" />
          <span className="t-font-s">{bonusOrStatementAmt}</span>
        </div>
        <div className={clsx('d-flex justify-content-between')}>
          <RichText text={totalLabel} componentId={'total-label'} customClass="t-font-m" />
          <span className="t-font-m total-amount">{totalAmt}</span>
        </div>
      </div>
    );
  };

  return (
    <StyledCobrandCardHorizontalFlexible
      data-component-name="m-ui-library-CobrandCardHorizontalFlexible"
      data-testid="ui-library-CobrandCardHorizontalFlexible"
      id={componentId}
      className={clsx(`col-12 col-sm-8 col-md-12 px-md-0 `, `${styleclass ? styleclass : ''}`)}
    >
      <div
        className={clsx('card-container t-background-color', customContainerClass, {
          'card-math-container': isTargetEnabled,
        })}
      >
        <div
          className={clsx('image-container', customImageClass, {
            'math-img-container align-self-lg-center align-self-md-start': isTargetEnabled,
          })}
        >
          <Image
            altText={dynamicMedia?.altText}
            renditions={renditions}
            dynamic={dynamicMedia?.dynamic}
            defaultImageURL={dynamicMedia?.assetPath}
          />
        </div>
        <div
          className={clsx(
            'flexible-card-content d-flex flex-grow-1',
            isTargetEnabled
              ? 'flexible-content-section flex-column'
              : 'justify-content-between align-items-center evergreen-content-section'
          )}
        >
          <div className={clsx(isTargetEnabled ? 'd-flex content-section' : 'flexible-card-content-container')}>
            <div className={clsx('flexible-card', { 'align-self-center flexible-math-card': isTargetEnabled })}>
              {isHeader && (
                <Heading
                  customClass={isTargetEnabled ? 'mb-2' : 'mb-1 mb-md-0'}
                  variation={headingType.subtitle}
                  fontSize={size.large}
                  element={headerTag}
                  dangerouslySet={headerRTEText}
                />
              )}
              {isBodyCopy && descriptionText && (
                <div
                  dangerouslySetInnerHTML={{ __html: descriptionText }}
                  className={clsx(
                    'd-none d-sm-block pr-md-2 t-font-alt-s',
                    { 'd-block': !descriptionTextMobile },
                    customDescriptionClass
                  )}
                ></div>
              )}
              {isBodyCopyMobile && descriptionTextMobile && (
                <div
                  dangerouslySetInnerHTML={{ __html: descriptionTextMobile }}
                  className={clsx('d-block d-sm-none pr-md-2 t-font-alt-s')}
                ></div>
              )}
            </div>
            {isTargetEnabled &&
              isCurrencyMath &&
              renderCobrandMathContentSection(
                totalStayLabel,
                totalStayAmount,
                statementCreditLabel,
                statementCreditAmount,
                totalAfterCreditLabel,
                totalAfterCreditAmount,
                false
              )}
            {isTargetEnabled &&
              isPointsMath &&
              renderCobrandMathContentSection(
                pointsBalanceLabel,
                pointsBalanceAmount,
                bonusPointsLabel,
                bonusPointsAmount,
                pointsTotalLabel,
                pointsBalanceAmount,
                true
              )}
          </div>
          <div
            className={clsx('d-flex', { 'mt-3 disclaimer-and-cta-section justify-content-between': isTargetEnabled })}
          >
            {isTargetEnabled && disclaimer && (
              <RichText
                text={disclaimer}
                componentId={'disclaimer-richtext'}
                customClass="t-font-alt-xs disclaimer-text col-md-5 col-12 p-0"
              />
            )}
            {ctaLinkText && (
              <Button
                className={`${buttonTypeClasses} flexible-click m-button-s m-md-0 text-center`}
                href={ctaLink}
                isLink={true}
                target={openInNewTab === true ? '_blank' : '_self'}
                trackingProperties={trackingProperties}
                rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
                ref={CobrandCardHorizontalFlexibleRef}
                buttonCopy={ctaLinkText}
                linkAriaLabelOpenNewWindow={openInNewWindowAriaLabel}
              />
            )}
          </div>
        </div>
      </div>
    </StyledCobrandCardHorizontalFlexible>
  );
};
