/* eslint-disable @nx/enforce-module-boundaries */
import { FC, useEffect, SyntheticEvent } from 'react';
import { CurrentLocationProps } from './CurrentLocation.types';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BMap: any;
  }
}

const CurrentLocationBaidu: FC<CurrentLocationProps> = ({
  inputFieldHandler,
  currentLocationHiddenFieldHandler,
  apiKey,
  currentLocationLabel,
  iconTextBlockCustomClass,
  render,
}) => {
  const clickTrackingValue = 'Current Location Clicked';
  const clickHandler = (e: SyntheticEvent): void => {
    e.preventDefault();
    const locationHandler = (data: Record<string, string>): void => {
      const currentLocationInfo = data;
      // update the hidden input fields and destination input field
      currentLocationHiddenFieldHandler(currentLocationInfo);
      inputFieldHandler(currentLocationInfo['address']);
    };
    getCurrentLocation(locationHandler);
  };
  useEffect(() => {
    if (!window.BMap || window.BMap === undefined) {
      appendMapFile();
    }
    function appendMapFile() {
      const script = document.createElement('script');
      script.src = `https://api.map.baidu.com/getscript?v=2.0&ak=${apiKey}&s=1`;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, [apiKey]);

  const getCurrentLocation = (callback: CallableFunction): void => {
    let currentLocation: Record<string, string>;

    if (window.BMap) {
      const geolocation = new window.BMap.Geolocation();
      geolocation.getCurrentPosition(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (r: any) => {
          if (geolocation.getStatus() === 0) {
            const point = new window.BMap.Point(r.point.lng, r.point.lat);
            const geoc = new window.BMap.Geocoder();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            geoc.getLocation(point, (rs: any) => {
              currentLocation = {
                address: rs.address,
              };
              callback(currentLocation);
            });
          } else {
            window.alert('Please enable geolocation in your browser settings.');
          }
        },
        { enableHighAccuracy: true }
      );
    }
  };
  const data = {
    currentLocationLabel,
    clickTrackingValue,
    iconTextBlockCustomClass,
    clickHandler,
  };
  return <>{render(data)}</>;
};

export default CurrentLocationBaidu;
