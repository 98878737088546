import { size } from '../../utils/enums/enums';
import { TrackingPropsInterface } from '../../utils/enums/enums';

export enum IconCollectionVariation {
  Icon = 'Icon',
  LogoLinks = 'logoLinks',
}
export enum IconCollectionAlignment {
  Vertical = 'vertical-align',
  Horizontal = 'horizontal-align',
}

export interface IconCollectionProps {
  id?: string;
  variation: IconCollectionVariation;
  alignment?: IconCollectionAlignment;
  iconClassName?: string;
  iconSize?: size.small | size.medium | size.large;
  href?: string;
  openInNewTab?: boolean;
  altText?: string;
  ariaLabel?: string;
  trackingProperties?: TrackingPropsInterface;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IconCollectionData: any;
  enableLinkClsName?: boolean;
}
