import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledUserProfileImageContainer = styled.div<{ borderColor: string }>`
  position: relative;

  width: ${toRem(100)};
  height: ${toRem(100)};
  @media ${baseVariables.mediaQuery.lg} {
    width: ${toRem(156)};
    height: ${toRem(156)};
  }

  .userprofile-image {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: ${toRem(4)} solid ${props => props.borderColor};
    overflow: hidden;
    position: relative;
    @media ${baseVariables.mediaQuery.lg} {
      border: ${toRem(7)} solid ${props => props.borderColor};
    }
  }
  .icon-lifetime {
    position: absolute;
    right: ${toRem(72)};
    transform: rotate(-23deg);
    width: ${toRem(32)};
    height: ${toRem(32)};
    font-size: ${toRem(20)};
    bottom: ${toRem(4)};
    border: ${toRem(3)} solid ${baseVariables.color['base20']};
    border-radius: 50%;
    background: ${baseVariables.color['goldSolid']};
    color: ${baseVariables.color['base20']};

    @media ${baseVariables.mediaQuery.lg} {
      width: ${toRem(40)};
      height: ${toRem(40)};
      font-size: ${toRem(27)};
      right: ${toRem(113)};
    }
  }
`;
