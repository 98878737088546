import { useCallback } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useScrollStore } from '@marriott/mi-store-utils';
import { GeneralMessage as GeneralMessageComp, GeneralMessageProps } from '@marriott/mi-ui-library';

export const GeneralMessageConfig = {
  emptyLabel: 'GeneralMessage',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/generalmessage`,
};

export const GeneralMessage = (props: GeneralMessageProps) => {
  const setRegisteredComponent = useScrollStore(state => state.setRegisteredComponent);

  const refCallback = useCallback((ref: HTMLDivElement) => {
    if (!ref) {
      return;
    }
    setRegisteredComponent({
      componentId: props.model.componentId ?? '',
      isStickyOnScrollUp: !!props.model?.scrollSetting?.isStickyOnScrollUp,
      isStickyOnScrollDown: !!props.model?.scrollSetting?.isStickyOnScrollDown,
      height: ref.clientHeight,
    });
  }, []);

  const updatedProps = { ...props, ARIA_EXPAND: 'Expand Message', ARIA_COLLAPSE: 'Collapse Message' };
  return (
    <div data-testid="general-message" data-component-name="o-common-static-generalmessage">
      {/* @ts-expect-error AEM doesn't like it's own page model types */}
      <EditableComponent config={GeneralMessageConfig} {...props}>
        <GeneralMessageComp {...updatedProps} refCallback={refCallback} />
      </EditableComponent>
    </div>
  );
};
