import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CobrandCardHorizontalFlexible as CobrandCardHorizontalFlexibleComp } from '@marriott/mi-ui-library';
import { CobrandCardHorizontalFlexibleCompProps } from './CobrandCardHorizontalFlexible.types';

export const CobrandCardHorizontalFlexibleConfig = {
  emptyLabel: 'CobrandCardHorizontalFlexible',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/cardhorizontalflexible`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CobrandContainer = (props: any) => {
  return (
    <EditableComponent config={CobrandCardHorizontalFlexibleConfig} {...props}>
      <CobrandCardHorizontalFlexibleComp {...props} />
    </EditableComponent>
  );
};

export const CobrandCardHorizontalFlexible = (props: CobrandCardHorizontalFlexibleCompProps) => {
  const { trackingProperties, componentId } = props.model;

  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};
  return (
    <div
      {...customAttributes}
      data-testid="cobrand-card-horizontal-flexible"
      data-component-name="o-common-static-cobrandcardhorizontalflexible"
    >
      <CobrandContainer {...props} />
    </div>
  );
};
