import { theme } from './theme';
import { toRem, ariaConditionalAppend } from './utils';
import breakpoints from '@marriott/global-styles/dist/scss/base/variables/_mediaQueries.module.scss';

// parsing breakpoints fetched from global styles and sending as int values
const APPLICATION_BREAKPOINTS = (() => {
  const parsed: { [key: string]: number } = {};
  Object.keys(breakpoints).forEach(key => {
    parsed[key] = parseInt(breakpoints[key].replace('px', ''), 10);
  });
  return parsed;
})();

export { theme, toRem, ariaConditionalAppend, APPLICATION_BREAKPOINTS };
