import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';
import { logoResize } from '../../utils/logoResize';
import { FONT_ICON_CARD_LAYRED_LOGO_SIZE } from '../../utils/enums/enums';
import { CardLayeredVariations } from './CardLayered.types';

export const StyledCardLayered = styled.div<{ isBadgeDisplay: boolean }>`
  display: flex;
  justify-content: space-between;

  .card-layered {
    position: relative;
    border-radius: ${toRem(15)};
    box-sizing: border-box;
    overflow: hidden;
    width: fit-content;
    height: fit-content;
    .image {
      max-width: 100%;
    }
    &__content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: ${props => (props.isBadgeDisplay ? 'space-between' : 'flex-end')};
      .overlay {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: ${toRem(16)};
        ${props =>
          !props.isBadgeDisplay
            ? `
        position: absolute;
        right: 0;
        top: 0;
        `
            : ''}
        .cardlayered-badge {
          white-space: nowrap;
          overflow: hidden;
          margin-right: ${toRem(8)};
          line-height: ${toRem(12)};
          padding: ${toRem(6)} ${toRem(8)};
        }
      }
      .socialIcons {
        padding: 0;
        span {
          &::before {
            padding: ${toRem(4)};
          }
        }
      }
      .card-text-wrapper {
        .card-texts {
          background: ${baseVariables.gradient['baseGradient00']};
          padding-bottom: ${toRem(24)};
          padding-top: ${toRem(24)};
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: end;
          .card-layered-header {
            padding-right: ${toRem(8)};
            padding-left: 0;
            &.card-wide-header {
              @media ${baseVariables.mediaQuery.sm} {
                -webkit-line-clamp: 1;
              }
              @media ${baseVariables.mediaQuery.md} {
                -webkit-line-clamp: 2;
              }
            }
          }
        }
      }
    }
    &.inverse {
      .card-layered__content .card-text-wrapper .card-texts {
        background: ${baseVariables.gradient['baseGradient04']};
      }
    }
    &.standard {
      .card-layered__content .card-text-wrapper .card-texts {
        background: ${baseVariables.gradient['baseGradient00']};
      }
    }
  }
  .inverse & {
    .card-layered__content .card-text-wrapper .card-texts {
      background: ${baseVariables.gradient['baseGradient04']};
    }
  }
  .glide__slides & {
    white-space: normal;
  }
  .chevronContainer {
    position: relative;
    overflow: hidden;
    &[class*='icon-arrow-']:before {
      position: absolute;
      right: ${toRem(24)};
      bottom: ${toRem(24)};
      z-index: 1;
    }
  }
`;

export const StyledFontIcon = styled.span<{ fontIcon: string; cardVariation: string }>`
  &.m-icon {
    display: inline-block;
    margin-bottom: ${toRem(24)};
    &.brand {
      &-logo-${props => props.fontIcon}::before {
        ${props => logoResize(props.fontIcon, FONT_ICON_CARD_LAYRED_LOGO_SIZE)};
        font-weight: ${baseVariables.font['fontWeightRegular']};
      }
    }
    &:hover {
      background-color: transparent;
    }
    ${props =>
      props.cardVariation === CardLayeredVariations.Wide
        ? `margin-bottom : ${toRem(6)};
          @media ${baseVariables.mediaQuery.md} {
            margin-bottom: ${toRem(12)};
          }
          @media ${baseVariables.mediaQuery.lg} {
            margin-bottom: ${toRem(16)};
          }
        `
        : ''};
  }
`;
