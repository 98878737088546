import { Types, renditions } from '@marriott/mi-ui-library';

interface ImageSource {
  altText: string;
  assetPath: string;
  dynamic: boolean;
  damPath: string;
  renditions: Array<{
    renditionPath: string;
    mediaValue: string;
    dynamic: boolean;
    damPath: string;
    mediaQuery: string;
    width: number;
    height: number;
  }>;
}

export interface logo {
  imageSrc: string;
  altText: string;
}

export interface MerchandisingHeroBannerProps {
  image: renditions;
  location?: string;
  description?: string;
  assetsVariation?: Types.AssetVariation;
  logo?: logo;
  imgAltText?: string;
  font_icon?: string;
  fontIconAltText?: string;
  fileReferenceImageVertical?: string;
  verticalImageAltText?: string;
  imageURLDesktop?: string;
  backgroundimagealttext?: string;
  imageURLMobile?: string;
  eyebrowText?: string;
  headertextstyle: Types.HeaderTextStyle;
  headerMedium?: string;
  descriptionLong?: string;
  callToActionText1?: string;
  destinationUrl1?: string;
  primaryopeninanewtab?: boolean;
  callToActionText2?: string;
  destinationUrl2?: string;
  secondaryopeninanewtab?: boolean;
  caption?: string;
  captionUrl?: string;
  locationopeninanewtab?: boolean;
  hideLocationCtaMobile?: boolean;
  horizontalgradient?: boolean;
  verticalgradient?: boolean;
  aspectRatioMobileVideo?: string;
  accountId?: string;
  playerId?: string;
  playerDomain?: string;
  cobrandmathhero?: boolean;
  mathLabel1?: string;
  mathLabel2?: string;
  mathLabel3?: string;
  mathValue?: string;
  videoaccountId?: string;
  enableCardImage?: boolean;
  enableMaskingOnDesktop?: boolean;
  enableVerticalCardImage?: boolean;
  enableAutoPlay?: boolean;
  enableMute?: boolean;
  enableVideoLoop?: boolean;
  heroSize?: Types.ImageAspectRatio;
  enableContentStyle?: boolean;
  imageLoading?: 'eager' | 'lazy';
  videoEnabledDesktop?: boolean;
  videoEnabledMobile?: boolean;
  appliedCssClassNames?: string;
  enableTarget?: boolean;
  trackingProperties?: TrackingProps;
  opensInNewTab?: string;
  videoIdDesktop?: string;
  videoIdMobile?: string;
  fileReferenceVideoCoverImage?: string;
  backgroundMedia?: ImageSource;
  componentId?: string;
  copyBlockWidth?: Types.HeaderCopyWidth;
  mboxParameter?: string;
  isDCAEnabled?: boolean;
  offerCode?: string;
}

export interface TrackingProps {
  atCCeVar48?: string;
  trackingDescription?: string;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  trackingTag2?: string;
  trackingTag1?: string;
  merchandisingCategory?: string;
  trackingContentPosition?: string;
  impressionCount?: boolean;
  trackingOfferType?: string;
  countryCode?: string;
  issuerCode?: string;
  rpcCode?: string;
  offerCode?: string;
  cell?: string;
  prequal?: string;
  prequalSpids?: string;
}
//TODO:Move these to global styles
export const MERCHANDISING_HEROBANNER_GRADIENTS = {
  EXTRATHIN: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 34.9%, rgba(0,0,0,0.54) 67.94%, rgba(0,0,0,0.62) 100%)`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37.37%, rgba(0,0,0,0.54) 68.33%, rgba(0,0,0,0.62) 100%)`,
  },
  THIN: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 26.52%, rgba(0,0,0,0.54) 59.73%, rgba(0,0,0,0.62) 100%)`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30.78%, rgba(0,0,0,0.54) 63.78%, rgba(0,0,0,0.62) 100%);`,
  },
  DEFAULT: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 18.39%, rgba(0,0,0,0.54) 51.39%, rgba(0,0,0,0.62) 100%);`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 24.19%, rgba(0,0,0,0.54) 55.22%, rgba(0,0,0,0.62) 100%);`,
  },
  WIDE: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 9.92%, rgba(0,0,0,0.54) 43.12%, rgba(0,0,0,0.62) 100%);`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 17.55%, rgba(0,0,0,0.54) 50.59%, rgba(0,0,0,0.62) 100%);`,
  },
};
