export enum HeaderTextStyle {
  WITHOUT_CO_BRAND = 'withoutCoBrand',
  WITH_CO_BRAND = 'withCoBrand',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum HeroSize {
  SIZE_16x9 = '16x9',
  SIZE_3x1 = '3x1',
  SIZE_2X1 = '2x1',
}

export enum ScrimStyle {
  DARK_SCRIM = 'dark-scrim',
  LIGHT_SCRIM = 'light-scrim',
  NO_SCRIM = 'no-scrim',
}

export enum Target {
  ENABLE_TARGET = 'enable-target',
  DISABLE_TARGET = '',
}

export enum LinkTargetType {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export enum AssetVariation {
  FONT_ICON = 'fonticon',
  IMAGE = 'image',
  VERTICAL_IMAGE = 'verticalImage',
}

export enum CopyBlockWidthEnum {
  DEFAULT = 'default',
  EXTRATHIN = 'extraThin',
  THIN = 'thin',
  WIDE = 'wide',
}
