import { AEMModel, AEMContainerProps } from '@marriott/mi-ui-library';
import { AemContainerConfig } from '../../molecules/Navigation';
import { ReactNode } from 'react';

export interface ItemsModel {
  linkLabel?: string;
  openInaNewTab: string;
  linkURL?: string;
  htmlContent?: string | Node;
}

export interface SocialItemsModel {
  linkLabel: string;
  linkURL: string;
}

export interface DestinationItemsModel {
  linkLabel: string;
  openInaNewTab: string;
  linkURL: string;
}
export interface FooterAEMModel extends AEMModel {
  destinationItems: DestinationItemsModel[];
  findhelpItems: ItemsModel[];
  ourCompanyItems: ItemsModel[];
  privacyItems: ItemsModel[];
  socialItems: ItemsModel[];
  workwithusItems: ItemsModel[];
  regions: ItemsModel[];
  minimizedItems?: ItemsModel[];
  id?: string;
}

export interface FooterProps extends AEMContainerProps {
  model: FooterAEMModel;
  cqPath: string;
  children: ReactNode;
  isAuthorMode?: boolean;
  config: AemContainerConfig;
}

export const socialLinks = ['facebook', 'instagram', 'twitter', 'linkedin', 'youtube'];
