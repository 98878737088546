import React, { ChangeEvent, useEffect, useId, useRef, useState } from 'react';
import clsx from 'clsx';

import { InputTextFieldProps } from './InputTextField.types';
import { size, tags } from '../../utils/enums/enums';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Text';
import { RichText } from '../RichText';
import { StyledInputTextField } from './InputTextField.styles';
import { emailRegex } from '@marriott/mi-ui-library';

export const InputTextField = React.forwardRef<HTMLInputElement, InputTextFieldProps>(
  (
    {
      /**
       * get type from parent.By default type will be text.
       */
      setErrorHtml = false, // if we are getting error message with html tags

      type = 'text',
      /**
       * get inputValue from parent
       */
      inputValue,
      /**
       * assign inputValue from parent to input element
       */
      assignInputValue = false,
      /**
       * label to display on top
       */
      label,
      /**
       * label with icon to display on top
       */
      iconForLabel,

      /**
       * place holder text
       */
      placeHolderText,

      /**
       * Flag to show/hide the placeholder text within the input element
       */
      togglePlaceholder,

      /**
       * send input value to parent when user types
       */
      getInputValue,

      /**
       * container class for variation = default
       */
      className = 'm-input-text-field',
      /**
       * container class for variation = line-type
       */
      classNameForLineType,
      /**
       * function to call when input is on focus
       */
      onFocus,

      /**
       * function to call when input is blured
       */
      onBlur,

      /**
       * disable input field , works as just a text to display(read only)
       */
      // disableUserInput = false,

      /**
       * custom icon class
       */
      iconClass,

      /**
       * show icon variation
       */
      showIcon,
      /**
       * aria-label for icon to display
       */
      iconAriaLabel,

      iconOnMouseDownCapture,

      /**
       * custom class to hide error font classes
       */
      hideFontClass,

      /**
       * message to display on error or success state
       */
      messageToShow,
      /**
       * show message variation
       */
      showErrorMessage,
      /**
       * custom class for message to display
       */
      messageClass,
      /**
       * custom class for message icon to display
       */
      errorIconClass,
      /**
       * custom message font size
       */
      messageFontSize = size.medium,
      /**
       * show badge variation
       */
      showBadge,
      /**
       * badge value to display
       */
      badgeValue,
      /**
       * custom app specific prop for Downshift plugin
       */
      getInputProps,
      /**
       * handleKeyDown Event
       */
      handleKeyDown,
      /**
       * input max length value
       */
      inputMaxLength,
      /**
       * input max length value
       */
      iconOnClick,
      /**
       * optional custom click track value for the icon
       */
      icon_custom_click_track_value,
      /**
       * optional custom click track value
       */
      custom_click_track_value,

      infoLabel,
      getLabelProps,
      testId,
      variation = 'default', // options for input text field variations are 'default' and 'line-type'
      withinModal,
      showUnderline,
      inputTextFieldClassName,
      trailingIconProps,
      isFocusEnabled = true,
      isBlurOn = true,
      isClickEnabled = false,
      disabled,
      lastElementRef,
      mandatory = false,
      isEmail,
      customDownshiftLabel = false,
    },
    ref
  ) => {
    //value of input field to be returned to parent component
    const [value, setValue] = useState(inputValue);

    //to set the input field as input-error state if the value is not entered on the input blur
    const [isErrorBlur, setIsError] = useState(false);

    //state to hide place holder text
    const [isPlaceholderVisible, setPlaceholderVisible] = useState(true);
    const inputId = useId();
    const labelId = useId();

    //function to handle onFocus
    const handleFocus = () => {
      if (!togglePlaceholder) {
        setPlaceholderVisible(false);
      }
      onFocus?.();
    };

    //function to handle onBlur
    const handleBlur = (ev?: React.FocusEvent<HTMLInputElement>) => {
      setPlaceholderVisible(true);
      onBlur?.(ev);
    };

    //function to handle input value change
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setValue(newValue);
      //return input text value to parent component
      getInputValue?.(newValue);
      if (!newValue) {
        setPlaceholderVisible(true);
      }
      if (mandatory === true && newValue === '') {
        setIsError(true);
      } else {
        setIsError(false);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeyDownEvent = (event: any) => {
      const newValue = event?.target?.value;
      if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault();
        handleKeyDown?.(newValue);
      }
    };

    useEffect(() => {
      setValue(inputValue);
    }, [inputValue]);

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (infoLabel) {
        inputRef?.current?.setAttribute('aria-label', infoLabel);
      }
    }, [inputRef]);

    /**
     * to set the error on the input blur by checking the email format
     * or if there is no input entered
     */
    const inputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (mandatory === true && e.target.value === '') {
        setIsError(true);
      } else if (isEmail) {
        if (!emailRegex.test(e.target.value)) {
          setIsError(true);
        } else {
          setIsError(false);
        }
      } else {
        setIsError(false);
      }
    };

    return (
      <>
        {variation === 'default' && (
          <StyledInputTextField
            data-component-name="m-ui-library-InputTextField"
            showUnderline={false}
            className={clsx(className, showErrorMessage || isErrorBlur ? 'is-error' : '')}
            data-testid={testId}
          >
            <label htmlFor={inputId} id={labelId} {...getLabelProps?.()}>
              {label}
              {mandatory ? '*' : ''}
            </label>
            <input
              {...{ custom_click_track_value: custom_click_track_value }}
              ref={inputRef || ref}
              type={type}
              id={inputId}
              name={`input-text-${label}`}
              onKeyDown={handleKeyDownEvent}
              {...(isPlaceholderVisible && placeHolderText ? { placeholder: placeHolderText } : {})}
              {...getInputProps?.({ onKeyDown: handleKeyDownEvent })}
              value={assignInputValue ? inputValue : value}
              onChange={handleChange}
              onFocus={isFocusEnabled ? handleFocus : undefined}
              onClick={isClickEnabled ? iconOnClick : undefined}
              onBlur={isBlurOn ? handleBlur : inputBlur}
              maxLength={inputMaxLength}
              aria-label={infoLabel}
              {...(customDownshiftLabel ? {} : { 'aria-labelledby': labelId })}
              {...(showBadge && !showIcon ? { 'aria-describedby': `badge-${inputId}` } : {})}
              role="textbox"
              className={clsx(inputTextFieldClassName, custom_click_track_value ? 'custom_click_track' : '')}
              disabled={disabled}
              required={mandatory}
            />
            {showIcon && (
              <span
                onClick={iconOnClick}
                className={clsx('trailing-element', icon_custom_click_track_value ? 'custom_click_track' : '')}
                tabIndex={0}
                ref={lastElementRef}
                role="button"
                aria-label={iconAriaLabel}
                {...{ custom_click_track_value: icon_custom_click_track_value }}
                {...trailingIconProps}
              >
                <Icon
                  iconClass={iconClass}
                  ariaLabel={iconAriaLabel}
                  {...{ custom_click_track_value: icon_custom_click_track_value }}
                ></Icon>
              </span>
            )}
            {(showErrorMessage || isErrorBlur) &&
              (setErrorHtml ? (
                <RichText
                  componentId={`componentid-${inputId}-input-error`}
                  customClass={messageClass}
                  text={messageToShow ?? ''}
                />
              ) : (
                <Text
                  fontSize={messageFontSize}
                  element={tags.span}
                  customClass={messageClass}
                  copyText={messageToShow ?? ''}
                />
              ))}
            {showBadge && !showIcon && (
              <span className="m-badge-inline-neutral trailing-element" id={`badge-${inputId}`}>
                {badgeValue}
              </span>
            )}
          </StyledInputTextField>
        )}
        {variation === 'line-type' && (
          <StyledInputTextField
            showUnderline={showUnderline ? true : false}
            className={clsx(
              classNameForLineType,
              'm-input-text-field-no-border',
              withinModal && 'within-modal ',
              'custom_click_track',
              showErrorMessage ? 'is-error' : ''
            )}
            data-component-name="m-ui-library-InputTextField"
            data-testid={testId}
            {...{ custom_click_track_value: custom_click_track_value }}
          >
            <label htmlFor={inputId} id={labelId} {...getLabelProps?.()}>
              <Icon iconClass={iconForLabel}></Icon>
              {label}
            </label>
            <input
              {...{ custom_click_track_value: `${custom_click_track_value}` }}
              ref={inputRef || ref}
              type={type}
              id={inputId}
              placeholder={isPlaceholderVisible && placeHolderText ? placeHolderText : ''}
              name={`input-text-${label}`}
              onKeyDown={handleKeyDownEvent}
              {...getInputProps?.({ onKeyDown: handleKeyDownEvent })}
              value={assignInputValue ? inputValue : value}
              onChange={handleChange}
              onFocus={isFocusEnabled ? handleFocus : undefined}
              onClick={isClickEnabled ? iconOnClick : undefined}
              onBlur={handleBlur}
              maxLength={inputMaxLength}
              aria-label={infoLabel}
              {...(customDownshiftLabel ? {} : { 'aria-labelledby': labelId })}
              {...(showBadge && !showIcon ? { 'aria-describedby': `badge-${inputId}` } : {})}
              role="textbox"
              className={inputTextFieldClassName}
            />
            {showIcon && (
              <span
                role="button"
                onClick={iconOnClick ? iconOnClick : undefined}
                className={clsx('trailing-element', icon_custom_click_track_value ? 'custom_click_track' : '')}
                tabIndex={0}
                onMouseDownCapture={e => {
                  e.stopPropagation();
                  iconOnMouseDownCapture && iconOnMouseDownCapture();
                }}
                ref={lastElementRef}
                {...{ custom_click_track_value: icon_custom_click_track_value }}
                aria-label={iconAriaLabel}
                {...trailingIconProps}
              >
                <Icon iconClass={iconClass} custom_click_track_value={icon_custom_click_track_value}></Icon>
              </span>
            )}
            {showErrorMessage && (
              <>
                {errorIconClass && <span className={clsx('icon', errorIconClass)}></span>}
                <Text
                  fontSize={hideFontClass ? ('' as size.extraSmall) : messageFontSize}
                  copyText={messageToShow ? messageToShow : ''}
                  element={tags.span}
                  customClass={messageClass}
                ></Text>
              </>
            )}
            {showBadge && !showIcon && (
              <span className="m-badge-inline-neutral trailing-element" id={`badge-${inputId}`}>
                {badgeValue}
              </span>
            )}
          </StyledInputTextField>
        )}
      </>
    );
  }
);
