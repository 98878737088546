import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '../../../styles';

export const StyledPopupMain = styled.div`
  ${rtl`
  font-weight: ${baseVariables.font['fontWeightRegular']};
  &.hqv-modal-content {
    &.custom-scrollbar::-webkit-scrollbar-thumb:hover{
      background-clip: padding-box;     
    }
    &::-webkit-scrollbar-track {
      margin-bottom: ${toRem(88)} !important;
      background-color: transparent;     
    }
    &.hqv-modal-data {
      visibility: hidden;
      display: none;
    }
    &.modal-content-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      @media ${baseVariables.mediaQuery.lg} {
        height: ${toRem(250)};
        min-height: ${toRem(250)};
        max-height: ${toRem(250)};
      }
      @media only ${baseVariables.mediaQuery.md} {
        height: ${toRem(200)};
        min-height: ${toRem(200)};
        max-height: ${toRem(200)};
      }
    }
    min-height:68vh;
    height: 68vh;
    max-height: 68vh;
    padding-bottom: ${toRem(32)} !important;
    border-radius: 0 0 ${toRem(14)} ${toRem(14)};
    .user-thin-bar, .cmp-container {
      display: none;
    }
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .m-checkbox-selection-item input[type='checkbox'] + label {
    border: none;
    background: transparent;
    &:hover {
      background: transparent;
    }
    display:block;
  }
  .m-checkbox-selection-item input[type='checkbox']:checked + label {
    border: none;
    background: transparent;
    &:hover {
      background: transparent;
    }
  }
  @media only ${baseVariables.mediaQuery.md} {
    height: 70vh;
    max-height: 70vh;
    input[type='radio']:focus + label:before {
      outline: none !important;
    }
  }
  @media only ${baseVariables.mediaQuery.lg} {
    height: auto;
  }
  height: 60vh;
  max-height: 60vh;
  @media only ${baseVariables.mediaQuery.sm} {
    height: 63vh;
    max-height: 63vh;
  }
  `}
`;
