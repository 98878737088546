/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import clsx from 'clsx';

import { IconCollectionProps, IconCollectionVariation, IconCollectionAlignment } from './IconCollection.types';
import { StyledIconCollection } from './IconCollection.styles';
import { Icon } from '../../atoms/Icon';

export const IconCollection: React.FC<IconCollectionProps> = ({
  variation = 'logoLinks',
  id,
  IconCollectionData,
  alignment = 'vertical',
  iconClassName,
  iconSize,
  enableLinkClsName = false,
}) => {
  const IconCollectionClass =
    variation === IconCollectionVariation.LogoLinks ? `${clsx('linkCollectionItem')} p-0` : '';
  const IconListClass =
    variation === IconCollectionVariation.LogoLinks ? `${clsx(!enableLinkClsName ? 'mr-2' : '', 'linkItems')}` : '';

  return (
    <StyledIconCollection
      data-component-name="m-ui-library-IconCollection"
      data-testid="ui-library-IconCollection"
      id={id}
      className={clsx(
        IconCollectionClass,
        alignment === IconCollectionAlignment.Horizontal ? 'horizontal-align' : 'vertical-align'
      )}
    >
      {IconCollectionData?.length &&
        IconCollectionData?.map((el: any, idx: number) => (
          <li className={clsx(IconListClass)} key={`${el}_${idx}`} id={el?.id}>
            <Icon
              iconClass={clsx(
                iconClassName,
                el?.brandtag?.includes('portfolio') ? el.brandtag : `brand-logo-${el.brandtag}`,
                iconSize ? `icon-${iconSize}` : ''
              )}
              iconHref={el?.linkurl}
              rel={el?.openinanewtab ? 'noopener noreferrer' : ''}
              target={el?.openinanewtab ? '_blank' : '_self'}
              enableLinkClsName={enableLinkClsName}
            >
              <span className="sr-only">{el?.linkText}</span>
              {el?.openinanewtab ? <span className="sr-only">Opens a new window</span> : ''}
            </Icon>
          </li>
        ))}
    </StyledIconCollection>
  );
};
