export const mockSessionData = {
  lastUpdated: '1718194156827',
  cacheData: {
    data: {
      firstName: 'LIFETIME',
      lastName: 'USER',
      rewardsId: '430041170',
      consumerID: '',
      accessToken: '',
      lastUpdated: '1718194156827',
      AriesErrors: {
        wsErrors: [
          {
            wsURL:
              'https://mboxedge34.tt.omtrdc.net/rest/v1/delivery?sessionId=44A65A5B-1DDA-507E-9A82-337D7281D1B8&client=marriottinternationa&version=2.2.0',
            errorCause: null,
            wsErrorCode: '500',
            apiName:
              'POST /rest/v1/delivery?sessionId=44A65A5B-1DDA-507E-9A82-337D7281D1B8&client=marriottinternationa&version=2.2.0',
            code: null,
            requestId: '/homepage-searchform.mi~X~E438E588-DAF7-55C0-8C2A-B5699DF2824D',
            componentName: '/marriott/invalidPath.mi',
            type: 'API',
            message: 'failed',
            wsMethod: 'POST',
            statusCode: 400,
          },
        ],
      },
      authToken: null,
      AriesAccountMerge: {
        isSecondarySignIn: false,
      },
      AriesSearch: {
        search_keyword: 'New York, NY, US',
        search_date_check_out_day_of_week: 'Friday',
        searchCriteria: {
          propertyId: 'NYCES',
          searchType: 'InCity',
          pageType: '',
          address: {
            googlePlaceAddressName: '',
            googlePlaceAddressLocality: '',
            countryShortName: '',
            googlePlaceAddressTypes: '',
            googlePlaceAddress: '',
            googlePlaceWebsite: '',
            city: 'New York',
            stateProvince: 'NY',
            stateProvinceDisplayName: '',
            country: 'US',
            region: '',
            postalCode: '',
            latitude: '40.753489',
            longitude: '-73.980961',
            location: '',
            destination: 'New York, NY, US',
            cityPopulation: 0.0,
            cityPopulationDensity: 0.0,
          },
          showAllHotel: false,
          geoCode: '40.753489,-73.980961',
          distanceInMiles: true,
          searchRadius: 50.0,
          availabilityRequestVO: {
            checkInDate: '2023-09-10T00:00:00+0000',
            checkOutDate: '2023-09-12T00:00:00+0000',
            numRooms: 1,
            numGuestsPerRoom: 1,
            numAdultsPerRoom: 1,
            maxRate: 0,
            minRate: 0,
            clusterCode: 'none',
            groupCode: '',
            defaultDateSearch: false,
            lengthOfStay: 18,
            defaultTabTotalCost: false,
            numChildrenPerRoom: 0,
            multiRateRewardsRedemption: false,
            lowestRegularRate: false,
            multiRateMaxCount: 0,
            excludeUnavailableProperties: false,
            includeUnknownRateCategoryForAssociate: 'false',
            flexibleDatesOptionSelected: false,
            hideFlexibleDateCalendar: false,
            associateFlow: false,
            flexibleDateSearchRateDisplay: false,
            multiRateSearch: false,
            rewardsRedemption: false,
            marriottRewardsNumberUnmasked: false,
            adfrequest: false,
            flexibleDate: false,
          },
          airportCode: '',
          searchResultViewType: 'list',
          showHideBuckets: false,
          moreThanTwoFilters: false,
          moreThanOneFilter: false,
          incentiveType: false,
          groupSalesSearch: false,
          sgoSearch: false,
          hotelCategory: 0,
          varyByNightsPostPopulated: false,
          hwsSgoProperty: false,
          numberOfFutureHotels: 0,
          firstTimeGSTSearch: true,
          nightRestriction: false,
          miniStoreFormSubmit: false,
          endecaDown: false,
          regionMiniStorePage: false,
          countryMiniStorePage: false,
          stateMiniStorePage: false,
          singleSearchAutoSuggest: false,
          groupLandingPage: false,
          showDisambiguation: false,
          poiName: '',
          poiCity: '',
          autoSuggestItemType: '',
          clickToSearch: false,
          airportName: '',
          countryName: '',
          marrOfferId: '',
          destinationAddressPlaceId: 'ChIJOwg_06VPwokRYv534QaPC8g',
          destinationAddressMainText: 'New York',
          destinationAddressSecondaryText: 'NY, US',
          showAddressPin: false,
          findAlternateProperties: false,
          invalidRequestCriteria: false,
          valhallaCodeEntered: false,
          promoteBrands: false,
          showAvailableHotels: false,
          iataNumber: '',
          showFullPrice: 'false',
          paginationAndSortingRequestVO: {
            sortType: 'distance',
            pageNumber: 1,
          },
          initialRequest: false,
          endecaResultSet: true,
        },
        search_criteria_changed: true,
        propertyRecordsCount: 1,
        search_google_places_destination:
          '"Name:New York|Locality:New York|State:NY|Postal Code:|Country:US|Latitude:40.753489|Longitude:-73.980961|Types:|Address:"',
        search_location_or_date_change: 'Location and Date',
        search_date_check_in_day_of_week: 'Monday',
        search_is_weekend_stay: 'Weekday and Weekend Stay',
        search_advance_purchase_days: '7',
        search_date_type: 'With Dates',
        search_availability_search: 'true',
      },
      AriesCommon: {
        env_is_prod: 'false',
        roomkey_api_key: 'a9bc92520c8acea6eadbc930b2ce2874',
        search_isFlexibleDate: 'false',
        browser_akamai_loc_state: 'texas',
        isProd: 'false',
        page_url_path_branch: '/search/findHotels',
        browser_akamai_loc_country: 'US',
        search_day_check_out: 'tue',
        prop_hws_tier: 'Standard',
        rk_errorMessage: "id='error-messages'",
        env_img_path: 'https://cache.marriott.com',
        search_currency_type: 'Default',
        rk_isProfileAuthenticated: 'false',
        env_server_id: 'NANA',
        search_is_weekend_stay: 'Weekday and Weekend Stay',
        cookie_mi_site: 'ram-dev39.ose-dev39-vxbyr.cloud.marriott.com',
        session_jsession_id: '44A65A5B-1DDA-507E-9A82-337D7281D1B8',
        rk_cList:
          'enable.rewards.members: false| enable.successfully.booked: false| enable.redemption.points: false| enable.remember.me: true| enable.mobile: false| enable.session.timeout: false| enable.special.rates.aaa: true| enable.special.rates.senior: true| enable.special.rates.gov: false| enable.users.sgo: false| number.of.pages: -1| enable.presented.hd.solution: true| enable.to.industry_solutions.users: false| enable.to.brand: true| percentage.users.to.show: 100| percentage.users.to.show.incremental: 1| enable.associate.users: false| exclude.special.rates.corpcode: MW1, IBM, EMP, ACC, H77, GEE, TVL, GDY, PEP, ORA, HPQ, DTC, ATT, MEB, TOY, PCW, SAP, T77, SIE, BOE, M11, BOA, WEL, A4Y, MCO, MOD, VZN, EMC, ZG4, G2D, JOH, UAL, UTC, DEL, LAC, LK6, GMC, RAY, MM4, MMB, MMF, MMP| enable.special.rates.corpcode: | user.prevent.roomkey.pop: 0| enable.rewards.basic: true| enable.rewards.silver: true|     enable.rewards.gold: false| enable.rewards.platinum: false| enable.users.corpcode: false| enable.users.groupcode: false| browser.useragent: GomezAgent, iPhone, iPad, Silk, BlackBerry, Trident / 4.0| enable.concur.users: false| enable.hd.solution: true| enable.domains.US: true| enable.domains.GB: true| enable.domains.DE: false| enable.domains.FR: false| enable.domains.AU: true| enable.domains.CN: false| enable.domains.JP: false| enable.domains.ES: true| enable.domains.BR: false| enable.domains.KR: false',
        env_date_time: '07/03/23 09:24:53',
        mvp_prequal_endpoint: '/aries-common/v1/sendPrequalFOCConfirmation',
        cookie_mi_visitor: '44A65A5B-1DDA-507E-9A82-337D7281D1B8',
        search_date_check_in_day_of_week: 'Monday',
        errorDetails: 'API|302|/search/default.mi|POST /rest/v1/ID_400',
        memState: 'unauthenticated',
        invokeFlushErrors: 'true',
        device_language_preferred: 'en_US',
        res_context: '0',
        roomkey_api_version: '1.5.1',
        env_site_id: 'US',
        ensighten_settings: 'ON;SYNCH',
        env_platform: 'ram-dev',
        env_site_name: 'ram-dev39.ose-dev39-vxbyr.cloud.marriott.com',
        browser_akamai_loc_long: '-77.0179',
        rk_enabled: 'true',
        page_requested_uri: '/search/findHotels',
        rk_roomKeyBaseURL: '//www.roomkey.com/referrals',
        chckOutDate: '07/04/2023',
        prof_address_state: 'unauthenticated',
        page_url_query_string:
          'view=list&amp;isInternalSearch=true&amp;vsInitialRequest=false&amp;searchType=InCity&amp;for-hotels-nearme=Near&amp;collapseAccordian=is-hidden&amp;singleSearch=true&amp;singleSearchAutoSuggest=Unmatched&amp;flexibleDateSearchRateDisplay=false&amp;isSearch=true&amp;isRateCalendar=true&amp;recordsPerPage=40&amp;destinationAddress.latitude=40.753489&amp;destinationAddress.stateProvince=NY&amp;searchRadius=50&amp;destinationAddress.placeId=ChIJOwg_06VPwokRYv534QaPC8g&amp;destinationAddress.country=US&amp;destinationAddress.secondaryText=NY,+US&amp;destinationAddress.city=New+York&amp;destinationAddress.mainText=New+York&amp;isTransient=true&amp;destinationAddress.longitude=-73.980961&amp;initialRequest=true&amp;fromToDate=07/03/2023&amp;fromToDate_submit=07/28/2023&amp;fromDate=07/10/2023&amp;toDate=07/28/2023&amp;toDateDefaultFormat=07/28/2023&amp;fromDateDefaultFormat=07/10/2023&amp;flexibleDateSearch=false&amp;isHideFlexibleDateCalendar=false&amp;t-start=2023-07-10&amp;t-end=2023-07-28&amp;isFlexibleDatesOptionSelected=false&amp;lengthOfStay=18&amp;roomCount=1&amp;numAdultsPerRoom=1&amp;childrenCount=0&amp;clusterCode=none&amp;destinationAddress.destination=New+York,+NY,+US&amp;rateClusterSetNumType=&amp;rateClusterSetNum=',
        env_jacket: 'MR',
        mr_id_alternate: '',
        sessionId: '44A65A5B-1DDA-507E-9A82-337D7281D1B8',
        session_scid: '',
        rk_currency:
          'AR: ARS,AM: AMD,AW: AWG,AU: AUD,AT: EUR,BE: EUR,BR: BRL,CA: CAD,KY: KYD,CL: CLP,CN: CNY,CR: CRC,CZ: CZK,DK: DKK,DO: DOP,EC: ,EG: EGP,SV: SVC,FR: EUR,GE: GEL,DE: EUR,GR: EUR,GU: ,HU: HUF,IN: INR,ID: IDR,IE: EUR,IL: ILS,IT: EUR,JP: JPY,JO: JOD,KW: KWD,MY: MYR,MX: MXN,NL: EUR,PK: PKR,PA: PAB,PH: PHP,PT: EUR,PE: PEN,PL: PLN,PR: ,QA: QAR,RO: RON,RU: RUB,KN: ,SA: SAR,SG: SGD,KR: KRW,ES: EUR,CH: CHF,TH: THB,TT: TTD,TR: TRY,US: USD,AE: AED,GB: GBP,VE: ,VN: VND,VI: ,CW: ,HN: HNL,BH: BHD,KZ: KZT,SE: SEK,SR: SRD,CO: COP,OM: OMR,JM: JMD,BS: BSD,DZ: DZD,LY: LYD,BB: BBD,AZ: AZN,VG:',
        prop_currency_type: null,
        chckInDat: '07/03/2023',
        mr_isLinkedUser: 'false',
        search_advance_purchase_days: '0',
        browser_akamai_loc_lat: '38.9102',
        prop_name: null,
        brwsrLang: 'en_US',
        referrer: '"https://phoenix-searchtest1.ose-dev39-red.aws-use1.cloud.marriott.com/homepage-searchform.mi"',
        page_domain_name: 'ram-dev39.ose-dev39-vxbyr.cloud.marriott.com',
        search_day_check_in: 'mon',
        prop_address_city: null,
        request_id: '/homepage-searchform.mi~X~E438E588-DAF7-55C0-8C2A-B5699DF2824D',
        mr_prof_authentication_state: 'unauthenticated',
        search_show_available_hotels: 'FALSE',
        res_rooms_per_night: '1',
        search_impression_criteria3: 'distance;null;',
        search_destination_lat_long: '40.753489,-73.980961',
        search_list_records_total: '0',
        search_date_check_in: '07/10/2023',
        search_brands: 'all|',
        numRooms: 1,
        search_list_page_current: '1',
        search_destination_city: 'New York',
        search_destination_country: 'US',
        search_google_places_destination:
          'Name:New York|Locality:New York|State:NY|Postal Code:undefined|Country:US|Latitude:40.753489|Longitude:-73.980961|Types:undefined|Address:null',
        res_stay_length: '1',
        search_number_guest: '1',
        search_total_price_checkbox: 'false',
        search_is_rewards_redemption: 'false',
        res_room_nights_total: '1',
        search_date_check_out: '09/12/2023',
        search_criteria_changed: 'false',
        isRewardRedemption: 'false',
        search_criteria:
          'CHECKIN_DATE=07/03/2023;NUMBER_OF_GUESTPERROOMS=1;STATE=NY;CLUSTER_CODE=none;NUMBER_OF_ROOMS=1;COUNTRY=US;CHECKOUT_DATE=07/04/2023;CITY=New York',
        search_previous_destination_city: 'New York',
        search_date_type: null,
        res_num_adults: 1,
        mr_prof_address_state: '',
        env_api_tile_error_info: 'API|302|/marriott/invalidPath.mi|POST /rest/v1/ID_400',
        search_previous_destination_state: 'NY',
        rk_clusterCode: 'NONE',
        res_num_children: 0,
        search_dates_flexible: 'false',
        mr_prof_address_country: '',
        mr_prof_address_city: '',
        search_list_setting_sort_order: 'distance',
        search_guests_per_room: 1,
        mr_prof_address_state_abbr: '',
        mr_prof_address_country_abbr: '',
        search_destination_state: 'NY',
        search_destination_city_state_country: 'New York,NY,US',
        search_previous_destination_country: 'US',
        geoCodeLatLong: '40.753489,-73.980961',
        numGuestsPerRoom: 1,
        srchDest: 'New York, NY, US',
      },
    },
  },
  sessionToken: '44A65A5B-1DDA-507E-9A82-337D7281D1B8',
  createdTime: '2023-07-03-08:31:45',
};
