import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CardHorizontalMini as CardHorizontalMiniComp, CardHorizontalMiniProps } from '@marriott/mi-ui-library';

export const CardHorizontalMiniConfig = {
  emptyLabel: 'CardHorizontalMini',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/cardhorizontalmini`,
};

export const CardHorizontalMini = (props: CardHorizontalMiniProps) => (
  <div className="p-0" data-testid="card-horizontal-mini" data-component-name="o-common-static-cardhorizontalmini">
    <EditableComponent config={CardHorizontalMiniConfig} {...props}>
      <CardHorizontalMiniComp {...props} />
    </EditableComponent>
  </div>
);
