// Imports for external libraries go here.
import React, { FC } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { CustomSelectBlockProps } from './CustomSelectBlock.types';
import { StyledCustomSelectBlock } from './CustomSelectBlock.styles';
import { Icon } from '../../atoms/Icon';

// Use named rather than default exports.
export const CustomSelectBlock: FC<CustomSelectBlockProps> = ({
  selectTitle,
  selectDescription,
  handleClick,
  name,
  id,
  isExpanded = false,
  changeArrowOnOpen = false, //flag to enable dropdown arrow change on its open
  customButtonClass = 't-font-xs',
  isColonSeparatorEnabled = true,
  additionalPaddingClass = '',
  bothLabelArrowClick = true,
  customClickTrackValue,
}) => {
  return (
    <StyledCustomSelectBlock
      data-component-name="m-ui-library-CustomSelectBlock"
      data-testid="ui-library-CustomSelectBlock"
    >
      <div
        {...{ custom_click_track_value: customClickTrackValue }}
        className="m-custom-select-block custom-drop-styles custom_click_track"
        role="button"
        aria-expanded={isExpanded}
        tabIndex={0}
        aria-label={selectTitle}
        onClick={bothLabelArrowClick ? handleClick : undefined}
        onKeyDown={
          bothLabelArrowClick
            ? (event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'Enter' || event.code === '13') {
                  handleClick();
                }
              }
            : undefined
        }
      >
        {selectTitle && (
          <label htmlFor={id}>
            {' '}
            {selectTitle}
            {isColonSeparatorEnabled ? ':' : ''}
          </label>
        )}
        <button
          type="button"
          aria-expanded={isExpanded}
          className={`${customButtonClass} ${additionalPaddingClass}`}
          name={name}
          id={id}
          data-testid={id}
          onClick={bothLabelArrowClick ? undefined : handleClick}
          tabIndex={-1}
          aria-label={selectTitle}
        >
          {selectDescription}
          <Icon
            aria-label="dropdown-icon"
            iconClass={isExpanded && changeArrowOnOpen ? 'icon-dropdown-up' : 'icon-dropdown-down'}
          />
        </button>
      </div>
    </StyledCustomSelectBlock>
  );
};
