/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';
import {
  SPECIAL_RATES,
  DATES,
  DESTINATION,
  ROOMS_AND_GUESTS,
  USE_POINTS,
  BRANDS,
  DESTINATION_HIDDEN_FIELDS,
  RESORTS_HIDDEN_FIELDS,
  GUEST_ROOMS,
  EVENT_SPACE,
  EVENT_PURPOSE,
  ERROR_MESSAGE,
  SELECTED_TAB,
  MEETINGS_DATES,
  MEETINGS_DESTINATION,
  EVENTS_DESTINATION_HIDDEN_FIELDS,
  EVENTS_ERROR_MESSAGE,
} from './store.constants';
import { getCurrentDateObject, getNextDateObject } from '@marriott/mi-ui-library';
import { initializeSearchForm, searchFormHelperFunction } from '../utils/searchFormHelper';
import { Moment } from 'moment';

interface FocusContextType {
  focusComp: string;
  isUserInteractWithComp: boolean; //to maintain the animation on button for desktop
  isUserEditSearchForm: boolean; //to check if used is actively edited the form
  isUpdateSearchClicked: boolean; //to check if User has clicked on search button
}

interface SelectDestinationNameContextType {
  isDestinationDetailsFetched: boolean;
  isAutoSuggestSelected: boolean;
  isRecentSearchSelected: boolean;
  recentlyViewedPopertyCode: string;
}
export type DateObjectType = Moment;
export interface DatesFieldType {
  lengthOfStay?: number;
  lastDefaultDate?: string;
  eventsLastDefaultDate?: string;
  eventsToDate?: DateObjectType | null;
  eventsFromDate?: DateObjectType | null;
  fromDate?: DateObjectType | null;
  toDate?: DateObjectType | null;
  flexible?: string;
  checkInDate?: string;
  checkOutDate?: string;
  numberOfNights?: number;
}

interface SearchFormPositionType {
  value: number;
}

export interface SearchFormState {
  [DESTINATION]: any;
  [MEETINGS_DESTINATION]: any;
  [SELECTED_TAB]: any;
  [ROOMS_AND_GUESTS]: any;
  [GUEST_ROOMS]: any;
  [EVENT_SPACE]: any;
  [EVENT_PURPOSE]: any;
  [DATES]: DatesFieldType;
  [MEETINGS_DATES]: DatesFieldType;
  [USE_POINTS]: any;
  [BRANDS]?: any;
  [SPECIAL_RATES]: any;
  [DESTINATION_HIDDEN_FIELDS]: any;
  [EVENTS_DESTINATION_HIDDEN_FIELDS]: any;
  [RESORTS_HIDDEN_FIELDS]: any;
  [ERROR_MESSAGE]: any;
  [EVENTS_ERROR_MESSAGE]: any;
  focusInteractions: FocusContextType;
  destinationFieldDetails: SelectDestinationNameContextType;
  isWeekendButtonSelected?: boolean;
  searchFormTopPosition: SearchFormPositionType;
  setIsWeekendButtonSelected: (value: boolean) => any;
  shopStoreInitialization: (sessionData: any, modelData?: any) => void;
  setSearchFormState: (fieldNamesArray: Array<string>, fieldsData: any, reset?: boolean) => any;
}

const currentDate = getCurrentDateObject();
const nextDate = getNextDateObject(currentDate);

const initialState: SearchFormState = {
  [DESTINATION]: {
    displayText: '',
    destinationAddressPlaceId: '',
  },
  [MEETINGS_DESTINATION]: {
    eventsDisplayText: '',
    eventsDestinationAddressPlaceId: '',
  },
  [ROOMS_AND_GUESTS]: {
    numRooms: '1',
    numAdultsPerRoom: 1,
    numChildrenPerRoom: 0,
    childrenAges: [],
  },
  [DATES]: {
    lastDefaultDate: '',
    fromDate: currentDate,
    toDate: nextDate,
  },
  [MEETINGS_DATES]: {
    eventsLastDefaultDate: '',
    eventsFromDate: null,
    eventsToDate: null,
    numberOfNights: 0,
  },
  [USE_POINTS]: {
    rewardsRedemption: false,
  },
  [BRANDS]: {
    marriottBrands: [],
  },
  [SELECTED_TAB]: {
    selectedTab: 'Baseline Search Form',
  },
  [SPECIAL_RATES]: {
    clusterCode: '',
    specialRateCode: 'none',
    corporateCode: '',
  },
  [GUEST_ROOMS]: {
    noOfRooms: '',
  },
  [EVENT_SPACE]: {
    noOfAttendees: '',
  },
  [EVENT_PURPOSE]: {
    purpose: '',
  },
  [DESTINATION_HIDDEN_FIELDS]: {},
  [EVENTS_DESTINATION_HIDDEN_FIELDS]: {},
  [RESORTS_HIDDEN_FIELDS]: {
    hotelType: [],
    activities: [],
    amenities: [],
  },
  [ERROR_MESSAGE]: {
    errorMessages: '',
  },
  [EVENTS_ERROR_MESSAGE]: {
    meetingsErrorMessages: '',
  },
  focusInteractions: {
    focusComp: '',
    isUserInteractWithComp: false,
    isUserEditSearchForm: false,
    isUpdateSearchClicked: false,
  },
  destinationFieldDetails: {
    isDestinationDetailsFetched: true,
    isAutoSuggestSelected: false,
    isRecentSearchSelected: false,
    recentlyViewedPopertyCode: '',
  },
  searchFormTopPosition: {
    value: 0,
  },
  isWeekendButtonSelected: false,
  setIsWeekendButtonSelected: () => {},
  shopStoreInitialization: () => {},
  setSearchFormState: () => {},
};

const SearchFormStore: StateCreator<SearchFormState> = set => {
  return {
    ...initialState,
    // Initialize search form from session data
    shopStoreInitialization: (sessionData: any, modelData: any) => {
      const sessionDoc = sessionData?.data || sessionData?.cacheData?.data;
      const initialData = initializeSearchForm(sessionDoc, modelData);
      set(() => ({ ...initialData }));
    },

    setIsWeekendButtonSelected: (value: boolean) => {
      set(state => ({
        ...state,
        isWeekendButtonSelected: value,
      }));
    },

    //Search Form context setter methods
    setSearchFormState: (fieldNamesArray: Array<string>, fieldsData: any, reset?: boolean) =>
      set(state => {
        const updatedState = searchFormHelperFunction(state, fieldNamesArray, fieldsData, reset);
        return updatedState;
      }),
  };
};

export const useSearchFormStore = createAppStore(SearchFormStore, {
  usePersistentStore: false,
});
