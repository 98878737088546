import { Types } from '@marriott/mi-ui-library';
import { TRACKING_SEARCH_URL } from './constants';

const { INTERNAL, EXTERNAL } = Types.LinkTargetType;

export type ClickTrackingProps = {
  fallbacks: {
    position: string;
    description: string;
  };
  url?: string | null;
  trackingProperties?: Types.TrackingPropsInterface;
};

export const getClickTrackValue = (
  { fallbacks, url, trackingProperties }: ClickTrackingProps,

  /* TODO: remove this. this is temporary to achieve parity with prod values. eventually we should use actual
    position (e.g. GlobalNav, Footer) and description (e.g. Help, Careers, Privacy Policy) */
  labelOverride?: string
) => {
  return [
    labelOverride || trackingProperties?.trackingContentPosition || fallbacks.position,
    labelOverride || trackingProperties?.trackingDescription || fallbacks.description,
    url && !url.includes(TRACKING_SEARCH_URL) ? EXTERNAL : INTERNAL,
  ].join('|');
};
