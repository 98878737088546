/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, SyntheticEvent, useState } from 'react';
import clsx from 'clsx';

import { IconTextBlock } from '@marriott/mi-ui-library';
import { DESTINATION_HIDDEN_FIELDS } from '../../lib/organisms/SearchForm/constants/StoreConstants';
import CurrentLocationView from '../SearchField/CurrentLocation/CurrentLocation';
import { CurrentLocationFeature } from '@marriott/mi-ui-library';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { CityPicklistProps } from './CityPicklist.types';
import { StyledCityPicklist } from './CityPicklist.styles';
import { cityPicklistClickTrackingLoc } from '../../constants/lib/constants';
import { useStaticDataContext } from '../../modules/context';
import RecentViewAndSearch from '../SearchField/RecentViewAndSearch/RecentViewAndSearch';
import { useCheckBreakpoint } from '../../hooks/lib/useCheckBreakpoint';

export const CityPicklist: FC<CityPicklistProps> = props => {
  const {
    currentLocationHiddenFieldHandler,
    updateInputValue,
    locale,
    currentLocationLabel,
    apiKey,
    showSearchListView,
    setShowSearchListView,
    closePopup,
    isMeetingDestField,
    lastElementRef,
    showListView,
  } = props;
  const isDesktopView = useCheckBreakpoint('viewportL');
  const {
    domesticCityPickList,
    internationalCityPickList,
    closeText,
    domesticLabel,
    internationalLabel,
    clearRecentLabel,
  } = useStaticDataContext();
  const clickTrackingLoc = 'Destination';
  const [recentSearchesCleared, setRecentSearchesCleared] = useState(false);

  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);
  const [showClearRecentsOnPicklist, setShowClearRecentsOnPicklist] = useState(false);
  const hiddenFieldsHandler = (data: Record<string, string | number>): void => {
    setSearchFormState(
      [DESTINATION_HIDDEN_FIELDS],
      {
        [DESTINATION_HIDDEN_FIELDS]: data,
      },
      true
    );
  };
  const cityClickHandler = (e: SyntheticEvent, item: Record<string, string | number>, type: string): void => {
    e.preventDefault();
    if (item[type]) {
      updateInputValue(item[type], item['placeId'], item['secondaryDescription'], item);
      if (hiddenFieldsHandler) {
        hiddenFieldsHandler(item);
      }
    }
  };
  //This block will clear the list of recent searches from localhost.
  const clearRecent = (): void => {
    localStorage.removeItem('miRecentSearch');
    localStorage.removeItem('miRecentlyViewedProperties');
    setRecentSearchesCleared(true);
  };
  const handleClearRecentKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      clearRecent();
    }
  };
  return (
    <StyledCityPicklist data-component-name="m-shop-CityPicklist" data-testid="shop-CityPicklist">
      <div className={clsx(!isDesktopView ? 'mt-3' : '')}>
        <CurrentLocationFeature
          currentLocationHiddenFieldHandler={currentLocationHiddenFieldHandler}
          inputFieldHandler={updateInputValue}
          locale={locale}
          currentLocationLabel={currentLocationLabel}
          apiKey={apiKey}
          render={CurrentLocationView}
          iconTextBlockCustomClass={'custom-t-font-m font-weight-regular'}
        />
        <RecentViewAndSearch
          updateInputValue={updateInputValue}
          updateSearchView={(val: boolean): void => setShowSearchListView?.(val)}
          showListView={showSearchListView}
          closePopup={closePopup}
          isMeetingDestField={isMeetingDestField}
          lastElementRef={lastElementRef}
          isCityPicklistFlow={true}
          setShowClearRecentsOnPicklist={setShowClearRecentsOnPicklist}
        />
      </div>

      <div className={clsx('picklist-subtitle-container')}>
        <h3 className={clsx('t-label-xs', 'list-heading', 'font-weight-regular')}>{domesticLabel}</h3>
        <div className={clsx('items-container')}>
          {domesticCityPickList?.map((item: Record<string, string | number>) => (
            <div
              onKeyDown={(event: any): void => {
                if (event.key === 'Enter' || event.keyCode === 13) {
                  cityClickHandler(event, item, 'domesticCityItems');
                }
              }}
              role="button"
              tabIndex={0}
              className="highlighted-item "
              key={`domestic-list-item-${item['domesticCityItems']}`}
            >
              <a
                tabIndex={-1}
                href="#"
                onClick={(event: SyntheticEvent): void => cityClickHandler(event, item, 'domesticCityItems')}
                className="custom_click_track m-link-action"
                {...{
                  custom_click_track_value: `${cityPicklistClickTrackingLoc}| ${item['domesticCityItems']} |internal`,
                }}
              >
                <IconTextBlock
                  icon={'location'}
                  primaryTextContent={`${item['domesticCityItems']}`}
                  primaryCustomClass={'custom-t-font-m font-weight-regular'}
                />
              </a>
            </div>
          ))}
        </div>

        {/* International List Starts here */}
        <h3 className={clsx('t-label-xs', 'list-heading', 'font-weight-regular')}>{internationalLabel}</h3>
        <div className={clsx('items-container')}>
          {internationalCityPickList?.map((item: Record<string, string | number>) => (
            <div
              onKeyDown={(event: any): void => {
                if (event.key === 'Enter' || event.keyCode === 13) {
                  cityClickHandler(event, item, 'internationalCityItems');
                }
              }}
              tabIndex={0}
              role="button"
              className="highlighted-item "
              key={`international-list-item-${item['internationalCityItems']}`}
            >
              <a
                tabIndex={-1}
                href="#"
                onClick={(event: SyntheticEvent): void => cityClickHandler(event, item, 'internationalCityItems')}
                className="custom_click_track"
                {...{
                  custom_click_track_value: `${cityPicklistClickTrackingLoc}| ${item['internationalCityItems']} |internal`,
                }}
              >
                <IconTextBlock
                  icon={'location'}
                  primaryTextContent={`${item['internationalCityItems']}`}
                  primaryCustomClass={'custom-t-font-m font-weight-regular'}
                />
              </a>
            </div>
          ))}
        </div>

        <div
          className={clsx(
            'm-icon-text',
            'button-container',
            showClearRecentsOnPicklist ? 'justify-content-between' : 'justify-content-end'
          )}
        >
          {showClearRecentsOnPicklist && !recentSearchesCleared && (
            <div
              role="button"
              tabIndex={0}
              className={clsx(
                'search-history-btm-cta-wrapper',
                'd-flex',
                'close-button',
                'custom_click_track',
                isDesktopView ? 't-font-m' : 't-font-s',
                !showListView ? '' : 'd-none'
              )}
              onClick={clearRecent}
              onKeyDown={handleClearRecentKeyDown}
              custom_click_track_value={`${cityPicklistClickTrackingLoc}| ${clearRecentLabel} |internal`}
            >
              <span className="pr-2 button-element">{clearRecentLabel}</span>
            </div>
          )}
          {isDesktopView && (
            <div
              tabIndex={0}
              role="button"
              className="d-flex close-button custom_click_track"
              onClick={() => {
                updateInputValue('', '', '', {});
              }}
              custom_click_track_value={`${cityPicklistClickTrackingLoc}| Close button clicked |internal`}
              onKeyDown={(event: any) => {
                if (event.keyCode === 13 || event.key === 'Enter') {
                  updateInputValue('', '', '', {});
                }
              }}
            >
              <span className="t-font-m pr-2 button-element">{closeText}</span>
              <span className="icon-arrow-up icon-s button-element"></span>
            </div>
          )}
        </div>
      </div>
    </StyledCityPicklist>
  );
};
