import { FC } from 'react';
import { baseVariables, Icon } from '@marriott/mi-ui-library';
import { StyledUserProfileImageContainer } from './UserProfileImage.styles';
import { UserProfileImageProps, UserType } from './UserProfileImage.types';

export const UserProfileImage: FC<UserProfileImageProps> = ({
  userImage,
  userImageAltText,
  userType,
  lifeTimeLevel,
}) => {
  const getImageBorderColor = (): string => {
    switch (userType) {
      case UserType.SILVER:
        // TODO: To be added to global styles
        return '#70758d';
      case UserType.GOLD:
        return `${baseVariables.color['goldSolid']}`;
      case UserType.PLATINUM:
        return `${baseVariables.color['platinumSolid']}`;
      case UserType.TITANIUM:
        // TODO: To be added to global styles
        return '#8D7474';
      case UserType.AMBASADOR:
        return `${baseVariables.color['base10']}`;
      case UserType.MEMBER:
        return `${baseVariables.color['accent10']}`;
      default:
        return '';
    }
  };
  const borderColor = getImageBorderColor();
  return (
    <StyledUserProfileImageContainer borderColor={borderColor}>
      <img src={userImage} alt={userImageAltText} className="userprofile-image" />
      {lifeTimeLevel && <Icon iconClass={'icon-lifetime d-flex align-items-center justify-content-center p-0'} />}
    </StyledUserProfileImageContainer>
  );
};
